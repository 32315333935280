import React from 'react';
import ModalBase from '../../Controls/ModalBase';
import { Box, Link, Button, Grid, Typography } from '@mui/material';
import { GetApp } from '@mui/icons-material';

const SinCorreo = React.forwardRef((props, ref) => {
  const { open, setOpen, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            Para recuperar contraseña su cuenta de usuario debe tener un correo electrónico
            registrado que constituya su <b>domicilio electrónico comercial.</b>
          </Typography>
          <br />
          <Typography align='center' variant='body2' color='textSecondary'>
            Para registrarlo debe presentar el formulario de actualización de datos{' '}
            <Link
              href='https://www.iosper.gov.ar/images/iosper_archivos/Formularios/formAltaProfesionales.pdf'
              target='_blank'
              download
            >
              <GetApp />
            </Link>{' '}
            firmado en la asociación médica correspondiente al convenio adherido con IOSPER o en
            alguna de las oficinas de la obra social, siendo en cualquier caso recepcionadas por la
            dirección de despacho de IOSPER.
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={(e) => handleClose(e)}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Recuperar mi Contraseña'
        onClose={handleClose}
      />
    </Box>
  );
});

export default SinCorreo;
