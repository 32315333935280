import React from 'react';
import { Typography, Button, Grid, Box, Link } from '@mui/material';
import ModalBase from '../Controls/ModalBase';
import { GetApp } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';

const ConsultoriosRestringidos = React.forwardRef((props, ref) => {
  const { open, setOpen, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            Momentaneamente no es posible realizar esta acción
          </Typography>
          <br />
          <Typography align='center' variant='body2' color='textSecondary'>
            {props?.message}
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={(e) => handleClose(e)}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase open={open} setOpen={setOpen} content={form} title='Error' onClose={handleClose} />
    </Box>
  );
});

export default ConsultoriosRestringidos;
