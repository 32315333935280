import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, FormControl, Stack, TextField } from '@mui/material';
import { getReimprimirOrden } from '../../services/Seo/Orden';
import LoadingButton from '../../@iosper/components/LoadingButton';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Print from '@mui/icons-material/Print';

const OrdenParaReimprimir = (props) => {
  const [datosOrden, setDatosOrden] = useState({});
  const [ordenGenerada, setOrdenGenerada] = useState(false);
  const [loadingImprimirOrden, setLoadingImprimirOrden] = useState(false);
  const { handlerReimprimir } = props;

  useEffect(() => {
    setDatosOrden(props?.datosOrden);

    return function cleanup() {
      setDatosOrden({});
    };
  }, [props.datosOrden]);

  if (!datosOrden) {
    return null;
  }

  const handleClose = () => {
    setLoadingImprimirOrden(false);
    setOrdenGenerada(false);
    props.setOpen(false);
    setDatosOrden({});
  };

  const handlePrint = async (event) => {
    setLoadingImprimirOrden(true);
    event.preventDefault();
    if (
      datosOrden !== null &&
      datosOrden.orden.numeroOrdenSinFormato !== undefined &&
      datosOrden.orden.numeroOrdenSinFormato !== null
    ) {
      try {
        const res = await getReimprimirOrden({
          numeroOrden: datosOrden.orden.numeroOrdenSinFormato,
        });
        setLoadingImprimirOrden(false);
        handlerReimprimir(datosOrden, res);
        handleClose();
      } catch (e) {
        setLoadingImprimirOrden(false);
      }
    }
  };

  const DrawerNumeroOrden = ({ numeroOrden, tipoOrden, codigoTipoOrden }) => {
    return codigoTipoOrden === '02' ? (
      <TextField
        id='order-fechageneracion'
        variant='standard'
        label='Nro de orden'
        defaultValue={`${numeroOrden} ${'(' + tipoOrden + ')'}`}
        fullWidth
        color='warning'
        focused
        InputProps={{
          readOnly: true,
        }}
      />
    ) : (
      <TextField
        id='order-fechageneracion'
        variant='standard'
        label='Nro de orden'
        defaultValue={numeroOrden}
        fullWidth
        InputProps={{
          readOnly: true,
        }}
      />
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}
          id='alert-dialog-title'
        >
          ¿Confirma la Reimpresión de la última orden?
        </DialogTitle>
        <DialogContent sx={{ pr: 2, pl: 3, pb: 0, pt: 0 }}>
          {datosOrden.orden && (
            <FormControl variant='outlined' margin='normal' fullWidth={true}>
              <DrawerNumeroOrden
                codigoTipoOrden={datosOrden?.orden?.codigoTipoOrden}
                numeroOrden={datosOrden?.orden?.numeroOrden}
                tipoOrden={datosOrden?.orden?.tipoOrden}
              ></DrawerNumeroOrden>
            </FormControl>
          )}
          {datosOrden.orden && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='order-fechageneracion'
                variant='standard'
                label='Fecha de Emisión'
                defaultValue={datosOrden.orden.fechaEmision}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden.afiliado && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='afiliado-nya'
                variant='standard'
                label='Afiliado'
                defaultValue={`(${datosOrden.afiliado.numerodocumentoformateado}) ${datosOrden.afiliado.nombre}, ${datosOrden.afiliado.apellido}`}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden.prestador && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='prestador-nya'
                label='Prestador'
                variant='standard'
                defaultValue={`(${datosOrden.prestador.codigoPrestador}) ${
                  datosOrden.prestador.razonSocial
                } ${
                  datosOrden.prestador?.especialidades
                    ? `(${datosOrden.prestador?.especialidades
                        ?.map((espe) => espe.descripcion)
                        .join(', ')} ) `
                    : ''
                } `}
                size='small'
                multiline
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems={{ xs: 'strech' }}
          >
            <Button
              variant='contained'
              startIcon={<HighlightOff />}
              color='neutral'
              onClick={handleClose}
              fullWidth
            >
              {ordenGenerada ? 'Cerrar' : 'Cancelar'}
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              startIcon={<Print />}
              loading={loadingImprimirOrden ? 'show' : 'hide'}
              onClick={handlePrint}
              disabled={!datosOrden}
              content={'Reimprimir'}
              fullWidth
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenParaReimprimir;
