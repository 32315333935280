import { FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DetalleCoberturaOrdenData = (props) => {
  const { detallePracticas } = props;
  const [practicas, setPracticas] = useState(null);
  const [tieneCobertura, setTieneCobertura] = useState(false);
  const [rowsDetalleCobertura, setRowsDetalleCobertura] = useState(0);

  useEffect(() => {
    if (props.detallePracticas) {
      setPracticas(props.detallePracticas);
    }

    return function cleanup() {
      setPracticas(null);
    };
  }, [props.detallePracticas]);

  useEffect(() => {
    if (practicas) {
      Object.values(practicas).forEach((practica) => {
        // Cuando es una generarcion el OrdenData devuelve en valorAutorizado el valor, cuando es reimrpesion lo devuelve en valorAutorizacion
        if (practica.valorAutorizado > 0 || practica.valorAutorizacion > 0) {
          setTieneCobertura(true);
          setRowsDetalleCobertura((prev) => prev + 1);
        }
      });
    }

    return function cleanup() {
      setTieneCobertura(false);
      setRowsDetalleCobertura(0);
    };
  }, [practicas]);

  if (!detallePracticas) {
    return null;
  }

  const detalle = () => {
    const result =
      practicas &&
      Object.values(practicas).map(
        (practica) =>
          `${practica.descripcionTipoCobertura}:  -$ ${
            practica.valorAutorizado > 0 ? practica.valorAutorizado : practica.valorAutorizacion
          }`,
      );
    return result.join('\n');
  };

  return (
    <FormControl fullWidth={true} variant='outlined' margin='normal'>
      <TextField
        id='detalle-cobertura'
        variant='standard'
        label='Detalle de Cobertura'
        value={tieneCobertura ? detalle() : 'No contiene'}
        size='small'
        fullWidth
        InputProps={{
          readOnly: true,
        }}
        multiline
        rows={rowsDetalleCobertura}
      />
    </FormControl>
  );
};

export default DetalleCoberturaOrdenData;
