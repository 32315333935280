import { axiosGet } from '../ServiceBase.js';

export const getTiposTratamientos = async () => {
  try {
    const tiposTratamientos = JSON.parse(sessionStorage.getItem('tiposTratamientos'));
    if (tiposTratamientos == null) {
      const tiposTratamientos = await axiosGet('/v1/busquedas/tiposTratamientos');
      sessionStorage.setItem('tiposTratamientos', JSON.stringify(tiposTratamientos));
      return tiposTratamientos;
    } else {
      return tiposTratamientos;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
