import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  CardActions,
  FormControl,
  Collapse,
  ToggleButton,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  HighlightOff,
  Save,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from '@mui/icons-material';
import PracticasPorRolAutocomplete from '../Practicas/PracticasPorRolAutocomplete';
import CustomizedSnackbars from '../Notifications/SnackBar';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { consultarCuposOrdenMedica } from '../../services/Seo/Orden';
import StyledDataGridCupo from '../Common/StyledDataGridCupo';
import { LoadingButton } from '@mui/lab';

const ConsultarCupo = () => {
  const history = useHistory();
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [selectedPractica, setSelectedPractica] = useState(null);
  const [cupo, setCupo] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [limites, setLimites] = useState([]);
  const [visibleFind, setVisibleFind] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const screenWidth = useWidth();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const customStyle = {
    marginBottom: '0px',
    marginTop: '0px',
  };

  const onSubmitConsultarCupo = async (data, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const res = await consultarCuposOrdenMedica({
        idAfiliado: selectedAfiliado?.idAfiliado,
        idPractica: selectedPractica?.idPractica,
      });
      if (res && res.consumoCupoOrdenMedicaVO.length > 0) {
        setCupo(res);
        setLimites(res.consumoCupoOrdenMedicaVO);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado cupos para la práctica');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const validatePractica = () => {
    return selectedPractica == null ? false : true;
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handlerSelectPractica = (practica) => {
    setSelectedPractica(practica);
  };

  /**
   * Evento selección afiliado. Seteamos el nuevo estado de afiliado
   * @param {object} afiliado
   */
  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
    //setDisabledTipoTratamiento(afiliado?false:true);
  };

  const handleBuscarOtro = () => {
    setLimites([]);
    setVisibleFind(true);
    clean();
  };

  const clean = () => {
    reset();
    setSelectedAfiliado(null);
    setSelectedPractica(null);
    onSelectAfiliado(null);
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
  };

  const DataCollapseDrawer = (props) => {
    const { limite } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`${limite.cantidadMeses || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <List>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Tipo Límite: ${limite?.tipoLimite || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Cant. Meses: ${limite?.cantidadMeses || ''}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Ordinarias: ${limite?.ordinarias || '0'}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Ampliaciones: ${limite?.ampliaciones || '0'}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Consumidas: ${limite?.consumidas || '0'}`} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary={`Restantes: ${limite?.restantes || '0'}`} />
              </ListItem>
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID PRESTADORES --------------------------
  const mapLimiteToRow = (p) => ({
    id: p.consumoCupo.cantidadMeses,
    tipoLimite: p.tipoLimite.descripcion,
    cantidadMeses: p.consumoCupo.cantidadMesesLetras,
    ordinarias: p.consumoCupo.cantidadCupos,
    ampliaciones: p.consumoCupo.extraordinarios,
    consumidas: p.consumoCupo.consumidos,
    restantes:
      p.consumoCupo.cantidadCupos + p.consumoCupo.extraordinarios - p.consumoCupo.consumidos,
    tieneCupo: p.consumoCupo.restantesConExtraordinarias > 0 ? '1' : '0',
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Cupos',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer limite={a.row} />,
    },
    { field: 'tipoLimite', headerName: 'Tipo Límite', width: 160 },
    { field: 'cantidadMeses', headerName: 'Cant. Meses', width: 180 },
    { field: 'ordinarias', headerName: 'Ordinarias', flex: 0.1 },
    { field: 'ampliaciones', headerName: 'Ampliaciones', flex: 0.1 },
    { field: 'consumidas', headerName: 'Consumidas', flex: 0.1 },
    { field: 'restantes', headerName: 'Restantes', flex: 0.1 },
  ];
  const handleBorrarMensaje = () => {
    setAlertResultFind('');
  };

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%', p: 1 }}>
            <CardContent style={customStyle}>
              <Typography gutterBottom variant='h5' style={customStyle}>
                Consultar cupo de consultas y prácticas
              </Typography>
              <Typography gutterBottom style={customStyle}>
                Acá podrás consultar el cupo de la práctica seleccionada para un afiliado.
              </Typography>
            </CardContent>
            {visibleFind && (
              <form style={customStyle} onSubmit={handleSubmit(onSubmitConsultarCupo)} noValidate>
                <CardContent style={customStyle}>
                  <FormControl
                    style={customStyle}
                    fullWidth={true}
                    variant='outlined'
                    margin='normal'
                  >
                    {/** componente afiliado */}
                    <Box mb={2} style={customStyle}>
                      <BuscarAfiliado
                        key={keyComponentAfiliado + '__afiliado'}
                        initialValue={selectedAfiliado}
                        style={customStyle}
                        onSelectAfiliado={onSelectAfiliado}
                        {...register('afiliadoRegister', {
                          validate: {
                            seleccionarAfiliado: () =>
                              !!selectedAfiliado || 'Debe seleccionar un afiliado',
                          },
                        })}
                      />
                      {errors.afiliadoRegister && (
                        <p style={{ color: 'red', marginTop: '1px' }}>
                          {errors.afiliadoRegister.message}
                        </p>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl fullWidth={true} variant='outlined' margin='normal'>
                    <PracticasPorRolAutocomplete
                      key={keyComponentAfiliado + '_practica'}
                      size='small'
                      ref={register('practicaRegister', {
                        validate: validatePractica,
                      })}
                      onSelectPractica={handlerSelectPractica}
                    />
                    {errors.practicaRegister != null && (
                      <p style={{ color: 'red' }}>Debe seleccionar la práctica</p>
                    )}
                  </FormControl>
                </CardContent>

                <CardActions sx={{ px: 2 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flexDirection: { xs: 'column', sm: 'row' },
                          gap: 2,
                        }}
                      >
                        <Button
                          variant='contained'
                          startIcon={<HighlightOff />}
                          color='neutral'
                          onClick={clean}
                        >
                          Limpiar
                        </Button>

                        <LoadingButton
                          variant='contained'
                          size='medium'
                          color='primary'
                          startIcon={<Save />}
                          type='submit'
                          loading={loading}
                        >
                          Buscar
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </CardActions>
              </form>
            )}
            {!visibleFind && (
              <Grid container direction='column' justifyContent='space-between' spacing={1}>
                <Grid item>
                  <List>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        primary={`Afiliado: ${cupo?.afiliadoVO.apellidoNombreFormateado || ''}`}
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText primary={`Práctica: ${cupo?.practicaVO.descripcion || ''}`} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item>
                  <div style={{ height: '60vh', width: '100%' }}>
                    <StyledDataGridCupo
                      getRowClassName={(params) => `super-app-theme--${params.row.tieneCupo}`}
                      sx={{
                        border: 0,
                        '& .MuiDataGrid-cell': {
                          py: '8px',
                        },
                      }}
                      loading={!limites.length}
                      rows={limites.map((a) => mapLimiteToRow(a))}
                      columns={columns}
                      getRowHeight={() => 'auto'}
                      getEstimatedRowHeight={() => 200}
                      columnVisibilityModel={{
                        desplegable: screenWidth === 'xs',
                        tipoLimite: screenWidth !== 'xs',
                        cantidadMeses: screenWidth !== 'xs',
                        ordinarias: screenWidth !== 'xs',
                        ampliaciones: screenWidth !== 'xs',
                        consumidas: screenWidth !== 'xs',
                        restantes: screenWidth !== 'xs',
                      }}
                      pagination
                      autoPageSize
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      m: 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: 2,
                    }}
                  >
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<Search />}
                      onClick={() => handleBuscarOtro()}
                    >
                      Buscar otro
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
        <CustomizedSnackbars
          open={alertResultFind}
          severity='info'
          message={alertResultFind}
          onDeleteMessage={handleBorrarMensaje}
        />
      </Grid>
    </div>
  );
};

export default ConsultarCupo;
