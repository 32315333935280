import {
  AutoAwesomeMotion,
  Person,
  ExitToApp,
  HelpOutline,
  Home,
  Logout,
  Password,
  QrCode,
  Assignment,
  AssignmentTurnedIn,
  Medication,
  Help,
  CancelPresentation,
  Print,
} from '@mui/icons-material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';

export const SideMenuList = [
  {
    to: '/',
    label: 'Inicio',
    icon: <Home />,
  },
  {
    label: 'Orden Electrónica',
    icon: <QrCode />,
    submenu: [
      {
        to: '/orden-digital',
        label: 'Generar Orden de Consulta',
        icon: <QrCode />,
      },
      {
        to: '/consultar-ordenes-electronicas',
        label: 'Consultar Orden',
        icon: <ManageSearchIcon />,
      },
      {
        to: '/anular-orden-electronica',
        label: 'Anular Orden',
        icon: <DeleteSweepOutlinedIcon />,
      },
    ],
  },
  {
    label: 'Orden Impresa',
    icon: <Assignment />,
    submenu: [
      {
        to: '/orden-consulta',
        label: 'Generar Orden de Consulta',
        icon: <Assignment />,
      },
      {
        to: '/orden-varia',
        label: 'Generar Orden de Prácticas',
        icon: <PostAddIcon />,
      },
      {
        to: '/consultar-ordenes-impresas',
        label: 'Consultar Orden',
        icon: <ManageSearchIcon />,
      },
      {
        to: '/reimpresion-orden',
        label: 'Reimpresión',
        icon: <Print />,
      },
      {
        to: '/registrar-atencion',
        label: 'Registrar Atención',
        icon: <AssignmentTurnedIn />,
      },
      {
        to: '/anular-atencion',
        label: 'Anular Atención',
        icon: <CancelPresentation />,
      },
      {
        to: '/consultar-cupo',
        label: 'Consultar Cupo',
        icon: <AutoAwesomeMotion />,
      },
      {
        to: '/anular-orden-impresa',
        label: 'Anular Orden',
        icon: <DeleteSweepOutlinedIcon />,
      },
    ],
  },
];

export const SideMenuListOdontologos = [
  {
    to: '/',
    label: 'Inicio',
    icon: <Home />,
  },
  {
    label: 'Orden Impresa',
    icon: <Assignment />,
    submenu: [
      {
        to: '/orden-consulta',
        label: 'Generar Orden de Consulta',
        icon: <Assignment />,
      },
      {
        to: '/consultar-ordenes-impresas',
        label: 'Consultar Orden',
        icon: <ManageSearchIcon />,
      },
      {
        to: '/reimpresion-orden',
        label: 'Reimpresión',
        icon: <Print />,
      },
      {
        to: '/registrar-atencion',
        label: 'Registrar Atención',
        icon: <AssignmentTurnedIn />,
      },
      {
        to: '/anular-atencion',
        label: 'Anular Atención',
        icon: <CancelPresentation />,
      },
      {
        to: '/consultar-cupo',
        label: 'Consultar Cupo',
        icon: <AutoAwesomeMotion />,
      },
      {
        to: '/anular-orden-impresa',
        label: 'Anular Orden',
        icon: <DeleteSweepOutlinedIcon />,
      },
    ],
  },
];

export const SideMenuListOptica = [
  {
    to: '/',
    label: 'Inicio',
    icon: <Home />,
  },
  {
    label: 'Orden Impresa',
    icon: <Assignment />,
    submenu: [
      {
        to: '/orden-varia',
        label: 'Generar Orden Varias',
        icon: <PostAddIcon />,
      },
      {
        to: '/consultar-ordenes-impresas',
        label: 'Consultar Orden',
        icon: <ManageSearchIcon />,
      },
      {
        to: '/reimpresion-orden',
        label: 'Reimpresión',
        icon: <Print />,
      },
      {
        to: '/registrar-atencion',
        label: 'Registrar Atención',
        icon: <AssignmentTurnedIn />,
      },
      {
        to: '/anular-atencion',
        label: 'Anular Atención',
        icon: <CancelPresentation />,
      },
      {
        to: '/consultar-cupo',
        label: 'Consultar Cupo',
        icon: <AutoAwesomeMotion />,
      },
      {
        to: '/anular-orden-impresa',
        label: 'Anular Orden',
        icon: <DeleteSweepOutlinedIcon />,
      },
    ],
  },
];

export const SideMenuListProfesional = [
  {
    label: 'Receta Electrónica',
    icon: <Medication />,
    submenu: [
      {
        to: '/generar-receta',
        label: 'Generar Receta',
        icon: <Medication />,
      },
      {
        to: '/consultar-recetas',
        label: 'Consultar Recetas',
        icon: <ManageSearchIcon />,
      },
    ],
  },
];

export const SideMenuListNoProfesional = [];

export const BottomMenuList = [
  {
    to: '/signout',
    label: 'Salir',
    icon: <ExitToApp />,
  },
];

export const BottomMenuListAyuda = [
  {
    to: '/ayuda',
    label: 'Ayuda',
    icon: <Help />,
  },
];

export const TopMenuList = [
  {
    to: '/perfil',
    icon: <Person fontSize='small' />,
    label: 'Perfil',
  },
  {
    to: '/cambiar-password',
    label: 'Cambiar mi Contraseña',
    icon: <Password fontSize='small' />,
  },
  {
    to: '/terminos-condiciones',
    label: 'Términos y condiciones',
    icon: <HelpOutline fontSize='small' />,
  },
  {
    to: '/signout',
    label: 'Salir',
    icon: <Logout fontSize='small' />,
  },
];
