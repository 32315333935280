import { TextField } from '@mui/material';
import React from 'react';

const DrawerNumeroOrden = ({ numeroOrden, tipoOrden, codigoTipoOrden }) => {
  return codigoTipoOrden === '02' ? (
    <TextField
      id='order-fechageneracion'
      variant='standard'
      label='Nro de orden'
      defaultValue={`${numeroOrden} ${'(' + tipoOrden + ')'}`}
      fullWidth
      color='warning'
      focused
      InputProps={{
        readOnly: true,
      }}
    />
  ) : (
    <TextField
      id='order-fechageneracion'
      variant='standard'
      label='Nro de orden'
      defaultValue={numeroOrden}
      fullWidth
      InputProps={{
        readOnly: true,
      }}
    />
  );
};

export default DrawerNumeroOrden;
