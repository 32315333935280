import React, { useEffect, useState } from 'react';
import { ArrowForwardIos, CheckCircleOutline, HighlightOffOutlined } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Stack,
  Tooltip,
  CardHeader,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { dateBirthFormatter } from '../../@iosper/utils/Format';
import { seguirPrestadorLogin } from '../../services/Busquedas/Prestadores';
import { styled } from '@mui/system';
import { GetApp } from '@mui/icons-material';
import LinkUrl from '@mui/material/Link';

const DrawerDataField = ({ label, data }) => {
  return (
    <ListItem>
      <Grid container alignItems={'baseline'}>
        <Grid item xs={12} sm={4}>
          <ListItemText
            secondary={
              <Typography gutterBottom variant='button' display='block' sx={{ fontWeight: '600' }}>
                {label}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ListItemText primary={data} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

const DrawerDataFieldAction = ({ label, data, link }) => {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton component={Link} to={link}>
          <ArrowForwardIos fontSize='small' />
        </IconButton>
      }
    >
      <ListItemButton component={Link} to={link}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <ListItemText
              secondary={
                <Typography
                  gutterBottom
                  variant='button'
                  display='block'
                  sx={{ fontWeight: '600' }}
                >
                  {label}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <ListItemText primary={data} />
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

const DrawerDataEstadoVerificado = ({ data, variaton, message, link }) => {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        data &&
        variaton === 'error' && (
          <Tooltip id='button-link' title='Verificar'>
            <IconButton component={Link} to={link}>
              <ArrowForwardIos fontSize='small' />
            </IconButton>
          </Tooltip>
        )
      }
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <ListItemText primary={data} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItemText
            primary={
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                {data && variaton === 'error' && (
                  <HighlightOffOutlined color={'error'} fontSize='small' />
                )}
                {data && variaton === 'success' && (
                  <CheckCircleOutline color={'success'} fontSize='small' />
                )}
                <Typography
                  variant='caption'
                  display='block'
                  gutterBottom
                  sx={{ color: variaton === 'error' ? '#ef5350' : '#4caf50' }}
                >
                  {data && message}
                </Typography>
              </Stack>
            }
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  '& .MuiCardHeader-content': {
    padding: 2,
  },
}));

const Perfil = () => {
  const [infoPrestador, setInfoPrestador] = useState(null);
  const [esProfesional, setEsProfesional] = useState(false);
  const [esEstablecimiento, setEsEstablecimiento] = useState(false);
  const [esConsultorio, setEsConsultorio] = useState(false);

  var numeroCalle;
  const numeroDomicilio = infoPrestador?.itemsDomicilioPersona?.filter(
    (t) => t.codigoTipoNumeracion === '6',
  );
  if (numeroDomicilio?.length > 0) {
    numeroCalle = numeroDomicilio[0];
  }

  const contactoTele = infoPrestador?.contactoPersona?.filter((t) => t.codigoTipoContacto === '1');
  const contactoMail = infoPrestador?.contactoPersona?.filter((m) => m.codigoTipoContacto === '2');
  const contactoCel = infoPrestador?.contactoPersona?.filter((m) => m.codigoTipoContacto === '5');

  useEffect(() => {
    const get = async () => {
      const res = await seguirPrestadorLogin();
      if (res !== null) {
        setInfoPrestador(res);
        if (res?.tipo === '01') {
          setEsProfesional(true);
        } else if (res?.tipo === '02') {
          setEsEstablecimiento(true);
        } else if (res?.tipo === '03') {
          setEsConsultorio(true);
        }
      }
    };
    get();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '24px',
              }}
            >
              <div>
                <Avatar sx={{ width: 100, height: 100 }} />
              </div>
              <div>
                <Typography
                  gutterBottom
                  variant='h6'
                  textAlign={'center'}
                  sx={{ fontWeight: 'bold' }}
                >
                  Bienvenido,{' '}
                  {infoPrestador?.prestador?.nombre.substring(
                    0,
                    infoPrestador?.prestador?.nombre.indexOf(','),
                  ) +
                    infoPrestador?.prestador?.nombre.substring(
                      infoPrestador?.prestador?.nombre.indexOf(',') + 1,
                      infoPrestador?.prestador?.nombre.length,
                    )}
                </Typography>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  component='p'
                  textAlign={'center'}
                >
                  Visualiza y gestiona tu información y seguridad personal.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <StyledCardHeader title='Información básica' />
            {esProfesional && (
              <CardContent
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <List>
                  <DrawerDataField
                    label='Nombre completo'
                    data={infoPrestador ? `${infoPrestador?.prestador?.nombre}` : ''}
                  />
                  <Divider />
                  <DrawerDataField label='CUIT' data={infoPrestador?.persona?.cuilCuitFormateado} />
                  <Divider />
                  <DrawerDataField
                    label='Matricula'
                    data={infoPrestador ? `${infoPrestador?.prestador?.matricula}` : ''}
                  />
                  <Divider />
                  <DrawerDataField
                    label='Especialidad'
                    data={infoPrestador?.prestador?.descripcionTipo}
                  />
                </List>
              </CardContent>
            )}

            {esEstablecimiento && (
              <CardContent
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <List>
                  <DrawerDataField
                    label='Razón Social'
                    data={infoPrestador ? `${infoPrestador?.prestador?.nombre}` : ''}
                  />
                  <Divider />
                  <DrawerDataField label='CUIT' data={infoPrestador?.persona?.cuilCuitFormateado} />
                </List>
              </CardContent>
            )}

            {esConsultorio && (
              <CardContent
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <List>
                  <DrawerDataField
                    label='Denominación'
                    data={infoPrestador ? `${infoPrestador?.consultorio?.descripcion}` : ''}
                  />
                  <Divider />
                  <DrawerDataField
                    label='Código de consultorio'
                    data={infoPrestador?.consultorio?.codigo}
                  />
                </List>
              </CardContent>
            )}
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <StyledCardHeader title='Información de contacto' />
            <CardContent
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <List disablePadding>
                <DrawerDataField
                  label='Correo Electrónico'
                  data={
                    <List disablePadding>
                      {contactoMail?.map((i, k) => (
                        <DrawerDataEstadoVerificado
                          data={i.descripcion}
                          variaton={i.confirmado ? 'success' : 'error'}
                          message={
                            i.confirmado
                              ? `(Verificado el ${i.fechaConfirmado || ''})`
                              : '(No Verificado)'
                          }
                          key={k}
                          link={`/verificar-contacto/${
                            i.idHistorialContactoPersonaJuridica || i.idHistorialContactoConsultorio
                          }/${i.descripcion}`}
                        />
                      ))}
                    </List>
                  }
                />

                {contactoCel?.length > 0 && (
                  <div>
                    <Divider />
                    <DrawerDataField
                      label='Celular'
                      data={
                        <List disablePadding>
                          {contactoCel?.map((i, k) => (
                            <ListItem key={k} disableGutters disablePadding>
                              <ListItemText primary={i.descripcion} />
                            </ListItem>
                          ))}
                        </List>
                      }
                    />
                  </div>
                )}

                {contactoTele?.length > 0 && (
                  <div>
                    <Divider />
                    <DrawerDataField
                      label='Teléfono Fijo'
                      data={
                        <List disablePadding>
                          {contactoTele?.map((i, k) => (
                            <ListItem key={k} disableGutters disablePadding>
                              <ListItemText primary={i.descripcion} />
                            </ListItem>
                          ))}
                        </List>
                      }
                    />
                  </div>
                )}

                <Divider />
                <DrawerDataField
                  label='Dirección'
                  data={
                    infoPrestador
                      ? `${infoPrestador?.calle?.descripcion || ''} ${numeroCalle?.valor || ''}, ${
                          infoPrestador?.localidad?.descripcion
                        }`
                      : ''
                  }
                />
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <StyledCardHeader title='Actualización de Datos' />
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '24px',
              }}
            >
              <Typography align='center' variant='body2' color='textSecondary'>
                Para realizar una modificación debe presentar el formulario de actualización de
                datos{' '}
                <LinkUrl
                  href='https://www.iosper.gov.ar/images/iosper_archivos/Formularios/formAltaProfesionales.pdf'
                  target='_blank'
                  download
                >
                  <GetApp />
                </LinkUrl>{' '}
                firmado en la asociación médica correspondiente al convenio adherido con IOSPER o en
                alguna de las oficinas de la obra social, siendo en cualquier caso recepcionadas por
                la dirección de despacho de IOSPER.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <StyledCardHeader title='Seguridad' />
            <CardContent
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <DrawerDataFieldAction
                label='Cambiar mi contraseña'
                data='******'
                link='/cambiar-password'
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Perfil;
