import React from 'react';
import axios from 'axios';
import { createContext, useReducer } from 'react';
import { RUN, STOP } from './constants';
import { useUser } from '../../../components/Auth/User';

export const InterceptorContext = createContext();
export const { Consumer, Provider } = InterceptorContext;

const initialState = {
  interceptorRunning: false,
};

function reducer(_interceptorRunning, action) {
  switch (action.type) {
    case RUN:
      return {
        interceptorRunning: true,
        request: null,
        response: null,
      };
    case STOP:
      return {
        interceptorRunning: false,
        request: null,
        response: {
          status: action?.response?.status,
          statusText: action?.response?.statusText,
          severity: action?.response?.severity,
        },
      };
    default:
      throw new Error();
  }
}

const InterceptorProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { signOut } = useUser();

  React.useEffect(() => {
    requestInterceptor();
    responseInterceptors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function run() {
    dispatch({
      type: RUN,
    });
  }

  function stop(response = null) {
    dispatch({
      type: STOP,
      request: null,
      response: {
        status: response?.status,
        statusText: response?.statusText,
        severity: response?.severity,
      },
    });
  }

  const requestInterceptor = () => {
    axios.interceptors.request.use(
      function (config) {
        run();
        return config;
      },
      function (error) {
        console.log('request error', error);
        stop({
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          severity: 'error',
        });
        return Promise.reject(error);
      },
    );
  };

  const responseInterceptors = () => {
    axios.interceptors.response.use(
      function (response) {
        if (response?.data?.status === 'error') {
          stop({
            status: 'warning',
            statusText: response?.data?.message,
            severity: 'warning',
          });
          return Promise.reject(response);
        } else {
          stop();
          return response;
        }
      },
      function (error) {
        const isWarning = [400, 422].includes(error?.response?.status);
        if (error?.response?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          signOut();
          stop();
        } else {
          stop({
            status: isWarning ? 'warning' : 500,
            statusText: isWarning ? error?.response?.data.error : error?.response?.statusText,
            severity: isWarning ? 'warning' : 'error',
          });
          return Promise.reject(error);
        }
      },
    );
  };

  return <Provider value={[state, dispatch]}>{props.children}</Provider>;
};

export default InterceptorProvider;
