import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getPracticasPorRol } from '../../services/Busquedas/Practicas';

const PracticasPorRolAutocomplete = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [practicaLabel, setPracticaLabel] = React.useState('Seleccioná la práctica');
  const [practica, setPractica] = React.useState(null);
  const [practicasList, setPracticasList] = React.useState([]);

  React.useEffect(() => {
    setPractica(props.practica);
  }, [props.practica]);

  React.useEffect(() => {
    if (props.codigoSubtipoPractica != null && options.length) {
      let list = options.filter(
        (item) => item.codigoSubtipoPractica === props.codigoSubtipoPractica,
      );
      setPracticasList(list);
    } else {
      setPracticasList(options);
    }
  }, [options, props.codigoSubtipoPractica]);

  React.useEffect(() => {
    const getPractica = async () => {
      const practicas = await getPracticasPorRol();
      setOptions(practicas);
    };

    try {
      if (options && options.length === 0) {
        getPractica();
      }
    } catch (err) {
      console.error(err);
    }
  }, [options]);

  const handleChange = (event, newValue) => {
    props.onSelectPractica(newValue);
    setPractica(newValue);
    setPracticaLabel('Práctica');
  };

  return (
    <Autocomplete
      id='practica-autocomplete'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?.idPractica === value?.idPractica}
      getOptionLabel={(a) => `${a.codigo} - ${a.descripcion}`}
      onChange={handleChange}
      options={practicasList}
      value={practica}
      loading={options && options.length === 0}
      renderInput={(params) => (
        <TextField
          id='textPractica'
          {...params}
          label={practicaLabel}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options && options.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default PracticasPorRolAutocomplete;
