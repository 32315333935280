import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  ToggleButton,
  Typography,
  FormControl,
  Alert,
  Input,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { findDiagnosticosByDescripcion } from '../../services/Busquedas/Diagnosticos';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { DataGrid } from '@mui/x-data-grid';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import ModalBase from '../Controls/ModalBase';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

const DataCollapseDrawer = (props) => {
  const { Diagnostico } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        selected={open}
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body1'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {`(${Diagnostico.codigo || ''}) ${Diagnostico.descripcion || 'Sin presentación'}`}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Código: ${Diagnostico?.codigo || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Diagnóstico: ${Diagnostico?.descripcion || ''}`} />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const getDiagnosticoNombre = (diagnostico) => {
  if (!diagnostico) return;
  return `(${diagnostico?.codigo}) ${diagnostico?.descripcion}`;
};

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    cursor: 'pointer',
  },
});

const DiagnosticoSelector = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const screenWidth = useWidth();
  const { onSelectedDiagnostico, errorSelector } = props;
  const [diagnosticos, setDiagnosticos] = useState([]);
  const [rowSelectedDataGrid, setRowSelectedDataGrid] = useState(null);
  const [disabledSearchMedicamento, setDisabledSearchMedicamento] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [diagnostico, setDiagnostico] = useState(props.medicamento || null);
  const [visibleFind, setVisibleFind] = useState(true);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [textFieldMedicamento, setTextFieldMedicamento] = useState('Buscá el Diagnóstico');
  const [error, setError] = useState('');

  React.useEffect(() => {
    setDiagnostico(props.diagnostico);
  }, [props.diagnostico]);

  React.useEffect(() => {
    setDiagnostico(props.diagnostico || null);
  }, [props.diagnostico]);

  React.useEffect(() => {
    onSelectedDiagnostico(diagnostico);
  }, [diagnostico]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handleClose = () => {
    setOpen(false);
  };

  const openCloseModal = () => {
    if (!open) {
      clean();
    }
    setOpen(!open);
  };

  const clean = () => {
    setDiagnosticos([]);
    setDiagnostico(null);
    setVisibleFind(true);
    setRowSelectedDataGrid(null);
    reset();
    setVisibleInfo(false);
  };

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setVisibleInfo(false);
    setAlertResultFind('');
    setError('');

    if (data.nombre === '') {
      setError('Debes ingresar un diagnóstico para realizar tu búsqueda.');
      setLoading(false);
    } else {
      const result = await findDiagnosticosByDescripcion(data.nombre);
      if (result && result.length > 0) {
        if (result.length >= 50) {
          setVisibleInfo(true);
        }
        setDiagnosticos(result);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado Diagnósticos.');
        clean();
      }
      setLoading(false);
    }
  };

  const selectHandler = (e) => {
    e.preventDefault();
    setDiagnostico({ ...rowSelectedDataGrid });
    setDisabledSearchMedicamento(true);
    openCloseModal();
    reset();
    setTextFieldMedicamento('Diagnóstico');
    setVisibleInfo(false);
  };

  const handleIconClose = (e) => {
    e.preventDefault();
    setDisabledSearchMedicamento(false);
    setTextFieldMedicamento('Buscá el Diagnóstico');
    setDiagnostico(null);
  };

  const handleOnClickIconSearch = (e) => {
    e.preventDefault();
    openCloseModal();
  };

  const handleClickMedicamento = (e) => {
    if (!disabledSearchMedicamento) {
      openCloseModal();
    }
  };

  const handleOnRowDoubleClick = (data, e) => {
    e.preventDefault();
    selectHandler(e);
    setRowSelectedDataGrid(data);
  };

  /// -------------------- DATA GRID Diagnosticos --------------------------
  const mapDiagnosticoToRow = (p, key) => ({
    id: `${key}-${p.idCie10}`,
    idCie10: p.idCie10,
    codigo: p.codigo,
    descripcion: p.descripcion,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Diagnósticos',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer Diagnostico={a.row} />,
    },
    { field: 'codigo', headerName: 'Código', width: 100 },
    { field: 'descripcion', headerName: 'Nombre', flex: 0.1 },
  ];

  const dataGridDiagnosticos = (
    <Grid container direction='column' justifyContent='space-between' spacing={1}>
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '8px',
              },
            }}
            loading={!diagnosticos.length}
            rows={diagnosticos.map((a, key) => mapDiagnosticoToRow(a, key))}
            columns={columns}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              codigo: screenWidth !== 'xs',
              descripcion: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            onRowClick={(selected) => {
              setRowSelectedDataGrid(selected.row);
            }}
            onRowDoubleClick={handleOnRowDoubleClick}
          />
        </div>
      </Grid>
      {visibleInfo && (
        <Grid item>
          <Alert severity='info'>
            Sólo se muestran los primeros 50 resultados, agregue más criterios de búsqueda.
          </Alert>
        </Grid>
      )}
      <Grid item>
        <Grid container justifyContent='flex-end' spacing={1}>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='neutral'
              startIcon={<SearchIcon />}
              onClick={() => clean()}
            >
              Buscar otro
            </Button>
          </Grid>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='primary'
              disabled={!rowSelectedDataGrid}
              startIcon={<CheckCircleIcon />}
              onClick={(e) => selectHandler(e)}
            >
              Seleccionar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const formFind = (
    <form>
      <FormControl fullWidth={true} variant='outlined'>
        <TextField
          variant='standard'
          type='text'
          fullWidth
          margin='normal'
          name='nombre'
          label='Ingresá un nombre o código del Diagnóstico'
          {...register('nombre', {
            maxLength: {
              value: 200,
              message: 'El campo es demasiado largo',
            },
            minLength: {
              value: 3,
              message: 'El campo debe tener como mínimo 3 caracteres.',
            },
          })}
        />
        {errors['nombre'] && <p style={{ color: 'red' }}>{errors['nombre'].message}</p>}
      </FormControl>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <LoadingButton
            fullWidth={true}
            type='submit'
            variant='contained'
            color='primary'
            loading={loading ? 'show' : 'hide'}
            content={'Buscar'}
            startIcon={<SearchIcon />}
            onClick={handleSubmit(find)}
          />
        </Grid>
      </Grid>
    </form>
  );
  const handleBorrarMensaje = () => {
    setAlertResultFind('');
    setError('');
  };

  const iconAdornment = !disabledSearchMedicamento ? (
    <IconButton
      variant='outlined'
      aria-label='Buscar Diagnóstico'
      onClick={handleOnClickIconSearch}
      size='large'
      edge='end'
    >
      <SearchIcon />
    </IconButton>
  ) : (
    <IconButton
      variant='outlined'
      aria-label='Buscar Diagnóstico'
      onClick={handleIconClose}
      size='large'
      edge='end'
    >
      <CloseSharpIcon />
    </IconButton>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{ position: 'relative' }}>
            <FormControl fullWidth variant='standard'>
              <InputLabel htmlFor='outlined-adornment-Diagnostico'>
                {textFieldMedicamento}
              </InputLabel>
              <Input
                id='outlined-adornment-Diagnostico'
                name='Diagnóstico'
                multiline
                maxRows={4}
                label='Buscá el Diagnóstico'
                aria-describedby='outlined-Diagnostico-helper-text'
                value={diagnostico ? getDiagnosticoNombre(diagnostico) : ''}
                onClick={handleClickMedicamento}
                onBlur={handleClickMedicamento}
                disabled
                endAdornment={<InputAdornment position='end'>{iconAdornment}</InputAdornment>}
              />
            </FormControl>
            <div className={classes.overlay} onClick={handleClickMedicamento} />
          </div>
          {errorSelector && screenWidth === 'xs' && <p style={{ color: 'red' }}>{errorSelector}</p>}
        </Grid>
      </Grid>

      <ModalBase
        fullWidth={true}
        open={open}
        content={visibleFind ? formFind : dataGridDiagnosticos}
        title='Buscar Diagnóstico'
        onClose={handleClose}
      />
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Box>
  );
});

export default DiagnosticoSelector;
