import React, { useState, useContext, useEffect, useMemo, createContext, useCallback } from 'react';
import { singInUser, useToken, getUsuario } from '../../services/Auth/Autenticacion';
import jwt from 'jsonwebtoken';

export const UserContext = createContext(null);

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useToken();

  useEffect(() => {
    if (token) {
      const decodedToken = jwt.decode(token, { complete: true });
      const isExpired = decodedToken
        ? decodedToken.payload.exp < new Date().getTime() / 1000
        : true;

      if (!isExpired && !user) {
        loadUsuario();
      }
    }
  }, [token, user]);

  const signIn = useCallback(
    async (username, password) => {
      try {
        const signToken = await singInUser({ username, password });
        if (signToken) {
          setToken(signToken);
          const user = await loadUsuario();
          return user;
        } else {
          clearSession();
          return null;
        }
      } catch (err) {
        console.error(err);
        clearSession();
        return null;
      }
    },
    [setToken, setUser],
  );

  const loadUsuario = async () => {
    const user = await getUsuario();
    setUser(user);
  };

  const clearSession = () => {
    setToken(null);
    setUser(null);
  };

  const signOut = useCallback(() => {
    clearSession();
  }, [setToken, setUser]);

  const values = useMemo(() => ({ user, token, signIn, signOut }), [user, token, signIn, signOut]);

  return <UserContext.Provider value={values}>{props.children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('`useUser` must be within a `UserProvider`');
  }
  return context;
};
