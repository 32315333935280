import { axiosGet, axiosPost } from '../ServiceBase.js';

export const getMedicamentos = async (data) => {
  const medicamentos = await axiosPost('/v1/busquedas/medicamentos', data);
  return medicamentos;
};

export const getUltimosMedicamentos = async (dataQuery) => {
  const medicamentos = await axiosGet(`/v1/busquedas/medicamentos/get-latest${dataQuery || ''}`);
  return medicamentos;
};
