import { axiosGet, axiosPost } from '../ServiceBase.js';

export const getContactos = async () => {
  return axiosGet('/v1/miconsultorio/contacto/correo');
};

export const getContactosByUser = async (username, cuilcuit) => {
  return axiosPost('/v1/contacto/miconsultorio/correo-usuario', {
    nombreUsuario: username,
    cuilcuit: cuilcuit,
  });
};

export const generarCodigoConfirmacion = async (idHistorialContactoPersonaJuridica) => {
  return axiosPost('/v1/miconsultorio/contacto/codigo-confirmacion/generar', {
    id: idHistorialContactoPersonaJuridica,
  });
};

export const confirmarContacto = async (idHistorialContactoPersonaJuridica, codigoConfirmacion) => {
  return axiosPost('/v1/miconsultorio/contacto/codigo-confirmacion/confirmar', {
    id: idHistorialContactoPersonaJuridica,
    code: codigoConfirmacion,
  });
};

export const getContactoConfirmado = async () => {
  return axiosGet('/v1/miconsultorio/contacto/validar-correo-confirmado');
};
