import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUser } from './User';
import { Paper, Typography } from '@mui/material';

const UserConnected = (props) => {
  return (
    <div>
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Estas conectado como "'}
        {props?.user?.razonsocial ? props.user.razonsocial : '-'}
        {'"'}
      </Typography>
    </div>
  );
};

export default function SignOut(props) {
  const { user, identity, signOut } = useUser();

  const signOutHandler = (e) => {
    e.preventDefault();
    signOut();
    sessionStorage.clear();
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Paper sx={{ p: 2 }}>
        <form noValidate>
          <Button
            sx={{ mb: 2 }}
            component={Link}
            to='/'
            fullWidth
            variant='text'
            color='primary'
            startIcon={<ArrowBackIcon />}
          >
            Volver
          </Button>

          <Button
            sx={{ mb: 2 }}
            component={Link}
            to='/signin'
            fullWidth
            variant='contained'
            color='neutral'
            startIcon={<ExitToAppIcon />}
            onClick={(e) => signOutHandler(e)}
          >
            Salir
          </Button>
        </form>
      </Paper>

      <Box mt={8}>
        <UserConnected user={user} identity={identity} />
      </Box>
    </Container>
  );
}
