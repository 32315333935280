import { useState, useEffect } from 'react';
import { Avatar, Box, Fab, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FormatListNumbered } from '@mui/icons-material';
import { getUltimosDiagnosticos } from '../../services/Busquedas/Diagnosticos';

export const UltimosDiagnoticos = ({ idAfiliado = null, limit = 10, onSelectEvent = null }) => {
  const [latestOption, setLatestOption] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getLatestOptions();
  }, [idAfiliado]);

  const getLatestOptions = async () => {
    let query = `?limit=${limit}`;
    if (idAfiliado) {
      query = query + `&idAfiliado=${idAfiliado}`;
    }
    const result = await getUltimosDiagnosticos(query);
    if (result.length > 0) {
      setLatestOption(result);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleSelect = (cie10) => {
    setAnchorEl(null);
    if (onSelectEvent) {
      onSelectEvent(cie10);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: { sm: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title='Últimos diagnósticos recetados'>
          <Fab
            onClick={handleClick}
            size='small'
            color='default'
            sx={{ boxShadow: 'none' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <FormatListNumbered fontSize='small' color='primary' />
          </Fab>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {latestOption.map((cie10) => (
          <MenuItem key={cie10.idCie10} onClick={(e) => onHandleSelect(cie10)}>
            ({cie10.codigo}) {cie10.descripcion}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
