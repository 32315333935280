import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar, CardActionArea } from '@mui/material';
import { useUser } from '../Auth/User';
import RegistrarAtencionMobileImg from '../../assets/images/svg/mobile-qrScan.svg';
import RegistrarAtencionDesktopImg from '../../assets/images/svg/registrar-atencion.svg';
import GenerarOrdenDigitalMobileImg from '../../assets/images/svg/mobile-generarOrden.svg';
import GenerarOrdenDigitalDesktopImg from '../../assets/images/svg/generar-orden.svg';
import { useWidth } from '../../@iosper/utils/UseWidth';

const CardActionComponent = ({ title, description, to, image }) => {
  return (
    <Card>
      <CardActionArea sx={{ borderRadius: '10px' }} component={Link} to={to || '/'}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          {image && (
            <div>
              <img src={image} alt={title} width={'85px'} height={'85px'} />
            </div>
          )}
          <div>
            {title && (
              <Typography gutterBottom variant='h6' sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant='body2' color='textSecondary' component='p'>
                {description}
              </Typography>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const UsuarioCardActionComponent = ({ title, description, to, image }) => {
  return (
    <Card>
      <CardActionArea sx={{ borderRadius: '10px' }} component={Link} to={to || '/'}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            gap: '24px',
          }}
        >
          <div>
            {/* <img src={image} alt={title} width={"85px"} /> */}
            <Avatar sx={{ width: 100, height: 100 }} />
          </div>

          <div>
            {title && (
              <Typography
                gutterBottom
                variant='h6'
                textAlign={'center'}
                sx={{ fontWeight: 'bold' }}
              >
                {title || ''}
              </Typography>
            )}
            {description && (
              <Typography variant='body2' color='textSecondary' component='p' textAlign={'center'}>
                {description}
              </Typography>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const Home = () => {
  const { user } = useUser();
  const screenWidth = useWidth();
  const [esProfesional, setProfesional] = useState(false);
  const [esOptica, setEsOptica] = useState(false);
  const [esOdontologo, setEsOdontologo] = useState(false);
  useEffect(() => {
    if (user) {
      setEsOptica(user?.letraEstablecimiento?.toLowerCase() === 'y');
      setEsOdontologo(user?.letraProfesional?.toLowerCase() === 'd');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setProfesional(user.roles[2]?.codigo === '2');
    }
  }, [user]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={4}>
        <UsuarioCardActionComponent title={user?.razonsocial} image={''} to={'/perfil'} />
      </Grid>
      {esProfesional && (
        <Grid item xs={12} sm={6} lg={4}>
          <CardActionComponent
            title={'Receta Electrónica'}
            description={'Confecciona recetas firmadas electrónicamente y envíalas a la farmacia'}
            to={'/generar-receta'}
            image={
              screenWidth === 'xs' ? GenerarOrdenDigitalMobileImg : GenerarOrdenDigitalDesktopImg
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} lg={4}>
        <CardActionComponent
          title={'Registrar Atención'}
          description={'Registra la atención del profesional al paciente'}
          to={'/registrar-atencion'}
          image={screenWidth === 'xs' ? RegistrarAtencionMobileImg : RegistrarAtencionDesktopImg}
        />
      </Grid>

      {!esOptica && !esOdontologo && (
        <Grid item xs={12} sm={6} lg={4}>
          <CardActionComponent
            title={'Orden Electrónica'}
            description={'Genera órdenes de atención utilizando código QR'}
            to={'/orden-digital'}
            image={
              screenWidth === 'xs' ? GenerarOrdenDigitalMobileImg : GenerarOrdenDigitalDesktopImg
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Home;
