import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PrestadorSelect from '../Prestador/PrestadorSelect';
import { useForm } from 'react-hook-form';
import { Search, HighlightOff, GetApp } from '@mui/icons-material';
import { findOrdenes, generarExcel } from '../../services/Busquedas/Ordenes';
import DateSelector from '../Common/DateSelector';
import OrdenesTable from './OrdenesTable';
import { format } from '../../@iosper/utils/Format';
import PrestadorAutocompleteVirtualizado from '../Prestador/PrestadorAutocompleteVirtualizado';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';
import { UltimosPrestadoresOrden } from '../Prestador/UltimosPrestadoresOrden';
import { LoadingButton } from '@mui/lab';

export const BuscarOrdenes = (props) => {
  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [ordenes, setOrdenes] = useState();
  const [showTable, setShowTable] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [keyComponentSelectPrestador, setKeyComponentSelectPrestador] = useState(0);
  const [keyComponentSelectPeriodo, setKeyComponentSelectPeriodo] = useState(0);
  const [idPrestador, setIdPrestador] = useState(null);
  const [prestadorLogueado, setPrestadorLogueado] = useState(null);
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    handlerLimpiar();
    if (props.electronicas) {
      setTitle('Electrónicas');
      setSubTitle('electrónicas');
    } else {
      setTitle('Impresas');
      setSubTitle('impresas');
    }
  }, [props.electronicas]);

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador != null) {
        setPrestadorLogueado(prestador);
        setSelectedPrestador(prestador);
      }
    };

    if (prestadorLogueado === null) {
      getPrestador();
    }
  }, []);

  const onSubmit = async (data, event) => {
    setLoadingSearch(true);
    setShowTable(false);
    setNoResult(false);
    event.preventDefault();

    try {
      setIdPrestador(selectedPrestador.idPrestador);

      const res = await findOrdenes(
        selectedPrestador.idPrestador,
        selectedPeriodo,
        props.electronicas ? '02' : '01',
      );
      setOrdenes(res);
      res.length > 0 ? setShowTable(true) : setNoResult(true);
      setLoadingSearch(false);
    } catch (e) {
      setLoadingSearch(false);
    }
  };

  const handlerLimpiar = () => {
    setSelectedPrestador(null);
    setKeyComponentSelectPrestador(1 + Math.random() * (1000 - 1));
    setKeyComponentSelectPeriodo(1 + Math.random() * (1000 - 1));
    setSelectedPeriodo(null);
    reset();
    setShowTable(false);
    setNoResult(false);
  };

  const handlerExportar = async () => {
    let fecha = format(new Date());
    await generarExcel({
      idPrestador: idPrestador,
      periodo: selectedPeriodo,
      codigoTipoOrden: props.electronicas ? '02' : '01',
      fileName:
        `Órdenes${title}_` +
        selectedPrestador.nombre +
        '_' +
        selectedPrestador.matricula +
        '_' +
        fecha,
    });
  };

  const handlerSelectPrestador = (prestador) => {
    setSelectedPrestador(prestador);
  };

  const handlerUltimoPrestador = (prestador) => {
    handlerSelectPrestador(prestador);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5'>
              Consultar Ordenes {title}
            </Typography>
            <Typography gutterBottom>
              Acá podrás consultar las ordenes {subTitle} generadas en el mes seleccionado.
            </Typography>
          </CardContent>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={3} alignItems='center'>
                <Grid
                  item
                  xs={
                    prestadorLogueado?.idProfesional || prestadorLogueado?.tipo === '15' ? 12 : 11
                  }
                >
                  {prestadorLogueado?.idProfesional || prestadorLogueado?.tipo === '15' ? (
                    <PrestadorSelect
                      key={keyComponentSelectPrestador + '_prestador'}
                      value={selectedPrestador}
                      disabled={selectedPrestador !== null ?? true}
                      name='prestadorRegister'
                      {...register('prestadorRegister', {
                        validate: () => selectedPrestador?.idPrestador || false,
                      })}
                      onSelectPrestador={handlerSelectPrestador}
                    />
                  ) : (
                    <PrestadorAutocompleteVirtualizado
                      variant='standard'
                      value={selectedPrestador}
                      key={keyComponentSelectPrestador + '_prestador'}
                      ref={register('prestadorRegister', {
                        validate: () => selectedPrestador?.idPrestador || false,
                      })}
                      onSelectedPrestador={handlerSelectPrestador}
                    ></PrestadorAutocompleteVirtualizado>
                  )}
                </Grid>
                {!prestadorLogueado?.idProfesional && prestadorLogueado?.tipo !== '15' && (
                  <Grid item xs={1}>
                    <UltimosPrestadoresOrden onSelectEvent={handlerUltimoPrestador} />
                  </Grid>
                )}
              </Grid>
              {errors.prestadorRegister && errors.prestadorRegister.type === 'validate' && (
                <p style={{ color: 'red' }}>Debe seleccionar el prestador</p>
              )}
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <DateSelector
                  name='dateSelector'
                  key={keyComponentSelectPeriodo}
                  {...register('dateSelector', {
                    validate: () => (selectedPeriodo != null ? true : false),
                  })}
                  label='Mes'
                  fieldName='mes'
                  type={['year', 'month']}
                  onSelectedFecha={(fecha) => {
                    setSelectedPeriodo(fecha);
                  }}
                  useCurrentDate
                />
                {errors.dateSelector && <p style={{ color: 'red' }}>Debe seleccionar un periodo</p>}
              </FormControl>
            </CardContent>
            <CardActions sx={{ px: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: 2,
                    }}
                  >
                    <Button
                      variant='contained'
                      size='medium'
                      startIcon={<HighlightOff />}
                      color='neutral'
                      onClick={handlerLimpiar}
                    >
                      Limpiar
                    </Button>
                    <LoadingButton
                      variant='contained'
                      size='medium'
                      color='primary'
                      type='submit'
                      startIcon={<Search />}
                      loading={loadingSearch}
                    >
                      Buscar
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </CardActions>
          </form>

          {showTable && (
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <Button
                  variant='contained'
                  size='small'
                  startIcon={<GetApp />}
                  color='primary'
                  onClick={handlerExportar}
                >
                  Exportar
                </Button>
                <OrdenesTable ordenes={ordenes} />
              </CardContent>
            </Card>
          )}

          {noResult && <Alert severity='info'>No se encontraron resultados</Alert>}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BuscarOrdenes;
