import { Delete } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Diagnostico from '../Diagnostico/DiagnosticoSelector';
import MedicamentoSelector from '../Medicamento/Medicamento';
import { UltimosDiagnoticos } from '../Diagnostico/UltimosDiagnoticos';
import { UltimosMedicamentos } from '../Medicamento/UltimosMedicamentos';
import { useWidth } from '../../@iosper/utils/UseWidth';

const Recipe = ({
  recipe,
  idAfiliado,
  register,
  errors,
  indice = 0,
  onDeleteRecipeEvent,
  onChangeRecipeEvent,
  diagnosticoList,
  isMedicamentoRepetido,
}) => {
  const nameDiagnosticoRegister = `diagnosticoRegister${indice}`;
  const nameMedicamentoRegister = `medicamentoRegister${indice}`;
  const screenWidth = useWidth();

  const onChangeDiagnosticoSelected = (newDiagnostico) => {
    onChangeRecipeEvent(indice, { ...recipe, cie10: newDiagnostico });
  };

  const onChangeMedicamentoSelected = (newMedicamento) => {
    onChangeRecipeEvent(indice, { ...recipe, medicamento: newMedicamento });
  };

  const onChangeLatestDiagnosticoSelected = (newDiagnostico) => {
    onChangeRecipeEvent(indice, { ...recipe, cie10: newDiagnostico });
  };

  const onChangeLatestMedicamentoSelected = (newMedicamento) => {
    onChangeRecipeEvent(indice, {
      ...recipe,
      medicamento: { ...newMedicamento, cantidad: newMedicamento?.cantidad || 1 },
    });
  };

  const onDeleteRecipe = () => {
    onDeleteRecipeEvent(indice);
  };

  const handleIsMedicamentoRepetido = (medicamento) => {
    return isMedicamentoRepetido(medicamento);
  };

  return (
    <>
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6'>RP{indice + 1}</Typography>
        </Grid>
        <Grid item sm={10} xs={9}>
          {/* Componente Diagnostico */}
          <Diagnostico
            name={nameDiagnosticoRegister}
            options={diagnosticoList}
            {...register(nameDiagnosticoRegister, {
              validate: () => recipe?.cie10 || false,
            })}
            value={recipe?.cie10}
            onSelectedDiagnostico={onChangeDiagnosticoSelected}
            diagnostico={recipe?.cie10}
          />
          {errors[nameDiagnosticoRegister] && (
            <p style={{ color: 'red', marginTop: '1px' }}>Debe seleccionar un diagnóstico</p>
          )}
        </Grid>

        <Grid item sm={2} xs={3}>
          <UltimosDiagnoticos
            idAfiliado={idAfiliado}
            onSelectEvent={onChangeLatestDiagnosticoSelected}
          />
        </Grid>

        <Grid item sm={10} xs={9}>
          {/* Componente medicamento */}
          <MedicamentoSelector
            name={nameMedicamentoRegister}
            medicamento={recipe?.medicamento}
            {...register(nameMedicamentoRegister, {
              validate: {
                medicamentoSeleccionado: () =>
                  recipe?.medicamento?.nombre ? true : 'Debe seleccionar el medicamento',
                cantidadMedicamentoSeleccionado: () =>
                  recipe?.medicamento?.cantidad > 0
                    ? true
                    : 'La cantidad del medicamento seleccionado debe ser mayor a 1',
                maxCantidadMedicamentoSeleccionado: () =>
                  Number(recipe?.medicamento?.cantidad) < 100
                    ? true
                    : 'La cantidad del medicamento seleccionado debe ser menor a 100',
                medicamentoReSeleccionado: () =>
                  handleIsMedicamentoRepetido(recipe?.medicamento)
                    ? true
                    : 'El medicamento seleccionado ya se encuentra cargado en otro RP',
              },
            })}
            errorSelector={errors[nameMedicamentoRegister]?.message}
            onSelectedMedicamento={onChangeMedicamentoSelected}
          />
          {errors[nameMedicamentoRegister] && screenWidth !== 'xs' && (
            <p style={{ color: 'red', marginTop: '1px' }}>
              {errors[nameMedicamentoRegister].message}
            </p>
          )}
        </Grid>

        <Grid item sm={2} xs={3}>
          <UltimosMedicamentos
            idAfiliado={idAfiliado}
            onSelectEvent={onChangeLatestMedicamentoSelected}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            pt: 2,
            justifyContent: 'flex-end',
            // py: 4,
            // pr: { xs: 1, sm: 4 },
          }}
        >
          <Button
            aria-label='Eliminar RT.'
            variant='contained'
            color='neutral'
            size='medium'
            startIcon={<Delete />}
            onClick={() => onDeleteRecipe()}
          >
            Eliminar
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default Recipe;
