import { axiosGet, axiosPost } from '../ServiceBase.js';

export const getPrestadores = async () => {
  try {
    const prestadores = JSON.parse(sessionStorage.getItem('prestadores'));

    if (prestadores === null) {
      const prestadores = await axiosGet('/v1/busquedas/prestadores');
      sessionStorage.setItem('prestadores', JSON.stringify(prestadores));
      return prestadores;
    } else {
      return prestadores;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPrestadorLogueado = async () => {
  try {
    const prestadorLogueado = JSON.parse(sessionStorage.getItem('prestadorLogueado'));

    if (prestadorLogueado === null) {
      const prestador = await axiosGet('/v1/busquedas/prestadores/prestadorLogueado');
      sessionStorage.setItem('prestadorLogueado', JSON.stringify(prestador));
      return prestador;
    } else {
      return prestadorLogueado;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const seguirPrestadorLogin = async () => {
  return axiosGet('/v1/prestador/seguir-prestador');
};

export const getPrescriptorSelector = async (data) => {
  const prestadores = await axiosPost('/v1/busquedas/prescriptorSelector', data);
  return prestadores;
};

export const getPrestadorSelector = async (data) => {
  const prestadores = await axiosPost('/v1/busquedas/prestadorSelector', data);
  return prestadores;
};

export const getPrestadorAll = async (data) => {
  try {
    const prestadores = JSON.parse(
      sessionStorage.getItem(data.modoPrescriptor ? 'prescriptoresAll' : 'prestadoresAll'),
    );
    if (prestadores === null) {
      const prestadores = await axiosPost('/v1/busquedas/prestadoresAll', data);
      sessionStorage.setItem(
        data.modoPrescriptor ? 'prescriptoresAll' : 'prestadoresAll',
        JSON.stringify(prestadores),
      );
      return prestadores;
    } else {
      return prestadores;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUltimosPrestadoresOrden = async (dataQuery) => {
  const prestadores = await axiosPost(`/v1/busquedas/prestadores/get-latest-orden${dataQuery}`);
  return prestadores;
};
