export const codInternal = [
  { code: 400, msg: 'Datos inválidos' },
  { code: 401, msg: 'No autorizado.' },
  { code: 403, msg: 'Error de conexión.' },
  { code: 404, msg: 'Error de conexión.' },
  { code: 408, msg: 'Error de conexión.' },
  { code: 413, msg: 'Error de conexión.' },
  { code: 414, msg: 'Error de conexión.' },
  { code: 415, msg: 'Error de conexión.' },
  { code: 500, msg: 'Error de servidor. Disculpe las molestias' },
  { code: 501, msg: 'Error de servidor. Disculpe las molestias' },
  { code: 502, msg: 'Error de servidor. Disculpe las molestias' },
  { code: 503, msg: 'Error de servidor. Disculpe las molestias' },
  { code: 504, msg: 'Error de servidor. Disculpe las molestias' },
];
