import { axiosGet, axiosPost, axiosPostFileExcel } from '../ServiceBase.js';

export const findOrdenes = async (idPrestador, periodo, codigoTipoOrden) => {
  let params = '';

  if (idPrestador) {
    params = `?idPrestador=${idPrestador}`;
  }

  if (periodo) {
    params += params === '' ? `?periodo=${periodo}` : `&periodo=${periodo}`;
  }

  if (codigoTipoOrden) {
    params +=
      params === '' ? `?codigoTipoOrden=${codigoTipoOrden}` : `&codigoTipoOrden=${codigoTipoOrden}`;
  }

  return await axiosGet(`/v1/ordenes/prestadores${params || ''}`);
};

export const findOrdenesElectronicasByAfiliado = async (data) => {
  const ordenes = await axiosPost('/v1/orden-digital/find-by-afiliado', data);
  return ordenes;
};

export const generarExcel = async (data) => {
  return axiosPostFileExcel('/v1/ordenes/reporte/excel', data);
};
