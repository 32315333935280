import React, { useState, useEffect } from 'react';
import CustomizedSnackbars from '../../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import { getContactosByUser } from '../../../services/Common/Contacto';
import SinCorreo from './SinCorreo';
import LoadingButton from '../../../@iosper/components/LoadingButton';
import logo from '../../../assets/images/logo-small.png';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Redirect } from 'react-router-dom';
import ReCaptcha from '../../Common/Recaptcha';
import SeleccionarContacto from './SeleccionarContacto';
import MensajeDeConfirmacionModal from '../../Notifications/MensajeDeConfirmacionModal';
import ButtonBack from '../../Breadcrumb/ButtonBack';

const OlvidePin = () => {
  const { handleSubmit } = useForm({ mode: 'onBlur' });

  const [username, setUsername] = useState('');
  const [cuilCuit, setCuilCuit] = useState('');
  const [contactos, setContactos] = useState(null);
  const [sinContacto, setSinContacto] = useState(false);

  const [openSeleccionar, setOpenSeleccionar] = useState(false);
  const [openMensajeConfirmacion, setOpenMensajeConfirmacion] = useState(false);
  const [onCancelar, setOnCancelar] = useState(false);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [recaptchaValidado, setRecaptchaValidado] = useState(false);

  const onRecaptchaValidado = (validado) => {
    setLoading(false);
    setRecaptchaValidado(validado);
  };

  const onRecaptchaChange = () => {
    setLoading(true);
  };

  const onRecaptchaError = (e) => {
    setLoading(false);
  };

  const contactosHandler = (contactos) => {
    setLoading(true);
    setOpenSeleccionar(true);
    setContactos(contactos);
  };
  const sinContactoHandler = () => {
    setLoading(true);
    setSinContacto(true);
  };
  const cancelarHandler = () => {
    setOnCancelar(true);
  };

  const handleCloseSinContacto = () => {
    setSinContacto(false);
    setLoading(false);
  };
  const handleCloseSeleccionar = (contacto) => {
    setOpenSeleccionar(false);
    setLoading(false);
    setOpenMensajeConfirmacion(contacto ? true : false);
  };

  const handleCloseMensajeConfirmacion = () => {
    setOpenMensajeConfirmacion(false);
  };

  const recuperarPinHandler = async (data, e) => {
    setLoading(true);
    setResponse('');
    try {
      const res = await getContactosByUser(username, cuilCuit);

      if (res !== undefined && res !== null && res.length > 0) {
        if (res.length === 1) {
          contactosHandler(res);
        } else {
          contactosHandler(res);
        }
      } else {
        sinContactoHandler(true);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {}, []);

  const handleBorrarMensaje = () => {
    setResponse('');
  };

  return onCancelar ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/signin' },
      }}
    />
  ) : (
    <Container component='main' maxWidth='sm'>
      <ButtonBack />
      <Card>
        <CardContent sx={{ my: { xs: 2, md: 4 }, mx: { xs: 2, md: 5 } }}>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            gap={2}
            mb={4}
          >
            <Avatar alt='IOSPER' src={logo} sx={{ width: 70, height: 70 }} />
            <Typography gutterBottom variant='h5' component='h2'>
              Olvidé Contraseña
            </Typography>
          </Grid>

          <form onSubmit={handleSubmit(recuperarPinHandler)}>
            <CardContent>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <TextField
                  variant='standard'
                  margin='normal'
                  fullWidth
                  id='cuilcuit'
                  label='CUIT'
                  name='cuilcuit'
                  autoFocus
                  value={cuilCuit || ''}
                  onChange={(e) => setCuilCuit(e.target.value)}
                />
                <TextField
                  variant='standard'
                  margin='normal'
                  fullWidth
                  id='username'
                  label='Nombre de usuario'
                  name='username'
                  value={username || ''}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <ReCaptcha
                  sitekey={process.env.REACT_APP_IOSPER_RECAPTCHA_SITE_KEY_SECRET}
                  onRecaptchaValidado={onRecaptchaValidado}
                  onChange={onRecaptchaChange}
                  onError={onRecaptchaError}
                />
              </FormControl>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems={{ xs: 'strech' }}
              >
                <Button variant='contained' size='medium' color='neutral' onClick={cancelarHandler}>
                  Cancelar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  type='submit'
                  disabled={!recaptchaValidado || (username === '' && cuilCuit === '')}
                  loading={loading ? 'show' : 'hide'}
                  content={'Recuperar Contraseña'}
                />
              </Stack>
            </CardActions>
          </form>
        </CardContent>
      </Card>

      <SinCorreo open={sinContacto} onClose={handleCloseSinContacto} />

      <SeleccionarContacto
        contactos={contactos}
        username={username}
        cuilcuit={cuilCuit}
        open={openSeleccionar}
        onClose={handleCloseSeleccionar}
      />

      <MensajeDeConfirmacionModal
        titleModal='Envio confirmado'
        messagePrimary='Se envió un correo a su casilla para completar la recuperación de su contraseña.'
        messageSecundary={'¿No recibió ningún correo? Recuerde revisar en el correo no deseado.'}
        open={openMensajeConfirmacion}
        onClose={handleCloseMensajeConfirmacion}
      />

      <CustomizedSnackbars
        open={response}
        severity='success'
        message={response}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Container>
  );
};

export default OlvidePin;
