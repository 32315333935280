import { TextField } from '@mui/material';

const DateTextInput = ({
  fieldName,
  label,
  register,
  errors,
  variant,
  required,
  value,
  change,
}) => {
  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        name={fieldName}
        label={label}
        type='date'
        defaultValue={value}
        id={fieldName}
        onChange={(newValue) => {
          console.log(newValue.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...register(fieldName, {
          required: {
            value: required,
            message: 'Debe ingresar ' + label,
          },
          onChange: (e) => change(e.target.value),
        })}
      />
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};
DateTextInput.defaultProps = {
  required: true,
  variant: 'standard',
};
export default DateTextInput;
