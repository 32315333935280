import React, { useState } from 'react';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { HighlightOff, Search } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { validarReimpresionOrden } from '../../services/Seo/Orden';
import OrdenPreview from './OrdenPreview';
import OrdenParaReimprimir from './OrdenParaReimprimir';
import { LoadingButton } from '@mui/lab';

const ReimprimirOrden = () => {
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);

  // Dialog Orden
  const [open, setOpen] = useState(false);
  const [datosOrdenReceived, setDatosOrdenReceived] = useState({});
  const [loadingOrden, setLoadingOrden] = useState(false);

  // Dialog Orden Preview
  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setLoadingOrden(true);

    try {
      const res = await validarReimpresionOrden(getAfiliadoData());
      setDatosOrdenReceived(res);
      setOpen(!open);
      setLoadingOrden(false);
    } catch (e) {
      setLoadingOrden(false);
    }
  };

  const getAfiliadoData = () => {
    let data = {
      idAfiliado: selectedAfiliado.idAfiliado,
    };
    return data;
  };

  const handlerLimpiar = () => {
    reset();
    setSelectedAfiliado(null);
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handlerCerrarPreview = () => {
    handlerLimpiar();
  };

  const handlerReimprimir = (datosOrden, urlPdf) => {
    setUrlReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenDialogReporte(true);
  };

  // Determinar si hay errores
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Grid container spacing={1}>
      <ButtonBack />
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Reimprimir Orden Afiliado
            </Typography>

            <Typography variant='string' component={'div'}>
              Acá podrás volver a imprimir la última orden impresa que generaste para el afiliado
              que ingreses en pantalla
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth={true} variant='outlined' margin='normal' sx={{ pt: 4 }}>
                <BuscarAfiliado
                  key={keyComponentAfiliado + '__afiliado'}
                  onSelectAfiliado={(afiliado) => setSelectedAfiliado(afiliado)}
                  ref={register('afiliadoRegister', {
                    validate: validateAfiliado,
                  })}
                />
                {errors.afiliadoRegister != null && (
                  <p style={{ color: 'red' }}>Debe ingresar el afiliado</p>
                )}
              </FormControl>
            </form>
          </CardContent>
          <CardActions sx={{ px: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                  }}
                >
                  <Button
                    variant='contained'
                    color='neutral'
                    startIcon={<HighlightOff />}
                    onClick={handlerLimpiar}
                  >
                    Limpiar
                  </Button>

                  <LoadingButton
                    variant='contained'
                    color='primary'
                    startIcon={<Search />}
                    disabled={!selectedAfiliado?.idAfiliado || hasErrors}
                    type='submit'
                    loading={loadingOrden}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Buscar
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>

        <OrdenParaReimprimir
          open={open}
          setOpen={setOpen}
          datosOrden={datosOrdenReceived}
          handlerReimprimir={handlerReimprimir}
        ></OrdenParaReimprimir>

        <OrdenPreview
          open={openDialogReporte}
          setOpen={setOpenDialogReporte}
          urlReporte={urlReporte}
          datosOrden={datosOrdenGenerada}
          showMail={true}
          nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
          handlerCerrarPreview={handlerCerrarPreview}
        ></OrdenPreview>
      </Grid>
    </Grid>
  );
};

export default ReimprimirOrden;
