import { axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * Registra la atencion de una orden
 * @returns
 */
export const registrarAtencion = async (data) => {
  return axiosPost('/v1/orden/registrar-atencion', data);
};

/**
 * Registra la atencion de una orden
 * @returns
 */
export const anularAtencion = async (data) => {
  return axiosPost('/v1/orden/anular-atencion', data);
};

export const buscarOrden = async (data) => {
  return axiosPost('/v1/orden/buscar', data);
};

export const findByOrdenAfiliado = async (data) => {
  return axiosPost('/v1/orden/find-by-orden-afiliado', data);
};

/**
 * Validar orden api call
 * @returns
 */
export const getValidarOrden = async (data) => {
  return axiosPost('/v1/orden/validar-orden', data);
};

/**
 * Generar orden api call
 * @returns
 */
export const getGenerarOrden = async (codigo) => {
  return axiosPost('/v1/orden/generar-orden', { codigoTransaccion: codigo });
};

/**
 * Imprimir orden api call
 * @returns
 */
export const getImprimirOrden = async (data) => {
  return axiosPostFile('/v1/orden/imprimir-orden', {
    numeroOrden: data.numeroOrden,
    codigoTransaccion: data.codigoTransaccion,
  });
};

/**
 * Verificar costo orden api call
 * @returns
 */
export const getVerificarCostoOrden = async (data) => {
  return axiosPost('/v1/orden/verificar-costo-orden', data);
};

/**
 * Reimprimir orden api call
 * @returns
 */
export const enviarOrden = async (data) => {
  return axiosPostFile('/v1/orden/enviar-orden', {
    numeroOrden: data.numeroOrden,
    correo: data.correo,
  });
};

export const consultarCuposOrdenMedica = async (data) => {
  return axiosPost('/v1/orden/consultar-cupo-orden', data);
};

export const anularOrden = async (data) => {
  return axiosPost('/v1/orden/anular-orden-impresa', data);
};

export const validarReimpresionOrden = async (data) => {
  return axiosPost('/v1/orden/validar-reimpresion', data);
};

export const getReimprimirOrden = async (data) => {
  return axiosPostFile('/v1/orden/reimpresion-orden', { idOrden: data.numeroOrden });
};
