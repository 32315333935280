import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  Stack,
  TextField,
  ListItem,
  ListItemText,
  Grid,
  Button,
  ToggleButton,
  Typography,
  Collapse,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { findBy } from '../../services/Busquedas/Afiliados';
import { Box } from '@mui/system';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import ModalBase from '../Controls/ModalBase';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { useRef } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const DataCollapseDrawer = (props) => {
  const { afiliado } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        selected={open}
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body1'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {`${afiliado.nya || ''} `}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`N° Afiliado - ${afiliado?.numeroAfiliado || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`N° Documento - ${afiliado?.numeroDocumento || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Localidad' secondary={`${afiliado?.localidad || ''}`} />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const BuscarAfiliado = React.forwardRef(({ initialValue, onSelectAfiliado, ...props }, ref) => {
  const screenWidth = useWidth();
  const [labelTextFieldAfiliado, setLabelTextFieldAfiliado] = useState(
    'Ingresá el documento del afiliado',
  );
  const [selectedAfiliado, setSelectedAfiliado] = useState(initialValue);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [errorEstadoAfiliado, setErrorEstadoAfiliado] = useState(null);
  const [showAfiliado, setShowAfiliado] = useState(false);
  const [openSeleccionarAfiliado, setOpenSeleccionarAfiliado] = useState(false);
  const [afiliadosList, setAfiliadosList] = useState(null);
  const [rowSelectedDataGrid, setRowSelectedDataGrid] = useState(null);
  const [readOnlyTextFieldAfiliado, setReadOnlyTextFieldAfiliado] = useState(false);
  const inputAfiliadoRef = useRef(null);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (initialValue != null) {
      setAfiliadoSelected(initialValue);
    } else {
      clean();
    }
  }, [initialValue]);

  const buscar = async (numeroDocumento) => {
    if (numeroDocumento?.length > 6) {
      const result = await findBy({
        numeroDocumento: numeroDocumento,
      });

      if (result && result.length > 0) {
        if (result.length === 1) {
          const afi = result[0];
          setSelectedAfiliado(afi);
          onSelectAfiliado(afi);
          setShowAfiliado(true);
          setErrorEstadoAfiliado(afi?.grupoEstado === 2 ? afi.estado : null);
          setLabelTextFieldAfiliado('N° Documento');
          setReadOnlyTextFieldAfiliado(true);
        } else {
          setOpenSeleccionarAfiliado(true);
          setAfiliadosList(result);
        }
      } else {
        setAlertResultFind('No se han encontrado afiliados');
      }
    }
  };

  const find = async (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    buscar(data?.numeroDocumento);
  };

  const handleKeyUpSubmit = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.key === ' ' || e.keyCode === 32) {
      e.preventDefault();
      e.stopPropagation();
      buscar(getValues('numeroDocumento'));
    }
  };

  const handleClose = () => {
    setOpenSeleccionarAfiliado(false);
  };

  const handleIconClose = (e) => {
    e.preventDefault();
    clean();
  };

  const handleOnRowDoubleClick = (data, e) => {
    e.preventDefault();
    selectHandler(e);
    setRowSelectedDataGrid(data);
  };

  const clean = () => {
    onSelectAfiliado(null);
    setSelectedAfiliado(null);
    setShowAfiliado(false);
    setAfiliadosList([]);
    setValue('numeroDocumento', '');
    setLabelTextFieldAfiliado('Ingresá el documento del afiliado');
    setReadOnlyTextFieldAfiliado(false);
    setErrorEstadoAfiliado(null);
    reset();
    // inputAfiliadoRef.current.focus();
  };

  const selectHandler = (e) => {
    e.preventDefault();
    setAfiliadoSelected(rowSelectedDataGrid);
  };

  const setAfiliadoSelected = (value) => {
    setSelectedAfiliado(value);
    onSelectAfiliado(value);
    handleClose();
    setShowAfiliado(true);
    setLabelTextFieldAfiliado('N° Documento');
    setReadOnlyTextFieldAfiliado(true);
  };

  const mapAfiliadoToRow = (a) => ({
    id: a.idAfiliado,
    numeroAfiliado: a.numeroAfiliado,
    nya: `${a?.apellido}, ${a?.nombre} `,
    nombre: a.nombre,
    apellido: a.apellido,
    numeroDocumento: `${a?.numeroDocumento} (${a?.tipoDocumento})`,
    localidad: a.localidad,
    edad: a.edad,
    estado: a.estado,
    grupoEstado: a.grupoEstado,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Afiliado',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer afiliado={a.row} />,
    },
    { field: 'numeroAfiliado', headerName: 'N° Afiliado', width: 120 },
    { field: 'nya', headerName: 'Apellido y Nonbre', flex: 0.1 },
    { field: 'numeroDocumento', headerName: 'N° Documento', flex: 0.1 },
    { field: 'localidad', headerName: 'Localidad', flex: 0.1 },
  ];

  const dataGridAfiliados = (
    <Grid container direction='column' justifyContent='space-between' spacing={1}>
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '8px',
              },
            }}
            loading={!afiliadosList?.length}
            rows={afiliadosList?.map((a) => mapAfiliadoToRow(a))}
            columns={columns}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              nya: screenWidth !== 'xs',
              numeroAfiliado: screenWidth !== 'xs',
              numeroDocumento: screenWidth !== 'xs',
              localidad: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            onRowClick={(selected) => {
              setRowSelectedDataGrid(selected.row);
            }}
            onRowDoubleClick={handleOnRowDoubleClick}
          />
        </div>
      </Grid>
      <Grid item>
        <Grid container justifyContent='flex-end' spacing={1}>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='primary'
              disabled={!rowSelectedDataGrid}
              startIcon={<CheckCircleIcon />}
              onClick={(e) => selectHandler(e)}
            >
              Seleccionar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const handleBorrarMensaje = () => {
    setAlertResultFind('');
  };

  const afiliadoSeleccionado = (
    <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
      <TextField
        id='afiliado-nya'
        label={` ${selectedAfiliado?.numeroDocumento}`}
        value={` ${selectedAfiliado?.apellido}, ${selectedAfiliado?.nombre} (${selectedAfiliado?.edad} años) `}
        fullWidth
        variant='standard'
        color={selectedAfiliado?.grupoEstado !== 2 ? 'warning' : 'primary'}
        focused
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' onClick={handleIconClose}>
              <IconButton variant='outlined' aria-label='Buscar Afiliado' size='large' edge='end'>
                <CloseSharpIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      {selectedAfiliado?.grupoEstado && selectedAfiliado.grupoEstado !== 2 && (
        <p style={{ color: '#F58638' }}>{selectedAfiliado?.estado}</p>
      )}
    </FormControl>
  );

  const formAfiliado = (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2 }}
      justifyContent={{ xs: 'center', sm: 'flex-end' }}
      alignItems={{ xs: 'strech' }}
    >
      {!showAfiliado && (
        <FormControl sx={{ width: '100%' }} variant='standard' onBlur={handleSubmit(find)}>
          <InputLabel htmlFor='outlined-adornment-afiliado'>{labelTextFieldAfiliado}</InputLabel>
          <Input
            id='outlined-adornment-prestador'
            name='afiliado'
            aria-describedby='outlined-afiliado-helper-text'
            inputRef={inputAfiliadoRef}
            defaultValue={initialValue}
            readOnly={readOnlyTextFieldAfiliado}
            variant='standard'
            type='tel'
            inputMode='numeric'
            onKeyDownCapture={(e) => handleKeyUpSubmit(e)}
            {...register('numeroDocumento', {
              maxLength: {
                value: 8,
                message: 'El número de documento es demasiado largo',
              },
              pattern: {
                value: /^[0-9]+$/,
                message: 'El número de documento debe contener sólo números',
              },
            })}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton variant='outlined' aria-label='Buscar Afiliado' size='large' edge='end'>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.numeroDocumento && (
            <p style={{ color: 'red' }}>{errors.numeroDocumento.message}</p>
          )}
          {showAfiliado && errorEstadoAfiliado && (
            <p style={{ color: 'red' }}>{afiliadoSeleccionado.estado}</p>
          )}
        </FormControl>
      )}
      {showAfiliado && <>{afiliadoSeleccionado}</>}
    </Stack>
  );

  return (
    <Box sx={{ width: '100%', marginBottom: '5px', marginTop: '-20px' }}>
      {formAfiliado}
      {openSeleccionarAfiliado && (
        <ModalBase
          fullWidth={true}
          open={openSeleccionarAfiliado}
          content={dataGridAfiliados}
          title='Seleccioná el afiliado'
          onClose={handleClose}
        />
      )}

      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Box>
  );
});

export default BuscarAfiliado;
