import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, FormControl, TextField } from '@mui/material';
import DetalleCoberturaOrdenData from './components/DetalleCoberturaOrdenData';

const VerificarCostoOrdenView = (props) => {
  const [datosOrden, setDatosOrden] = useState({});

  useEffect(() => {
    if (props.datosOrden) {
      setDatosOrden(props.datosOrden);
    }

    return function cleanup() {
      setDatosOrden({});
    };
  }, [props.datosOrden]);

  if (!datosOrden) {
    return null;
  }

  const handleClose = () => {
    setDatosOrden({});
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Verificar Costo Orden de Prestación'}</DialogTitle>
        <DialogContent>
          {datosOrden.practicas && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='practica'
                multiline
                variant='standard'
                label='Práctica'
                defaultValue={
                  datosOrden.practicas &&
                  Object.values(datosOrden.practicas).map(
                    (practica) => `(${practica.codigoPractica}) ${practica.descripcionPractica}`,
                  )
                }
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden.totales && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='coseguro'
                variant='standard'
                label='Coseguro a pagar al Prestador'
                defaultValue={`$ ${datosOrden.totales.totalPagar}`}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { fontWeight: 'bold' },
                }}
              />
            </FormControl>
          )}
          {datosOrden.practicas && (
            <DetalleCoberturaOrdenData
              detallePracticas={datosOrden.practicas}
            ></DetalleCoberturaOrdenData>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} variant='contained' color='neutral' autoFocus>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerificarCostoOrdenView;
