import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PrestadorSelect from '../Prestador/PrestadorSelect';
import { HighlightOff } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { findOrdenesElectronicasByAfiliado } from '../../services/Busquedas/Ordenes';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import CustomizedSnackbars from '../Notifications/SnackBar';
import BuscarAnularOrdenElectronicaTableResult from './BuscarAnularOrdenElectronicaTableResult';
import PrestadorAutocompleteVirtualizado from '../Prestador/PrestadorAutocompleteVirtualizado';
import { UltimosPrestadoresOrden } from '../Prestador/UltimosPrestadoresOrden';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';
import { LoadingButton } from '@mui/lab';

export const BuscarAnularOrdenElectronica = () => {
  const [visibleFind, setVisibleFind] = useState(true);
  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [keyComponentSelectPrestador, setKeyComponentSelectPrestador] = useState(0);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ordenesElectronicas, setOrdenesElectronicas] = useState(null);
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [prestadorLogueado, setPrestadorLogueado] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const getData = () => {
    let data = {
      idPrestador: selectedPrestador.idPrestador,
      numeroAfiliado: selectedAfiliado?.numeroAfiliado,
    };
    return data;
  };

  const find = async (data, e) => {
    e.preventDefault();
    onBuscar(getData());
  };

  const onBuscar = async (data) => {
    setOrdenesElectronicas([]);
    setLoading(true);
    setAlertResultFind('');

    try {
      const res = await findOrdenesElectronicasByAfiliado(data);
      setOrdenesElectronicas(res);
      if (res.length > 0) {
        setVisibleFind(false);
      } else {
        setAlertResultFind('Busqueda sin resultados, intente con otros parámetros.');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador != null) {
        setPrestadorLogueado(prestador);
        setSelectedPrestador(prestador);
      }
    };

    if (prestadorLogueado === null) {
      getPrestador();
    }
  }, []);

  const handleVolver = () => {
    setOrdenesElectronicas([]);
    setVisibleFind(true);
    handlerLimpiar();
  };

  const handlerLimpiar = () => {
    reset();
    setSelectedAfiliado(null);
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    setKeyComponentSelectPrestador(1 + Math.random() * (1000 - 1));
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const refresh = () => {
    onBuscar(getData());
  };

  const handleBorrarMensaje = () => {
    setAlertResultFind('');
  };

  const validatePrestador = () => {
    // Si no esta activo seguimos mostrando que debe seleccionar un prestador
    return selectedPrestador !== null ? true : false;
  };

  const handlerSelectPrestador = (prestador) => {
    setSelectedPrestador(prestador);
  };
  const handlerUltimoPrestador = (prestador) => {
    handlerSelectPrestador(prestador);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5'>
              Anular Orden Electrónica
            </Typography>
            <Typography gutterBottom>
              Acá podrás anular órdenes electrónicas que fueron emitidas dentro de las últimas 24
              hs.
            </Typography>
          </CardContent>
          {visibleFind && (
            <form>
              <CardContent>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <BuscarAfiliado
                    key={keyComponentAfiliado + '__afiliado'}
                    onSelectAfiliado={(afiliado) => setSelectedAfiliado(afiliado)}
                    ref={register('afiliadoRegister', {
                      validate: validateAfiliado,
                    })}
                  />
                  {errors.afiliadoRegister != null && (
                    <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
                  )}
                </FormControl>

                <Grid container spacing={3} alignItems='center'>
                  <Grid item xs={prestadorLogueado?.idProfesional ? 12 : 11}>
                    {prestadorLogueado?.idProfesional ? (
                      <PrestadorSelect
                        key={keyComponentSelectPrestador + '_prestador'}
                        value={selectedPrestador}
                        disabled={selectedPrestador !== null ?? true}
                        name='prestadorRegister'
                        {...register('prestadorRegister', {
                          validate: validatePrestador,
                        })}
                        onSelectPrestador={handlerSelectPrestador}
                      />
                    ) : (
                      <PrestadorAutocompleteVirtualizado
                        key={keyComponentSelectPrestador + '_prestador'}
                        value={selectedPrestador}
                        disabled={prestadorLogueado !== null ?? true}
                        name='prestadorRegister'
                        {...register('prestadorRegister', {
                          validate: () => selectedPrestador?.idPrestador || false,
                        })}
                        onSelectedPrestador={handlerSelectPrestador}
                      />
                    )}
                  </Grid>
                  {!prestadorLogueado?.idProfesional && (
                    <Grid item xs={1}>
                      <UltimosPrestadoresOrden onSelectEvent={handlerUltimoPrestador} />
                    </Grid>
                  )}
                </Grid>

                {errors.prestadorRegister && (
                  <p style={{ color: 'red' }}>Debe seleccionar un prestador</p>
                )}
              </CardContent>
              <CardActions sx={{ px: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                      }}
                    >
                      <Button
                        variant='contained'
                        size='medium'
                        startIcon={<HighlightOff />}
                        color='neutral'
                        onClick={handlerLimpiar}
                      >
                        Limpiar
                      </Button>
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        loading={loading}
                        startIcon={<SearchIcon />}
                        onClick={handleSubmit(find)}
                      >
                        Buscar
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          )}

          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item>
                <BuscarAnularOrdenElectronicaTableResult
                  ordenesElectronicas={ordenesElectronicas}
                  onRefresh={refresh}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {' '}
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => handleVolver()}
                    >
                      Buscar Otras
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarAnularOrdenElectronica;
