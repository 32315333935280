import React from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';

const DataCollapseDrawer = (props) => {
  const { orden } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        selected={open}
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body1'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {orden.numero}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Fecha' secondary={orden?.fecha || ''} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Afiliado' secondary={`${orden?.afiliado}` || ''} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Práctica' secondary={`${orden?.practica}` || ''} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Honorarios' secondary={`$ ${orden?.honorarios}` || ''} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Coseguro' secondary={`$ ${orden?.coseguro}` || ''} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary='Honorarios a pagar'
                secondary={`$ ${orden?.honorariosAPagar}` || ''}
              />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const OrdenesTable = ({ ordenes }) => {
  const screenWidth = useWidth();

  const mapOrdenesToRow = (a, index) => ({
    resume: a,
    id: index,
    numero: a.numero,
    fecha: a.fecha,
    practica: a.descripcion,
    honorarios: a.importe,
    coseguro: a.coseguropagado,
    honorariosAPagar: a.honorariosAPagar,
    afiliado: a.nya + ' (' + a.numeroDocumento + ')',
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Nro Orden',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer orden={a.row} />,
    },
    { field: 'fecha', headerName: 'Fecha Emisión', width: 160 },
    { field: 'numero', headerName: 'Nro Orden', width: 100 },
    { field: 'practica', headerName: 'Práctica', flex: 1 },
    { field: 'afiliado', headerName: 'Afiliado', flex: 1 },
    {
      field: 'honorarios',
      width: 100,
      headerName: 'Honorarios',
      renderCell: (params) => <div>{`$ ${params.row.honorarios}`}</div>,
    },
    {
      field: 'coseguro',
      width: 100,
      headerName: 'Coseguro',
      renderCell: (params) => <div>{`$ ${params.row.coseguro}`}</div>,
    },
    {
      field: 'honorariosAPagar',
      width: 150,
      headerName: 'Honorarios a pagar',
      renderCell: (params) => <div>{`$ ${params.row.honorariosAPagar}`}</div>,
    },
  ];

  return (
    <div style={{ height: '60vh', width: '100%' }}>
      <DataGrid
        sx={{
          border: 0,
          '& .MuiDataGrid-cell': {
            py: '8px',
          },
        }}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        loading={!ordenes.length}
        rows={ordenes.map((a, index) => mapOrdenesToRow(a, index))}
        columns={columns}
        autoPageSize
        pagination={screenWidth === 'xs' ? null : true}
        disableSelectionOnClick
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs',
          practica: screenWidth !== 'xs',
          afiliado: screenWidth !== 'xs',
          fecha: screenWidth !== 'xs',
          honorarios: screenWidth !== 'xs',
          coseguro: screenWidth !== 'xs',
          honorariosAPagar: screenWidth !== 'xs',
        }}
      />
    </div>
  );
};

export default OrdenesTable;
