// Config.js
import axios from 'axios';

//\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
// SET axios base URL for IOSPER API
axios.defaults.baseURL = `${process.env.REACT_APP_IOSPER_ENDPOINT_BASE_URL}`;

//\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
// Add a request interceptor
// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   console.log('Request', config);
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

//\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
// Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     // Do something with response data
//     console.log('Response', response);
//     return response;
//   }, function (error) {
//     // Do something with response error
//     return Promise.reject(error);
//   });

export default axios;
