import React, { useState, useEffect } from 'react';
import LoadingButton from '../../../@iosper/components/LoadingButton';
import ModalBase from '../../Controls/ModalBase';
import { recuperarPinMiConsultorio } from '../../../services/Auth/Autenticacion';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

const SeleccionarContacto = React.forwardRef((props, ref) => {
  const { open, setOpen, contactos, onClose, username, cuilcuit } = props;

  const [value, setValue] = useState(null);
  const [correo, setCorreo] = useState(null);
  const [loading, setLoading] = useState(false);

  const emails =
    contactos != null
      ? contactos.map((item) => {
          return (
            <FormControlLabel
              key={`contacto-${item.idHistorialContactoPersonaJuridica}`}
              value={item.idHistorialContactoPersonaJuridica}
              control={<Radio />}
              label={item.descripcion}
            />
          );
        })
      : null;

  const find = (id) => {
    for (let i = 0; i < contactos.length; i++) {
      if (contactos[i].idHistorialContactoPersonaJuridica === id) {
        return contactos[i];
      }
    }
  };

  const restablecerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await recuperarPinMiConsultorio(username, cuilcuit, value);
      setLoading(false);
      onClose(correo);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue(parseInt(e.target.value));
    var correo = find(e.target.value);
    setCorreo(correo);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (contactos !== undefined && contactos !== null) {
      setCorreo(contactos[0]);
      setValue(contactos[0].idHistorialContactoPersonaJuridica);
    }
  }, [contactos]);

  const form = (
    <div>
      <Grid container spacing={3} direction='column' justifyContent='left' alignItems='left'>
        <Grid item>
          <Typography align='center' variant='body2'>
            Verificá el correo seleccionado al que enviaremos los pasos para restablecer tu
            contraseña
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={3}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item>
              <FormControl component='fieldset'>
                <RadioGroup
                  size='small'
                  color='primary'
                  name='controlled-radio-buttons-group'
                  value={value}
                  onChange={handleChange}
                >
                  {emails}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems={{ xs: 'strech' }}
          >
            <LoadingButton
              fullWidth={true}
              variant='contained'
              color='primary'
              disabled={false}
              size='medium'
              loading={loading ? 'show' : 'hide'}
              content={'Restablecer'}
              onClick={(e) => restablecerHandler(e)}
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Restablecer contraseña'
        onClose={handleClose}
      />
    </Box>
  );
});

export default SeleccionarContacto;
