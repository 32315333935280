import { useState, useEffect } from 'react';
import { Avatar, Box, Fab, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FormatListNumbered } from '@mui/icons-material';
import { findRecetasElectronicas } from '../../services/Busquedas/RecetasElectronicas';

export const UltimasRecetasElectronicas = ({
  idAfiliado = null,
  limit = 10,
  onSelectEvent = null,
  onTieneRecetasEvent = null,
}) => {
  const [latestOption, setLatestOption] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getLatestOptions();
  }, [idAfiliado]);

  const getLatestOptions = async () => {
    if (idAfiliado) {
      const result = await findRecetasElectronicas({
        idAfiliado: idAfiliado,
        conEstado: false, //lo seteo en false para que no consulte el estado en cofaer
        limite: limit,
      });
      if (result.length > 0) {
        setLatestOption(result);
        if (onTieneRecetasEvent) {
          onTieneRecetasEvent(true);
        }
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleSelect = async (receta) => {
    setAnchorEl(null);
    if (onSelectEvent) {
      onSelectEvent(receta);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: { sm: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title='Últimas recetas del afiliado seleccionado'>
          <Fab
            onClick={handleClick}
            size='small'
            color='default'
            sx={{ boxShadow: 'none' }}
            aria-controls={open ? 'account-menu-recetas' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <FormatListNumbered fontSize='small' color='primary' />
          </Fab>
        </Tooltip>
      </Box>
      <Menu
        id='account-menu-recetas'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {latestOption.length === 0 && <MenuItem disabled>Sin recetas generadas...</MenuItem>}
        {latestOption.length > 0 &&
          latestOption.map((recetaElectronica) => (
            <MenuItem
              key={recetaElectronica.recetaElectronica?.idRecetaElectronica}
              onClick={(e) => onHandleSelect(recetaElectronica)}
            >
              <strong>&nbsp;Rp1:&nbsp;</strong>({recetaElectronica.registro1?.drogas?.join(', ')}){' '}
              {recetaElectronica?.registro1?.presentacion}, &nbsp;Cie10:&nbsp;
              {recetaElectronica?.registro1?.cie10Descripcion}
              {recetaElectronica.registro2 && (
                <>
                  {' - '}
                  <strong>&nbsp;Rp2:&nbsp;</strong> (
                  {recetaElectronica.registro2?.drogas?.join(', ')})
                </>
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
