import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import QrPreview from '../Common/QrPreview';
import DetalleCoberturaOrdenData from './components/DetalleCoberturaOrdenData';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';
import { enviarOrden } from '../../services/Seo/Orden';
import PracticasSinCupo from './components/PracticasSinCupo';

const OrdenPreview = ({ showSuccessIcon = false, ...props }) => {
  const { handlerCerrarPreview } = props;
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');
  const [practicasSinCupo, setPracticasSinCupo] = useState([]);

  const handleClose = () => {
    props.setOpen(false);
    handlerCerrarPreview();
  };

  const handlerEnviarCorreo = async (correo) => {
    setLoadingEnviar(true);
    setSuccessCorreo('');
    try {
      const res = await enviarOrden({
        numeroOrden: props.datosOrden.orden.numeroOrdenSinFormato,
        correo: correo,
      });

      setSuccessCorreo('Orden enviada al correo: ' + correo);
      setLoadingEnviar(false);
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  useEffect(() => {
    if (props?.datosOrden != undefined && props?.datosOrden != '') {
      setPracticasSinCupo(
        props?.datosOrden?.practicas
          .filter(
            (p) =>
              p.cuposDisponible != null &&
              Object.values(p.cuposDisponible).filter((c) => c == false).length > 0,
          )
          .map((p) => p.codigoPractica),
      );
    }
  }, [props?.datosOrden]);

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='felx-end'
            spacing={1}
          >
            <Grid item>
              {' '}
              <Typography variant='h6'>Orden Generada</Typography>
            </Grid>
            <Grid item>
              {' '}
              <CheckCircleIcon fontSize='small' color='primary'></CheckCircleIcon>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {props.datosOrden.orden && (
                <QrPreview
                  showDownload={props.showDownload}
                  showPrint={props.showPrint}
                  urlReporte={props.urlReporte}
                  showMail={props.showMail}
                  correo={props.datosOrden.afiliado.correo}
                  loadingEnviar={loadingEnviar}
                  successCorreo={successCorreo}
                  onEnviarCorreo={handlerEnviarCorreo}
                  nombreReporte={'Orden_iosper_' + props.datosOrden.orden.numeroOrden}
                  qrValue={JSON.stringify({
                    codigo: props.datosOrden.orden.numeroOrdenFormatoCodigo,
                  })}
                  showSuccessIcon={showSuccessIcon}
                />
              )}
              {props.datosOrden.orden && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='numero-orden'
                    variant='standard'
                    label='Nro. de Orden'
                    defaultValue={props.datosOrden.orden.numeroOrden}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              )}
              {props.datosOrden.afiliado && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='afiliado'
                    label='Afiliado'
                    variant='standard'
                    defaultValue={`(${props.datosOrden.afiliado.numerodocumentoformateado}) ${props.datosOrden.afiliado.nombre}, ${props.datosOrden.afiliado.apellido}`}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              )}
              {props.datosOrden.prestador && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='prestador'
                    label='Prestador'
                    variant='standard'
                    defaultValue={`(${props.datosOrden.prestador.codigoPrestador}) ${
                      props.datosOrden.prestador.razonSocial
                    } ${
                      props.datosOrden.prestador?.especialidades
                        ? `(${props.datosOrden.prestador?.especialidades
                            ?.map((espe) => espe.descripcion)
                            .join(', ')} ) `
                        : ''
                    } `}
                    size='small'
                    multiline
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              )}
              {props.datosOrden.prescriptor?.idPrestador != null && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='prescriptor'
                    label='Prescriptor'
                    variant='standard'
                    defaultValue={`(${props.datosOrden.prescriptor.codigoPrestador}) ${
                      props.datosOrden.prescriptor.razonSocial
                    } ${
                      props.datosOrden.prescriptor?.especialidades
                        ? `(${props.datosOrden.prescriptor?.especialidades
                            ?.map((espe) => espe.descripcion)
                            .join(', ')} ) `
                        : ''
                    } `}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              )}
              {props.datosOrden.arancelTotal && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='coseguro'
                    label='Coseguro a pagar al prestador'
                    variant='standard'
                    defaultValue={`$ ${props.datosOrden.arancelTotal.totalNumero}`}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              )}
              {props.datosOrden.practicas && (
                <DetalleCoberturaOrdenData
                  detallePracticas={props.datosOrden.practicas}
                ></DetalleCoberturaOrdenData>
              )}
              {practicasSinCupo?.length > 0 && (
                <PracticasSinCupo practicasSinCupo={practicasSinCupo}></PracticasSinCupo>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            size='small'
            startIcon={<HighlightOff />}
            variant='contained'
            color='primary'
            onClick={(e) => handleClose(e)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenPreview;
