import { useState } from 'react';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordTextInput = ({
  fieldName,
  label,
  register,
  errors,
  required,
  variant,
  maxLength,
  minLength,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <FormControl variant={variant} required={required} fullWidth>
        <InputLabel htmlFor={`${label}`}>{label}</InputLabel>
        <Input
          id={fieldName}
          type={showPassword ? 'text' : 'password'}
          name={fieldName}
          {...register(fieldName, {
            required: {
              value: true,
              message: 'Debe ingresar ' + label,
            },
            maxLength: {
              value: maxLength,
              message: label + ' debe tener como máximo ' + maxLength + ' caracteres.',
            },
            minLength: {
              value: minLength,
              message: label + ' debe tener como mínimo ' + minLength + ' caracteres.',
            },
            pattern: {
              value: /^[0-9a-zA-Z]+$/,
              message: label + ' debe contener solo letras y al menos un número',
            },
          })}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {errors[fieldName] && <p style={{ color: 'red' }}>{errors[fieldName].message}</p>}
    </div>
  );
};

PasswordTextInput.defaultProps = {
  maxLength: 25,
  minLength: 6,
  required: true,
  variant: 'standard',
};

export default PasswordTextInput;
