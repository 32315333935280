import { Box, Grid, InputLabel, FormControl, Input } from '@mui/material';
import React, { useState } from 'react';
import PracticasPorRolAutocomplete from '../../Practicas/PracticasPorRolAutocomplete';

const PracticaOrden = React.forwardRef((props, ref) => {
  const VARIAS_AMB = '99';
  const [practicaSelected, setPracticaSelected] = useState(props.practica || null);
  const { register, errors, indice = 0 } = props;

  const namePracticaRegister = `practicaRegister${indice}`;
  const cantidadPracticaRegister = `cantidadPracticaRegister${indice}`;

  React.useEffect(() => {
    props.onPracticaChanged(practicaSelected);
  }, [practicaSelected]);

  React.useEffect(() => {
    setPracticaSelected(props.practica);
  }, [props.practica]);

  const onChangeCantidadPractica = (e) => {
    if (practicaSelected) {
      setPracticaSelected({ ...practicaSelected, cantidad: e.target.value });
    }
  };

  const handlerSelectPractica = (value) => {
    setPracticaSelected({ practica: value, cantidad: practicaSelected?.cantidad || '1' });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3} alignItems='flex-end'>
        <Grid item sm={10} xs={12}>
          <FormControl fullWidth variant='standard'>
            <PracticasPorRolAutocomplete
              size='small'
              name={namePracticaRegister}
              {...register(namePracticaRegister, {
                validate: () => practicaSelected?.practica || false,
              })}
              practica={practicaSelected?.practica}
              onSelectPractica={handlerSelectPractica}
              codigoSubtipoPractica={VARIAS_AMB}
            />
          </FormControl>
          {errors[namePracticaRegister] && (
            <p style={{ color: 'red' }}>Debe ingresar la práctica</p>
          )}
        </Grid>

        <Grid item sm xs={12}>
          <FormControl fullWidth variant='standard'>
            <InputLabel htmlFor='cantidad-practica'>Cantidad</InputLabel>
            <Input
              id='cantidad-practica'
              {...register(cantidadPracticaRegister, {
                validate: () => (practicaSelected?.cantidad > 0 ? true : false),
              })}
              aria-describedby='Cantidad de unidades del practica seleccionado.'
              type='number'
              defaultValue={practicaSelected?.cantidad || 1}
              name='cantidad'
              onChange={onChangeCantidadPractica}
              inputProps={{
                step: 1,
                minLength: 0,
                maxLength: 2,
                min: 1,
                max: 99,
                type: 'number',
              }}
            />
            {errors[cantidadPracticaRegister] != null && (
              <p style={{ color: 'red' }}>Debe ingresar cantidad </p>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PracticaOrden;
