import React from 'react';
import './App.css';
import './services/Axios/Config';
import { Box } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { UserProvider } from './components/Auth/User';
import VersionControlProvider from './@iosper/components/VersionControl/store/VersionControlProvider';
import CssBaseline from '@mui/material/CssBaseline';
import InterceptorProvider from './services/Axios/store/InterceptorProvider';
import Layouts from './layouts';
import { Helmet } from 'react-helmet';

const App = () => {
  return (
    <>
      <Helmet>
        {process.env.REACT_APP_STAGE !== 'production' && (
          <meta name='robots' content='noindex, nofollow' />
        )}
      </Helmet>
      <UserProvider>
        <InterceptorProvider>
          <VersionControlProvider>
            <SnackbarProvider>
              <Box>
                <BrowserRouter>
                  <CssBaseline />
                  <Layouts />
                </BrowserRouter>
              </Box>
            </SnackbarProvider>
          </VersionControlProvider>
        </InterceptorProvider>
      </UserProvider>
    </>
  );
};
export default App;
