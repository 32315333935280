import { Box, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

const DateSelector = React.forwardRef(({ onSelectedFecha, type, label, useCurrentDate }, ref) => {
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    if (useCurrentDate) {
      const currentDate = new dayjs();
      setValue(currentDate);
      onSelectedFecha(currentDate.format('YYYY-MM-DD'));
    }
  }, [useCurrentDate]);

  const handleChange = (newValue) => {
    if (newValue) {
      setValue(newValue);
      onSelectedFecha(newValue.format('YYYY-MM-DD'));
    }
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
        <DatePicker
          label={label}
          defaultValue={value || dayjs()}
          views={type}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} helperText={null} variant='standard' fullWidth />
          )}
          slotProps={{
            textField: {
              variant: 'standard',
              fullWidth: true,
            },
          }}
          minDate={dayjs('2022-01-01 00:00')}
          maxDate={dayjs()}
        />
      </LocalizationProvider>
    </div>
  );
});
export default DateSelector;
