import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Grid,
  Box,
} from '@mui/material';

import { generarCodigoConfirmacion } from '../../../services/Common/Contacto';
import LoadingButton from '../../../@iosper/components/LoadingButton';
import ModalBase from '../../Controls/ModalBase';

const SeleccionarContacto = React.forwardRef((props, ref) => {
  const { open, setOpen, contactos, onAgregarContacto, onCodigoEnviado, onClose } = props;
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const emails =
    contactos != null
      ? contactos.map((item, key) => {
          return (
            <FormControlLabel
              key={`contacto-${key}`}
              value={item.idHistorialContactoPersonaJuridica}
              control={<Radio />}
              label={item.descripcion}
            />
          );
        })
      : null;

  const addCorreoHandler = async (e) => {
    e.preventDefault();
    onAgregarContacto();
  };

  const enviarCodigoHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // genero codigo y abrir la pantalla de confirmacion
      await generarCodigoConfirmacion(value);
      setLoading(false);
      onCodigoEnviado(value);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue(parseInt(e.target.value));
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (contactos !== null) {
      setValue(contactos[0].idHistorialContactoPersonaJuridica);
    }
  }, [contactos]);

  const form = (
    <div>
      <Grid container spacing={3} direction='column' justifyContent='left' alignItems='left'>
        <Grid item>
          <Typography align='center' variant='body1'>
            Necesitamos que confirme su cuenta de correo electrónico registrado, para que constituya
            su domicilio electrónico comercial o personal
          </Typography>
        </Grid>
        <Grid item>
          <Typography align='center' variant='body2'>
            Verifique el correo seleccionado al que enviaremos el código de confirmación:
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={3}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item>
              <FormControl component='fieldset'>
                <RadioGroup
                  size='small'
                  color='primary'
                  name='controlled-radio-buttons-group'
                  value={value}
                  onChange={handleChange}
                >
                  {emails}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent='flex-end' spacing={1}>
        <Grid item>
          <LoadingButton
            fullWidth={true}
            variant='contained'
            color='primary'
            disabled={false}
            loading={loading ? 'show' : 'hide'}
            content={'Enviar'}
            onClick={(e) => enviarCodigoHandler(e)}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Confirmar domicilio electrónico'
        onClose={handleClose}
      />
    </Box>
  );
});

export default SeleccionarContacto;
