import { useTheme } from '@emotion/react';
import { Keyboard } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { ReactComponent as WarningImg } from '../../../assets/images/svg/mobile-warning.svg';
import ReplayIcon from '@mui/icons-material/Replay';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { QrCodeScanner } from '@mui/icons-material';

const NumeroOrdenQRScan = ({
  register,
  errors,
  change,
  onAyudaActivada,
  onScanActivo,
  tituloAyuda,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = matches ? false : true;
  const [qrNoValido, setQrNoValido] = useState(false);
  const [showQrScanner, onShowQrScanner] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [numeroOrden, setNumeroOrden] = useState('');
  const handleOnQRResult = (result, e) => {
    try {
      if (result) {
        const js = JSON.parse(result);
        let prefijo = js.codigo.substr(0, 4);
        let parseNumeroOrden = parseInt(js.codigo.substr(4));
        if (prefijo !== '0001') {
          setQrNoValido(true);
          onAyudaActivada(true);
        }
        setNumeroOrden(parseNumeroOrden);
        change(parseNumeroOrden);
        onShowQrScanner(false);
        onScanActivo(false);
      }
    } catch (e) {
      console.log(e);
      setQrNoValido(true);
      onShowQrScanner(false);
      onAyudaActivada(true);
      onScanActivo(false);
    }
  };

  const handleHelp = () => {
    setShowHelp(true);
    onAyudaActivada(true);
  };

  const closeQrScanner = () => {
    onShowQrScanner(false);
    onScanActivo(false);
  };

  const handleReplyFromQrNoValido = () => {
    setQrNoValido(false);
    onAyudaActivada(false);
  };

  const handleReplyFromHelp = () => {
    setShowHelp(false);
    onAyudaActivada(false);
  };

  const DrawerQr = (
    <Paper sx={{ p: 2 }}>
      <Typography variant='string' component={'div'} align='center' sx={{ pb: 3 }}>
        Escanea el código QR de la orden
      </Typography>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={handleOnQRResult}
        style={{ width: '100%' }}
        scanDelay={1000}
      />
      <Box sx={{ p: 2 }}>
        <Button
          size='small'
          variant='text'
          color='primary'
          fullWidth={true}
          onClick={(e) => handleHelp(e)}
        >
          {' '}
          ¿Necesitás ayuda?{' '}
        </Button>
        <Button
          size='small'
          startIcon={<Keyboard />}
          variant='contained'
          color='neutral'
          fullWidth={true}
          onClick={(e) => closeQrScanner(e)}
        >
          Ingresar por teclado
        </Button>
      </Box>
    </Paper>
  );

  const contentQrNoValido = (
    <Grid container spacing={3}>
      <Grid item>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid xs={2} item>
            <WarningImg />
          </Grid>
          <Grid xs={10} item>
            <Typography align='center' variant='body2' color='secondary'>
              Oops! No pudimos escanear correctamente el código QR
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='body2'>Verificá que tu cámara esté alineada.</Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          Verificá que la cámara de tu dispositivo esté limpia.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          Verificá que el reflejo de las luces no genere ruidos en la foto.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          size='small'
          startIcon={<ReplayIcon />}
          variant='contained'
          color='primary'
          fullWidth={true}
          onClick={(e) => handleReplyFromQrNoValido(e)}
        >
          Reintentar
        </Button>
      </Grid>
    </Grid>
  );

  const numeroOrdenDrawer = (
    <CardContent sx={{ justifyContent: 'flex-end', p: 0 }}>
      <FormControl variant='standard' fullWidth>
        <TextField
          {...register('numeroorden', {
            required: {
              value: true,
              message: 'El número de orden es requerido',
            },
            maxLength: {
              value: 10,
              message: 'El número de orden es demasiado largo',
            },
            pattern: {
              value: /^[0-9]+$/,
              message: 'El campo debe contener sólo números',
            },
            onChange: (e) => {
              change(e.target.value);
              setNumeroOrden(e.target.value);
            },
          })}
          variant='standard'
          fullWidth
          name='numeroorden'
          label='Ingresá el número de orden'
          id='numeroorden'
          value={numeroOrden}
          onChange={(newValue) => {
            setNumeroOrden(newValue.target.value);
            change(newValue.target.value);
          }}
        />
        {errors.numeroorden && <p style={{ color: 'red' }}>{errors.numeroorden.message}</p>}
        {esMobile && (
          <Button
            size='small'
            startIcon={<QrCodeScanner />}
            variant='contained'
            color='neutral'
            onClick={(e) => {
              onShowQrScanner(true);
              onScanActivo(true);
            }}
          >
            {' '}
            Leer Qr
          </Button>
        )}
      </FormControl>
    </CardContent>
  );
  const contentHelp = (
    <Grid container spacing={3} justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Grid container direction='row' spacing={1} justifyContent='left'>
          <Grid item>
            <HelpOutlineIcon color='primary' />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>{tituloAyuda}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='body2'>Apuntá la cámara hacia el código QR en la orden.</Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          Una vez leído correctamente el código, seguí las intrucciones en pantalla para completar
          el proceso.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          size='small'
          startIcon={<ReplayIcon />}
          variant='contained'
          color='primary'
          fullWidth={true}
          onClick={(e) => handleReplyFromHelp(e)}
        >
          Reintentar
        </Button>
      </Grid>
    </Grid>
  );

  return qrNoValido
    ? contentQrNoValido
    : showHelp
    ? contentHelp
    : showQrScanner
    ? DrawerQr
    : numeroOrdenDrawer;
};

export default NumeroOrdenQRScan;
