import React from 'react';
import { AccountCircle, MoreVert } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useUser } from '../../components/Auth/User';

const TopMenu = ({ list }) => {
  const listMenu = list;
  const { user } = useUser();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  if (!listMenu) {
    return;
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderTopList = listMenu.map((item) => {
    return (
      <MenuItem key={item.label} component={Link} to={item.to} onClick={handleMenuClose}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        {item.label}
      </MenuItem>
    );
  });

  return (
    <Box>
      <IconButton
        id='top-menu'
        size='large'
        edge='end'
        aria-label='Menú del usuario.'
        aria-controls={menuId}
        aria-haspopup='true'
        onClick={handleProfileMenuOpen}
        color='inherit'
      >
        <AccountCircle sx={{ display: { xs: 'none', md: 'flex' } }} />
        <MoreVert sx={{ display: { xs: 'flex', md: 'none' } }} />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <Grid
          aria-label='Usuario actual:'
          aria-haspopup='true'
          container
          flexDirection={'column'}
          flexWrap={'wrap'}
          alignItems={'center'}
          sx={{
            outline: 0,
            minHeight: 'auto',
            margin: 0,
            marginBottom: '16px',
            padding: 0,
          }}
        >
          <Grid item sx={{ padding: '6px 16px' }}>
            <Avatar sx={{ width: 50, height: 50 }} />
          </Grid>
          <Grid item sx={{ padding: '6px 16px' }}>
            <Typography variant='inherit' noWrap>
              {user?.razonsocial || 'Cuenta'}
            </Typography>
          </Grid>
        </Grid>
        {/* <MenuItem component={Link} to='/perfil' key='perfil'>
          <ListItemIcon
            size='large'
            aria-label='Usuario actual:'
            aria-haspopup='true'
            color='inherit'
          >
            <AccountCircle />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>{user?.razonsocial || 'Cuenta'}</Typography>
        </MenuItem> */}
        <Divider />
        {renderTopList}
      </Menu>
    </Box>
  );
};

export default TopMenu;
