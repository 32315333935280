import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const options = [{ cantidad: 1 }, { cantidad: 2 }, { cantidad: 3 }];

const CantidadRecetasCombo = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [cantidadRecetasLabel, setCantidadRecetasLabel] = React.useState('Cant. recetas');

  React.useEffect(() => {
    setDisabled(props.disabled);
    //if (props.disabled) {
    setValue(options[0]);
    props.onSelectCantidadRecetas(options[0]);
    //}
  }, [props.disabled]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onSelectCantidadRecetas(newValue);
    setCantidadRecetasLabel('Cant. recetas');
  };

  return (
    <Autocomplete
      id='cantidad-recetas-autocomplete'
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.cantidad === value.cantidad}
      getOptionLabel={(a) => `${a.cantidad}`}
      onChange={handleChange}
      value={value || setValue(options[0])}
      options={options}
      loading={options && options.length === 0}
      renderInput={(params) => (
        <TextField
          id='textCantidadRecetas'
          {...params}
          label={cantidadRecetasLabel}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options && options.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default CantidadRecetasCombo;
