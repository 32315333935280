import React from 'react';
import { Collapse, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const MenuListDrawer = ({ list = [], onMobileOpen = null }) => {
  const [open, setOpen] = React.useState({});

  const handleClick = (itemIndex) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [itemIndex]: !prevOpen[itemIndex],
    }));
  };

  const onHandleEscapeSubMenu = (e, index) => {
    if (e?.key === 'Escape') {
      handleClick(index);
      let ele = document.getElementById(`accordion-${index}`);
      ele.focus();
    }
  };

  return (
    <>
      {list.length > 0 &&
        list.map((item, index) => (
          <React.Fragment key={item.label}>
            {!item?.submenu && (
              <MenuItem
                key={item.label}
                component={Link}
                to={item.to}
                sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                onClick={(e) => onMobileOpen(false)}
              >
                <ListItemIcon sx={{ color: '#B12823' }}>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            )}
            {item?.submenu && (
              <>
                <MenuItem
                  key={item.label}
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                  onClick={() => handleClick(index)}
                  aria-controls={open ? `submenu-${index}` : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  id={`accordion-${index}`}
                  tabIndex={index}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                  {open[index] ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>

                <Collapse
                  id={`submenu-${index}`}
                  role='region'
                  aria-labelledby={`accordion-${index}`}
                  in={open[index]}
                  timeout='auto'
                  unmountOnExit
                >
                  {item?.submenu?.map((subItem, subIndex) => (
                    <MenuItem
                      key={subItem.label}
                      component={Link}
                      to={subItem.to}
                      sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 3 }}
                      onClick={(e) => onMobileOpen(false)}
                      onKeyDown={(e) => {
                        onHandleEscapeSubMenu(e, index);
                      }}
                      tabIndex={index + 1}
                    >
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={subItem.label} />
                    </MenuItem>
                  ))}
                </Collapse>
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
};
