import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Hidden,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import { useForm } from 'react-hook-form';
import { Delete, HighlightOff, Search } from '@mui/icons-material';
import CustomizedSnackbar from '../Notifications/SnackBar';
import { findByOrdenAfiliado } from '../../services/Seo/Orden';
import AnularOrdenImpresaPopUp from './AnularOrdenImpresaPopUp';
import NumeroOrdenQRScan from './components/NumeroOrdenQRScan';
import DrawerNumeroOrden from './components/DrawerNumeroOrden';
import { LoadingButton } from '@mui/lab';

const AnularOrdenImpresa = (props) => {
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [numeroOrden, setNumeroOrden] = useState('');
  const [loadingBuscar, setLoadingBuscar] = useState(false);
  const [datosOrden, setDatosOrden] = useState(null);
  const [success, setSuccess] = useState('');
  const [loadingAnular, setLoadingAnular] = useState(false);
  const [onOpenAnular, setOpenAnular] = useState(null);
  const [ayudaActivada, setAyudaActivada] = useState(false);
  const [scanActivo, setScanActivo] = useState(false);
  const [keyComponentNumeroOrden, setKeyComponentNumeroOrden] = useState(0);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'onBlur' });

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handlerLimpiar = () => {
    reset();
    setLoadingBuscar(false);
    setLoadingAnular(false);
    setSelectedAfiliado(null);
    setNumeroOrden('');
    setDatosOrden(null);
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    setKeyComponentNumeroOrden(1 + Math.random() * (1000 - 1));
  };

  const handlerAyudaActivada = (value) => {
    setAyudaActivada(value);
  };

  const handlerScanActivo = (value) => {
    setScanActivo(value);
  };

  const handlerChangeNumeroOrden = (value) => {
    setNumeroOrden(value);
    setValue('numeroorden', value);
  };

  const getDataOrden = () => {
    let data = {
      numeroAfiliado: selectedAfiliado?.numeroAfiliado,
      numeroOrden: parseInt(numeroOrden),
    };
    return data;
  };

  const onBuscar = async () => {
    try {
      const res = await findByOrdenAfiliado(getDataOrden());
      setDatosOrden(res);
    } catch (e) {
      setLoadingBuscar(false);
      console.log(e);
    }
  };

  const onSubmitBuscar = async (data, event) => {
    event.preventDefault();
    setLoadingBuscar(true);
    onBuscar();
  };

  const onSubmitAnular = async (data, event) => {
    event.preventDefault();
    setOpenAnular(true);
    setLoadingAnular(true);
  };

  const handleAnulada = async (anulada) => {
    setSuccess('La orden ha sido anulada correctamente');
    setOpenAnular(false);
    handlerLimpiar();
    if (anulada) {
      props.onRefresh();
    }
  };

  const DrawerForm = (
    <form onSubmit={handleSubmit(onSubmitBuscar)}>
      <CardContent sx={{ p: 2 }}>
        <Hidden xsUp={scanActivo || ayudaActivada} xsDown={scanActivo || ayudaActivada}>
          <FormControl fullWidth={true} variant='outlined' margin='normal'>
            <BuscarAfiliado
              key={keyComponentAfiliado + '__afiliado'}
              onSelectAfiliado={(afiliado) => setSelectedAfiliado(afiliado)}
              ref={register('afiliadoRegister', {
                validate: validateAfiliado,
              })}
            />
            {errors.afiliadoRegister != null && (
              <p style={{ color: 'red' }}>Debe ingresar el afiliado</p>
            )}
          </FormControl>
        </Hidden>
        <NumeroOrdenQRScan
          key={keyComponentNumeroOrden + '__numeroorden'}
          register={register}
          errors={errors}
          change={handlerChangeNumeroOrden}
          onAyudaActivada={handlerAyudaActivada}
          onScanActivo={handlerScanActivo}
          tituloAyuda='¿Querés anular la atención de una orden?'
        />
      </CardContent>
      <Hidden xsUp={scanActivo || ayudaActivada} xsDown={scanActivo || ayudaActivada}>
        <CardActions sx={{ px: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                }}
              >
                <Button
                  variant='contained'
                  startIcon={<HighlightOff />}
                  color='neutral'
                  onClick={handlerLimpiar}
                >
                  Limpiar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  startIcon={<Search />}
                  type='submit'
                  loading={loadingBuscar}
                >
                  Buscar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Hidden>
    </form>
  );

  const DrawerAnular = (
    <form onSubmit={handleSubmit(onSubmitAnular)}>
      <CardContent sx={{ justifyContent: 'flex-end', p: 2 }}>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <DrawerNumeroOrden
            codigoTipoOrden={datosOrden?.codigoTipoOrden}
            numeroOrden={datosOrden?.numeroOrden}
            tipoOrden={datosOrden?.tipoOrden}
          ></DrawerNumeroOrden>
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='order-fechageneracion'
            variant='standard'
            label='Estado'
            defaultValue={datosOrden?.estadoOrden}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='order-fechageneracion'
            variant='standard'
            label='Fecha de Emisión'
            defaultValue={datosOrden?.fechaEmision}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='afiliado-nya'
            variant='standard'
            label='Afiliado'
            defaultValue={`(${datosOrden?.nroDocumento}) ${datosOrden?.nombre}, ${datosOrden?.apellido}`}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='prestador-nya'
            label='Prestador'
            variant='standard'
            defaultValue={`(${datosOrden?.matriculaPrestador}) ${datosOrden?.nombrePrestador} `}
            size='small'
            multiline
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='coseguro-total'
            label='Coseguro Total'
            variant='standard'
            defaultValue={`$ ${datosOrden?.totalCoseguro} `}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </CardContent>
      <CardActions sx={{ px: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
              }}
            >
              <Button
                variant='contained'
                startIcon={<HighlightOff />}
                color='neutral'
                onClick={handlerLimpiar}
              >
                Cancelar
              </Button>
              <LoadingButton
                variant='contained'
                size='medium'
                color='primary'
                startIcon={<Delete />}
                type='submit'
                loading={loadingAnular}
              >
                Anular
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 0 }}>
          <Hidden xsUp={ayudaActivada} xsDown={ayudaActivada}>
            <CardContent>
              <Typography gutterBottom variant='h5'>
                Anular Orden Impresa
              </Typography>
              <Typography gutterBottom>
                Acá podrás anular órdenes impresas de consulta y/o de prácticas que no tengan
                registro de atención en la aplicación.
              </Typography>
            </CardContent>
          </Hidden>

          {!datosOrden && DrawerForm}

          {datosOrden && DrawerAnular}

          {onOpenAnular ? (
            <AnularOrdenImpresaPopUp
              open={onOpenAnular}
              orden={datosOrden}
              setOrdenAnulada={handleAnulada}
              onCancelar={() => setOpenAnular(false)}
            />
          ) : null}
        </Card>

        <CustomizedSnackbar
          open={success}
          autoHideDuration={10000}
          severity='success'
          message={success}
        />
      </Grid>
    </Grid>
  );
};

export default AnularOrdenImpresa;
