import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
} from '@mui/material';

import { generarCodigoConfirmacion, confirmarContacto } from '../../services/Common/Contacto';
import ModalBase from '../Controls/ModalBase';
import { useForm } from 'react-hook-form';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CountDown from '../Common/CountDown';

const ConfirmarContacto = React.forwardRef((props, ref) => {
  const { open, contactos, onAgregarContacto, onContactoConfirmado, onClose } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [caducado, setCaducado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReenviar, setLoadingReenviar] = useState(false);
  const [codigoIngresado, setCodigoIngresado] = useState(false);

  const onCaducado = () => {
    setCaducado(true);
  };

  const clean = async () => {
    setCaducado(false);
    reset();
  };

  const addCorreoHandler = async (e) => {
    e.preventDefault();
    await clean();
    onAgregarContacto();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await confirmarContacto(contactos[0].idHistorialContactoPersonaJuridica, codigoIngresado);

      setLoading(false);
      onContactoConfirmado();
    } catch (e) {
      setLoading(false);
    }
  };

  const reenviarCodigoHandler = async (e) => {
    e.preventDefault();
    setLoadingReenviar(true);

    await generarCodigoConfirmacion(contactos[0].idHistorialContactoPersonaJuridica);

    setLoadingReenviar(false);
    clean();
  };

  const handleClose = () => {
    clean();
    onClose();
  };

  const form = (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} direction='column' justifyContent='left' alignItems='left'>
          <Grid item xs={12} zeroMinWidth>
            <Typography align='left' variant='h6'>
              Ingrese el código de confirmación que enviamos a:
            </Typography>
            {contactos && (
              <List>
                {contactos.map((item, key) => (
                  <ListItem key={`email-account-${key}`}>
                    <ListItemIcon>&bull;</ListItemIcon>
                    <ListItemText primary={item.descripcion} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <Typography align='left' variant='body2'>
              ¿No recibió ningún correo?
              <br />
              Recuerde revisar en el correo no deseado.
            </Typography>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <TextField
              label='¿Cúal es el código?'
              variant='standard'
              fullWidth
              name='codigo'
              focused
              error={caducado}
              helperText={caducado && 'El código ha caducado'}
              {...register('codigo', {
                required: {
                  value: true,
                  message: 'Debe ingresar un código',
                },
              })}
              onChange={(e) => setCodigoIngresado(e.target.value)}
            />
            {errors.codigo && <p style={{ color: 'red' }}>{errors.codigo.message}</p>}
            <Grid item xs={12}>
              <Stack direction='row' spacing={1} justifyContent='left' alignItems='center'>
                {!caducado && <Typography variant='caption'> El código caducará en</Typography>}
                {!caducado && <CountDown id='count' minutes={5} onCaducado={onCaducado} />}
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
              justifyContent={{ xs: 'center', sm: 'flex-end' }}
              alignItems='center'
            >
              <LoadingButton
                variant='text'
                color='primary'
                loading={loadingReenviar ? 'show' : 'hide'}
                content={'Reenviar código'}
                disabled={!caducado}
                onClick={(e) => reenviarCodigoHandler(e)}
              />

              <LoadingButton
                fullWidth={true}
                variant='contained'
                color='primary'
                loading={loading ? 'show' : 'hide'}
                content={'Confirmar'}
                type='submit'
                disabled={caducado}
              />
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        content={form}
        title='Confirmar domicilio electrónico'
        onClose={handleClose}
      />
    </Box>
  );
});

export default ConfirmarContacto;
