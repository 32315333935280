import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';

const DocumentoPreview = () => {
  let { state } = useLocation();

  return (
    <Grid container spacing={1}>
      <ButtonBack />
      <Grid item xs={12}>
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <iframe
              title='Preview'
              src={state?.url}
              width={'100%'}
              height={500}
              loading='lazy'
              allowFullScreen={true}
              style={{
                border: 'none',
                height: 'calc(100vh - 200px)',
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DocumentoPreview;
