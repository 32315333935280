import { FormControl, Grid, Input, InputLabel } from '@mui/material';
import { forwardRef, useState } from 'react';
import { IMaskInput } from 'react-imask';

const TextMaskNroArea = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='(000[0])'
      lazy={false}
      inputRef={ref}
      onAccept={(value, { _unmaskedValue }) =>
        onChange({ target: { name: props.name, value, unmaskedValue: _unmaskedValue } })
      }
      overwrite
    />
  );
});

const TextMaskNroTelefono = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000000[0]'
      lazy={false}
      inputRef={ref}
      onAccept={(value, { _unmaskedValue }) =>
        onChange({ target: { name: props.name, value, unmaskedValue: _unmaskedValue } })
      }
      overwrite
    />
  );
});

export const Telefono = ({
  nroArea = '',
  nroTelefono = '',
  nroTelCompleto = '',
  onChangeEvent = null,
}) => {
  const [nroAreaSplit, nroTelefonoSplit] = nroTelCompleto?.split('-') || null;
  const [nroAreaValue, setNroAreaValue] = useState(nroAreaSplit || nroArea || '');
  const [nroTelefonoValue, setNroTelefonoValue] = useState(nroTelefonoSplit || nroTelefono || '');

  const handleChangeArea = ({ target }) => {
    setNroAreaValue(target.unmaskedValue);
    if (onChangeEvent) {
      onChangeEvent({
        target: {
          name: 'telefono',
          nroArea: target.unmaskedValue,
          nroTelefono: nroTelefonoValue,
          unmaskedValue: `${target.unmaskedValue}-${nroTelefonoValue}`,
        },
      });
    }
  };
  const handleChangeTelefono = ({ target }) => {
    setNroTelefonoValue(target.unmaskedValue);
    if (onChangeEvent) {
      onChangeEvent({
        target: {
          name: 'telefono',
          nroArea: nroAreaValue,
          nroTelefono: target.unmaskedValue,
          unmaskedValue: `${nroAreaValue}-${target.unmaskedValue}`,
        },
      });
    }
  };

  return (
    <Grid container gap={1} flexDirection={'row'} flexWrap={'nowrap'}>
      <Grid item xs={4}>
        <FormControl fullWidth variant='standard'>
          <InputLabel htmlFor='cod-area-input' shrink={true}>
            Cód. área
          </InputLabel>
          <Input
            id='cod-area-input'
            name='codigoMask'
            variant='standard'
            value={nroAreaValue}
            onChange={handleChangeArea}
            inputComponent={TextMaskNroArea}
            fullWidth
          />
        </FormControl>
      </Grid>

      <Grid item xs={8}>
        <FormControl fullWidth variant='standard'>
          <InputLabel htmlFor='cod-tel-input' shrink={true}>
            Teléfono
          </InputLabel>
          <Input
            id='cod-tel-input'
            name='telefonoMask'
            variant='standard'
            value={nroTelefonoValue}
            onChange={handleChangeTelefono}
            inputComponent={TextMaskNroTelefono}
            fullWidth
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
