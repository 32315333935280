import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getTiposTratamientos } from '../../services/Busquedas/TiposTratamientos';

const TipoTratamientoCombo = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const [tipoTratamientoLabel, setTipoTratamientoLabel] = React.useState('Tipo de tratamiento');

  React.useEffect(() => {
    const getTipoTratamiento = async () => {
      const tiposTratamientos = await getTiposTratamientos();
      setOptions(tiposTratamientos);
    };

    try {
      if (options && options.length === 0) {
        getTipoTratamiento();
      }
    } catch (err) {
      console.error(err);
    }

    setDisabled(props.disabled);
  }, [options, props.disabled]);

  React.useEffect(() => {
    setValueTipoTratamiento(props.value);
  }, [props.value]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValueTipoTratamiento(newValue);
  };

  const setValueTipoTratamiento = (value) => {
    setValue(value);
    props.onSelectTipoTratamiento(value);
    setTipoTratamientoLabel('Tipo Tratamiento');
  };

  return (
    <Autocomplete
      id='tipo-tratamiento-autocomplete'
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.idTipoTratamiento === value.idTipoTratamiento}
      getOptionLabel={(a) => `${a.descripcion}`}
      onChange={handleChange}
      value={value}
      options={options}
      loading={options && options.length === 0}
      renderInput={(params) => (
        <TextField
          id='textTipoTratamiento'
          {...params}
          label={tipoTratamientoLabel}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options && options.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default TipoTratamientoCombo;
