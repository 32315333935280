import { Refresh } from '@mui/icons-material';
import HighlightOff from '@mui/icons-material/HighlightOff';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { generarTokenConsulta } from '../../services/Seo/OrdenDigital';
import CountDown from '../Common/CountDown';
import { useUser } from '../Auth/User';
import { LoadingButton } from '@mui/lab';
import backgroundQrFramework from '../../assets/images/svg/qr-framework.svg';

const OrdenDigitalQRPreview = ({
  open,
  qrValue,
  prestador,
  handlerCerrarPreview = null,
  ...props
}) => {
  const { user } = useUser();
  const [caducado, setCaducado] = useState(false);
  const [Qr, setQr] = useState(qrValue);

  useEffect(() => {
    setQr(qrValue);
  }, [qrValue]);

  const onCaducado = () => {
    setCaducado(true);
  };

  const handleClose = () => {
    handlerCerrarPreview();
  };

  const generarQr = async (matricula, tipo, user) => {
    let data = {
      codigoPrestador: matricula,
      tipoPrestador: tipo,
      nombreUsuario: user,
    };

    const res = await generarTokenConsulta(data);
    setQr(res);
  };

  const onReload = (value) => {
    generarQr(prestador.matricula, prestador.tipo, user.nombre);
    setCaducado(false);
    setQr(null);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <Stack direction='column' spacing={1} justifyContent='center' alignItems='center' gap={3}>
            {prestador && (
              <Container>
                <Typography variant='h6' gutterBottom align='center'>
                  Prestador
                </Typography>
                <Typography variant='subtitle1' gutterBottom align='center'>
                  {prestador?.nombre} ({prestador?.matricula})
                </Typography>
              </Container>
            )}

            {!Qr && <LoadingButton startIcon={<QrCodeIcon />} type='icon' loading={!Qr} />}

            {Qr && !caducado && (
              <Box
                sx={{
                  backgroundImage: `url("${backgroundQrFramework}")`,
                  width: '170px',
                  height: '170px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <QRCodeSVG value={JSON.stringify({ token: Qr })} />
              </Box>
            )}

            {Qr && caducado && (
              <Fab
                color='default'
                aria-label='add'
                sx={{ height: 200, width: 200, p: 2 }}
                onClick={onReload}
              >
                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                  <Refresh sx={{ fontSize: 60 }} />
                  <Typography variant='caption'>Haz clic para actualizar el código QR</Typography>
                </Stack>
              </Fab>
            )}

            {!caducado && (
              <Container maxWidth='sm'>
                <Typography variant='subtitle2' align='center'>
                  Esperando a que el afiliado lea el QR y genere la orden desde mi-iosper
                </Typography>

                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                  <Typography variant='caption'> El código caducará en</Typography>
                  <CountDown id='count' minutes={5} onCaducado={onCaducado} />
                </Stack>
              </Container>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            size='small'
            startIcon={<HighlightOff />}
            variant='contained'
            color='neutral'
            onClick={(e) => handleClose(e)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenDigitalQRPreview;
