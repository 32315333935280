import React from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import ModalBase from '../Controls/ModalBase';

const ContactoConfirmado = React.forwardRef((props, ref) => {
  const { open, setOpen, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const form = (
    <div>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography align='center' variant='body2' color='textSecondary'>
            Se ha confirmado su domicilio electrónico para su cuenta de usuario de IOSPER
          </Typography>
          <br />
          <Typography align='center' variant='body2' color='textSecondary'>
            A continuación podrá ingresar a mi.consultorio
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={(e) => handleClose(e)}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ModalBase
        open={open}
        setOpen={setOpen}
        content={form}
        title='Domicilio electrónico confirmado'
        onClose={handleClose}
      />
    </Box>
  );
});

export default ContactoConfirmado;
