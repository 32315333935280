import { Button } from '@mui/material';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ButtonBack = (props) => {
  const handlerGoBack = () => {
    if (props.location?.state?.datosBack !== undefined) {
      props.history.push({
        pathname: props.location.state.datosBack?.pathnameBack,
        state: { datosBack: props.location.state.datosBack } || {}, // your data array of objects
      });
    } else {
      props.history.goBack();
    }
  };

  return (
    <div>
      <Button
        variant={props.variant || 'text'}
        color='primary'
        size={props.size || 'small'}
        startIcon={<ArrowBackIcon />}
        onClick={handlerGoBack}
        sx={{ mb: Number(props.marginBotton) || 1 }}
        aria-label='Volver a la página anterior'
      >
        Atrás
      </Button>
    </div>
  );
};

export default withRouter(ButtonBack);
