import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ModalBase from '../../../components/Controls/ModalBase';
import videoPWA from '../../../assets/videos/pwaiOS.mp4';

const manualDescargarAppApple = () => {
  return (
    <Box>
      <p>Siga estos tres simples pasos para instalar la aplicación.</p>
      <ol>
        <li>
          Toca el icono <bold>Compartir</bold> del navegador de su dispositivo.
        </li>
        <li>
          Seleccione <bold>Agregar</bold> a la pantalla de inicio de las opciones.
        </li>
        <li>
          Confirme la instalación tocando el botón <bold>Agregar</bold>.
        </li>
      </ol>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: '15px' }}>
        <video
          loading='lazy'
          autoPlay
          loop
          muted
          style={{ width: '100%', maxWidth: '400px', borderRadius: '30px' }}
        >
          <source src={videoPWA} type='video/mp4' />
        </video>
      </Box>
    </Box>
  );
};

export const ButtonDownloadPWAiOS = (props) => {
  const dataUserAgent = window.navigator.userAgent;
  const dataPlatform = navigator.platform;

  const [isApple, setIsApple] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    detectarDispositivo();
  });

  const detectarDispositivo = () => {
    let isApple = /iPhone|iPad/i.test(dataUserAgent);
    let isMacOS = dataPlatform.toUpperCase().indexOf('MAC') >= 0 || /Mac/i.test(dataUserAgent);
    // let isMobile = /Mobile/.test(dataUserAgent);

    setIsApple(isApple || isMacOS);
  };

  const modalDownloadApp = (event) => {
    setOpen(true);
  };

  return (
    <>
      {isApple && (
        <>
          <Box onClick={modalDownloadApp} sx={{ cursor: 'pointer' }}>
            {props.children}
          </Box>
          <ModalBase
            open={open}
            content={manualDescargarAppApple()}
            title={
              <Box sx={{ mr: { xs: 5, sm: 10 } }}>
                ¿Cómo instalar mi.CONSULTORIO en dispositivos Apple?
              </Box>
            }
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
};
