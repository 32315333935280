import { FormControl, TextField } from '@mui/material';

const PracticasSinCupo = (props) => {
  return (
    <FormControl fullWidth={true} variant='outlined' margin='normal'>
      <TextField
        id='practicas-sin-cupo'
        variant='standard'
        label='Practicas sin cupo para la próxima orden '
        value={props?.practicasSinCupo?.toString()}
        size='small'
        fullWidth
        InputProps={{
          readOnly: true,
          style: { fontWeight: 'bold' },
        }}
        multiline
      />
    </FormControl>
  );
};

export default PracticasSinCupo;
