import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, FormControl, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '../../@iosper/components/LoadingButton';
import Pass from '../Common/PasswordTextInput';
import { getCambiarPassword } from '../../services/Sau/Usuario';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import { HighlightOff } from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';

const CambiarPassword = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data, e) => {
    setLoading(true);
    setResponse('');
    setError('');

    try {
      if (data.nuevapass === data.confirmarnuevapass) {
        let par = {
          rolafiliado: false,
          passwordactual: data.passactual,
          nuevapassword: data.nuevapass,
          confirmarnuevapassword: data.confirmarnuevapass,
        };

        await getCambiarPassword(par);
        setResponse('El cambio de Contraseña fué exitoso.');
        reset();
        setLoading(false);
      } else {
        setError('Las contraseñas ingresadas no coinciden.');
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handlerLimpiar = () => {
    reset();
  };

  const handleBorrarMensaje = () => {
    setError('');
    setResponse('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Cambiar Contraseñas
            </Typography>
          </CardContent>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <Pass
                  register={register}
                  errors={setError}
                  minLength='2'
                  label='Contraseña Actual'
                  fieldName='passactual'
                ></Pass>
                {errors['passactual'] && (
                  <p style={{ color: 'red' }}>{errors['passactual'].message}</p>
                )}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <Pass
                  register={register}
                  errors={setError}
                  label='Nueva Contraseña'
                  fieldName='nuevapass'
                ></Pass>
                {errors['nuevapass'] && (
                  <p style={{ color: 'red' }}>{errors['nuevapass'].message}</p>
                )}
              </FormControl>
              <FormControl fullWidth={true} variant='outlined' margin='normal'>
                <Pass
                  register={register}
                  errors={setError}
                  label='Confirmar Nueva Contraseña'
                  fieldName='confirmarnuevapass'
                ></Pass>
                {errors['confirmarnuevapass'] && (
                  <p style={{ color: 'red' }}>{errors['confirmarnuevapass'].message}</p>
                )}
              </FormControl>
              <Typography variant='body2' color='textSecondary' component='p' align='center'>
                La Contraseña debe tener un mínimo de 8 caracteres, 4 letras y 4 números que no
                deben ser consecutivos o iguales (ej. c4ax91k6)
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems={{ xs: 'strech' }}
              >
                <Button
                  variant='contained'
                  size='medium'
                  startIcon={<HighlightOff />}
                  color='neutral'
                  onClick={handlerLimpiar}
                >
                  Limpiar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  type='submit'
                  startIcon={<LockResetIcon />}
                  loading={loading ? 'show' : 'hide'}
                  content={'Cambiar mi contraseña'}
                />
              </Stack>
            </CardActions>
          </form>
        </Card>
      </Grid>
      <CustomizedSnackbars
        open={response}
        severity='success'
        message={response}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
});
export default CambiarPassword;
