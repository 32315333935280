import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

export const ButtonMenu = ({
  open,
  icon = '',
  iconColor = 'primary',
  text = '',
  textColor = 'primary',
  children,
  ...props
}) => {
  if (children) {
    return children;
  }

  return (
    <ListItem sx={{ minHeight: 48 }}>
      <ListItemIcon title={text} sx={{ color: iconColor }}>
        {icon}
      </ListItemIcon>
      <ListItemText>
        <Typography variant='button' gutterBottom color={textColor} fontWeight='600'>
          {text}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
