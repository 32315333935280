import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  ListItem,
  ListItemText,
  Box,
  ToggleButton,
  Collapse,
  List,
  CardContent,
  Stack,
  Menu,
  MenuItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  OpenInNew,
  PictureAsPdfOutlined,
  GetApp,
  Delete,
  ForwardToInbox,
  MoreVert,
  Medication,
} from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { useHistory } from 'react-router-dom';
import { getReporteRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import AnularRecetaElectronicaPopUp from './AnularRecetaElectronicaPopUp';
import EnviarRecetaElectronicaPopUp from './EnviarRecetaElectronicaPopUp';
import StyledDataGridRecetas from './StyledDataGridRecetas';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { findRecetasElectronicas } from '../../services/Busquedas/RecetasElectronicas';

const DataCollapseDrawer = (props) => {
  const { recetaElectronica } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescargar = async (filename, idRecetaElectronica) => {
    try {
      let idsRecetas = [];
      idsRecetas.push(idRecetaElectronica);
      const urlReporte = await getReporteRecetaElectronica({ idsRecetas: idsRecetas });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', urlReporte);
      link.setAttribute('download', 'Receta Electrónica ' + filename + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };
  const handleEnviar = (recetaElectronica) => {
    props.onEnviar(recetaElectronica);
    handleClose();
  };

  const handleAnular = (recetaElectronica) => {
    props.onAnular(recetaElectronica);
    handleClose();
  };

  const handleGenerar = (recetaElectronica) => {
    props.onGenerar(recetaElectronica);
    handleClose();
  };

  const handlePreview = (recetaElectronica) => {
    props.onPreview(recetaElectronica);
    handleClose();
  };

  return (
    <Box sx={{ width: '100%', marginLeft: -1, paddingLeft: -2, marginRight: -5, paddingRight: -5 }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0, p: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          sx={{
            marginRight: '-30px',
            marginLeft: '-20px',
            marginBottom: '-8px',
            marginTop: '-8px',
          }}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List sx={{ py: -5, px: 0, marginBottom: '0px', marginTop: '-10px' }}>
            <ListItem sx={{ py: 0, px: -2 }}>
              <ListItemText
                primary={`Nro.: ${recetaElectronica.numero}${
                  recetaElectronica.periodo ? ` (${recetaElectronica.periodo})` : ''
                } - ${recetaElectronica.afiliado || ''}`}
                secondary={
                  <>
                    <Typography
                      component='span'
                      variant='caption'
                      fontWeight='bold'
                      sx={{ textTransform: 'none' }}
                    >
                      {recetaElectronica.estado}
                    </Typography>
                    {' - '}
                    <Typography component='span' variant='caption'>
                      {recetaElectronica.fechaPrescripcion || ''}
                    </Typography>
                  </>
                }
                primaryTypographyProps={{
                  textTransform: 'none',
                  fontSize: '16px',
                }}
              />
            </ListItem>
          </List>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ justifyContent: 'flex-end', p: 0 }}>
              <ListItemButton
                aria-label='more'
                sx={{ p: 0 }}
                id='long-button'
                aria-controls={openAction ? 'long-menu' : undefined}
                aria-expanded={openAction ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVert />
              </ListItemButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 50 * 4.5,
                    width: '17ch',
                  },
                }}
              >
                <MenuItem
                  key={'descargar'}
                  onClick={() => {
                    handleDescargar(recetaElectronica.afiliado, recetaElectronica.id);
                  }}
                  disabled={recetaElectronica.codigoEstado !== '1'}
                >
                  <GridActionsCellItem icon={<GetApp />} title='Reporte PDF' label='Descargar' />
                  Descargar
                </MenuItem>
                <MenuItem
                  key={'anular'}
                  onClick={() => {
                    handleAnular(recetaElectronica);
                  }}
                  disabled={recetaElectronica.codigoEstado !== '1'}
                >
                  <GridActionsCellItem icon={<Delete />} title='Anular' label='Anular' />
                  Anular
                </MenuItem>
                <MenuItem
                  key={'enviar'}
                  onClick={() => {
                    handleEnviar(recetaElectronica);
                  }}
                  disabled={recetaElectronica.codigoEstado !== '1'}
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    disabled={recetaElectronica.codigoEstado !== '1'}
                    title='Enviar'
                    label='Enviar'
                  />
                  Enviar
                </MenuItem>
                <MenuItem
                  key={'preview'}
                  onClick={() => {
                    handlePreview(recetaElectronica);
                  }}
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    title='Ver receta'
                    label='Ver receta'
                  />
                  Ver Receta
                </MenuItem>

                <MenuItem
                  key={'generar'}
                  onClick={() => {
                    handleGenerar(recetaElectronica);
                  }}
                >
                  <GridActionsCellItem
                    icon={<Medication />}
                    disabled={recetaElectronica.codigoEstado !== '1'}
                    title='Repetir tratamiento'
                    label='Repetir tratamiento'
                  />
                  Repetir tratamiento
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 0 }}>
          <List>
            {recetaElectronica.codigoEstado === '1' && (
              <ListItem
                sx={{
                  paddingTop: -2,
                  marginTop: -2,
                  marginBottom: -1,
                  py: 0,
                }}
              >
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Vencimiento:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.vencimiento}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}

            {/**ENTREGADA */}
            {recetaElectronica.codigoEstado === '2' && (
              <ListItem
                sx={{
                  paddingTop: -2,
                  marginTop: -2,
                  marginBottom: 1,
                  py: 0,
                }}
              >
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Farmacia:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.farmacia}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}

            {recetaElectronica.tratamiento && (
              <ListItem
                sx={{
                  paddingTop: 1,
                  paddingBottom: 2,
                  py: 0,
                }}
              >
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Tratamiento:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.tratamiento}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}

            <ListItem
              sx={{
                paddingTop: -1,
                marginTop: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Rp1:
                    </Typography>{' '}
                    <Typography component='span' variant='caption'>
                      {recetaElectronica.rp1}
                    </Typography>
                  </>
                }
              />
            </ListItem>

            {recetaElectronica.rp2 !== '-' && (
              <ListItem sx={{ py: 0, marginTop: -1 }}>
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Rp2:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {recetaElectronica.rp2}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const RecetaElectronicaTableResult = (props) => {
  const history = useHistory();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  //const esMobile = matches ? false : true;
  const [success, setSuccess] = useState('');

  const screenWidth = useWidth();

  const [recetaElectronica, setRecetaElecctronica] = useState(null);
  const [onOpenAnular, setOpenAnular] = useState(null);
  const [onOpenEnviar, setOpenEnviar] = useState(null);
  const [loadingResults, setLoadingResults] = useState(null);

  useEffect(() => {
    if (props.recetasElectronicas.length > 1) {
      setLoadingResults(false);
    }
  }, [props.recetasElectronicas]);

  const handleAnular = (recetaElectronica) => {
    setRecetaElecctronica(recetaElectronica);
    setOpenAnular(true);
  };
  const handleEnviar = (recetaElectronica) => {
    setRecetaElecctronica(recetaElectronica);
    setOpenEnviar(true);
  };

  const handleGenerar = async (recetaElectronica) => {
    try {
      history.push({
        pathname: `/generar-receta/${recetaElectronica.idrecetaElectronica}`,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAnulada = async (anulada) => {
    setSuccess('La receta ha sido anulada correctamente');
    setLoadingResults(true);
    setRecetaElecctronica(null);
    setOpenAnular(false);
    if (anulada) {
      props.onRefresh();
    }
  };

  const handleEnviada = async (enviada) => {
    setSuccess('La receta ha sido enviada correctamente');
    setRecetaElecctronica(null);
    setOpenEnviar(false);
  };

  const pdfClic = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        handlePdf(id);
      });
    },
    [],
  );
  const previewClic = React.useCallback(
    (row) => () => {
      setTimeout(() => {
        handlePreview(row);
      });
    },
    [],
  );

  const anularClic = React.useCallback(
    (value) => () => {
      handleAnular(value);
    },
    [],
  );

  const enviarClic = React.useCallback(
    (value) => () => {
      handleEnviar(value);
    },
    [],
  );

  const generarClic = React.useCallback(
    (value) => () => {
      handleGenerar(value);
    },
    [],
  );

  const handlePdf = async (idRecetaElectronica) => {
    try {
      let idsRecetas = [];
      idsRecetas.push(idRecetaElectronica);
      const urlReporte = await getReporteRecetaElectronica({ idsRecetas: idsRecetas });
      history.push({
        pathname: '/previsualizar',
        state:
          {
            url: urlReporte,
            datosBack: {
              pathnameBack: '/consultar-recetas',
              recetasElectronicas: props.recetasElectronicas,
            },
          } || {}, // your data array of objects
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handlePreview = async (row) => {
    try {
      let receta = {};
      if (props.recetasElectronicas?.length > 1) {
        receta = props.recetasElectronicas[row.index];
      } else {
        const result = await findRecetasElectronicas({
          numeroReceta: row.id,
        });
        if (result?.length === 1) {
          receta = result[0];
        }
      }

      //armo objeto apto para reutilizar vista de receta-generada
      const consulta = {};
      consulta.esConsulta = true;
      consulta.afiliado = {
        nombre: receta.personaFisicaAfiliado?.nombre,
        apellido: receta.personaFisicaAfiliado?.apellido,
        idAfiliado: receta.afiliado.idAfiliado,
        numeroDocumentoFormateado: receta.personaFisicaAfiliado?.numeroDocumentoString,
        tipoDocumentoDescripcion: receta.tipoDocumentoAfiliado?.descripcion,
      };
      consulta.profesional = {
        descripcion:
          receta.personaFisicaProfesional.apellido + ', ' + receta.personaFisicaProfesional.nombre,
        letra: receta.profesional.letra,
        matricula: receta.profesional.matricula,
        domicilio: receta.profesional.domicilio,
        telefono: receta.profesional.telefono,
        profesion: receta.profesional.profesion,
      };
      consulta.recetaElectronica = {
        observacion: receta.recetaElectronica.observacion,
        numero: receta.recetaElectronica.numero,
        periodo: receta.recetaElectronica.periodo,
        planDescripcion: receta.plan.descripcion,
        tipoRecetaElectronicaDescripcion: receta.tipoRecetaElectronica?.descripcion,
        tipoRecetaElectronicaCodigo: receta.tipoRecetaElectronica?.codigo,
        fechaInicioVigenciaFormateada: receta.recetaElectronica.fechaInicioVigenciaFormateada,
        vencimientoFormateado: receta.recetaElectronica.vencimientoFormateado,
        fechaEstado: receta.historialEstado.fechaDesde,
        fechaPrescripcion: receta.recetaElectronica.fechaPrescripcion,
        domicilioAfiliado: receta.recetaElectronica.domicilioAfiliado, //se muestran cuando es receta controlada
        telefonoAfiliado: receta.recetaElectronica.telefonoAfiliado, //se muestran cuando es receta controlada
        farmacia: receta.farmacia?.nombre + ' (' + receta.farmacia?.localidad + ')',
      };
      consulta.tipoTratamiento = { descripcion: receta.tipoTratamiento.descripcion };
      consulta.estado = receta.estado;
      consulta.observacion = receta.recetaElectronica.observacion;

      let rpList = [];
      if (receta.registro1) {
        rpList.push({
          cie10: {
            codigo: receta.registro1.cie10Codigo,
            descripcion: receta.registro1.cie10Descripcion,
          },
          medicamento: {
            cantidad: receta.registro1.cantidad,
            drogas: !receta.registro1 ? '-' : receta.registro1?.drogas?.join(', '),
            nombre: receta.registro1.nombre,
            presentacion: receta.registro1.presentacion,
            laboratorio: receta.registro1.laboratorio,
          },
        });
      }
      if (receta.registro2 && receta.registro2 != null) {
        rpList.push({
          cie10: {
            codigo: receta.registro2?.cie10Codigo,
            descripcion: receta.registro2?.cie10Descripcion,
          },
          medicamento: {
            cantidad: receta.registro2.cantidad,
            drogas: !receta.registro2 ? '-' : receta.registro2?.drogas?.join(', '),
            nombre: receta.registro2.nombre,
            presentacion: receta.registro2.presentacion,
            laboratorio: receta.registro2.laboratorio,
          },
        });
      }

      consulta.rpList = rpList;
      consulta.tipoRecetaElectronica = { descripcion: receta.tipoRecetaElectronica.descripcion }; ////cargar datos en findBy
      consulta.cantidadRecetas = receta.estado; //cargar datos en findBy
      consulta.descripcionIds = ''; //cargar datos en findBy
      consulta.idsRecetasAsociadas = receta.idsRecetasAsociadas;

      history.push('/receta-generada', {
        from: history.location,
        receta: consulta,
        datosBack: {
          pathnameBack: '/consultar-recetas',
          recetasElectronicas: props.recetasElectronicas,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const mapRecetaElectronicaToRow = (a, index) => {
    return {
      id: a.recetaElectronica.idRecetaElectronica,
      index: index,
      numero: a.recetaElectronica.numero,
      tratamiento:
        a.tipoTratamiento.codigo !== '2'
          ? a.tipoTratamiento.descripcion
          : `${a.tipoTratamiento.descripcion} (${a.recetaElectronica.periodo})`,
      resume: a,
      idrecetaElectronica: a.recetaElectronica.idRecetaElectronica,
      estado: a.estado.descripcion,
      codigoEstado: a.estado.codigo,
      farmacia: !a.farmacia.nombre ? '-' : `${a.farmacia.nombre} (${a.farmacia.localidad})`,
      correo: a.afiliado.correo,
      fechaEstado: a.historialEstado.fechaDesde,
      fechaPrescripcion: a.recetaElectronica.fechaPrescripcion,
      vencimiento: a.recetaElectronica.vencimiento,
      afiliado: `${a.personaFisicaAfiliado.apellido}, ${a.personaFisicaAfiliado.nombre}`,
      dni: a.personaFisicaAfiliado.numeroDocumento,
      prescriptor: `${a.personaFisicaProfesional.apellido}, ${a.personaFisicaProfesional.nombre}`,
      rp1: !a.registro1
        ? '-'
        : `(${a.registro1?.drogas?.join(', ')}) ${a?.registro1?.presentacion}, Cant.:${
            a?.registro1?.cantidad
          } - Cie10: ${a?.registro1?.cie10Descripcion} `,
      rp2: !a.registro2
        ? '-'
        : `(${a.registro2?.drogas?.join(', ')}) ${a?.registro2?.presentacion}, Cant.:${
            a?.registro2?.cantidad
          } - Cie10: ${a?.registro2?.cie10Descripcion} `,
    };
  };

  const columns = React.useMemo(
    () => [
      {
        field: 'desplegable',
        headerName: 'Recetas electrónicas:',
        flex: 1,
        renderCell: (a) => (
          <DataCollapseDrawer
            recetaElectronica={a.row}
            onAnular={(e) => {
              handleAnular(e);
            }}
            onEnviar={(e) => {
              handleEnviar(e);
            }}
            onGenerar={(e) => {
              handleGenerar(e);
            }}
            onPreview={(e) => {
              handlePreview(e);
            }}
          />
        ),
      },
      { field: 'numero', headerName: 'Nro.', width: 80 },
      { field: 'afiliado', headerName: 'Afiliado', flex: 0.08 },
      { field: 'estado', headerName: 'Estado', width: 85 },
      { field: 'tratamiento', headerName: 'Tratamiento', width: 110 },
      { field: 'fechaPrescripcion', headerName: 'Prescripción', width: 100 },
      { field: 'vencimiento', headerName: 'Vencimiento', width: 100 },
      { field: 'rp1', headerName: 'Rp1', flex: 0.1 },
      { field: 'rp2', headerName: 'Rp2', flex: 0.1 },
      { field: 'farmacia', headerName: 'Farmacia', width: 80 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Delete />}
            label='Anular'
            disabled={params.row.codigoEstado !== '1'}
            onClick={anularClic(params.row)}
          />,
          <GridActionsCellItem
            icon={<PictureAsPdfOutlined />}
            label='Reporte PDF'
            disabled={params.row.codigoEstado !== '1'}
            onClick={pdfClic(params.row.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<OpenInNew />}
            label='Ver Receta'
            onClick={previewClic(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<ForwardToInbox />}
            label='Enviar'
            disabled={params.row.codigoEstado !== '1'}
            onClick={enviarClic(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<Medication />}
            label='Repetir tratamiento'
            onClick={generarClic(params.row)}
            showInMenu
          />,
        ],
      },
    ],
    [previewClic, anularClic, enviarClic, generarClic],
  );

  const dataGridRecetas = props.recetasElectronicas && (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      spacing={1}
      sx={{ paddingTop: '-10px', marginTop: '-30px' }}
    >
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <StyledDataGridRecetas
            getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '5px',
              },
            }}
            columns={columns}
            loading={loadingResults}
            rows={props.recetasElectronicas.map((a, index) => mapRecetaElectronicaToRow(a, index))} //
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            components={{
              NoRowsOverlay: () => (
                <Stack height='100%' alignItems='center' justifyContent='center'>
                  Sin Recetas Electrónicas.
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              numero: screenWidth !== 'xs',
              tratamiento: screenWidth !== 'xs',
              fechaPrescripcion: screenWidth !== 'xs',
              vencimiento: screenWidth !== 'xs',
              estado: screenWidth !== 'xs',
              afiliado: screenWidth !== 'xs',
              farmacia: screenWidth !== 'xs',
              rp1: screenWidth !== 'xs',
              rp2: screenWidth !== 'xs',
              actions: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
          />
        </div>
      </Grid>
    </Grid>
  );
  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  return (
    <Grid container spacing={2} sx={{ paddingTop: '-10px', marginTop: '-10px' }}>
      <Grid item xs={12} sx={{ paddingTop: '-20px', marginTop: '-10px' }}>
        <Card sx={{ width: '100%', paddingTop: '-20px', marginTop: '-10px' }}>
          <CardContent>{dataGridRecetas}</CardContent>
        </Card>
      </Grid>
      {onOpenAnular ? (
        <AnularRecetaElectronicaPopUp
          open={onOpenAnular}
          recetaElectronica={recetaElectronica}
          setRecetaAnulada={handleAnulada}
          onCancelar={() => setOpenAnular(false)}
        />
      ) : null}
      {onOpenEnviar ? (
        <EnviarRecetaElectronicaPopUp
          open={onOpenEnviar}
          recetaElectronica={recetaElectronica}
          setRecetaEnviada={handleEnviada}
          onCancelar={() => setOpenEnviar(false)}
        />
      ) : null}

      <CustomizedSnackbars
        open={success}
        autoHideDuration={8000}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default RecetaElectronicaTableResult;
