import { axiosGet, axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * Generar una recetalelectronica
 * @returns
 */
export const generarRecetaElectronica = async (data) => {
  return axiosPost('/v1/receta-electronica/generar', data);
};

/**
 *
 * @param {idRecetaElectronica, observacion} data
 * @returns
 */
export const anularRecetaElectronica = async (data) => {
  const recetas = await axiosPost('/v1/receta-electronica/anular', data);
  return recetas;
};

/**
 *
 * Generar una recetalelectronica
 * @returns
 */
export const getReporteRecetaElectronica = async (data) => {
  return axiosPostFile('/v1/receta-electronica/imprimir', data);
};

/**
 * Enviar por correo la receta electronica
 * @returns
 */
export const enviarRecetaElectronica = async (data) => {
  return axiosPostFile('/v1/receta-electronica/enviar', data);
};

/**
 * Enviar por correo la receta electronica
 * @returns
 */
export const findRecetaElectronica = async (idRecetaElectronica) => {
  const query = `?idRecetaElectronica=${idRecetaElectronica}`;
  return axiosGet(`/v1/receta-electronica/find${query || ''}`);
};
