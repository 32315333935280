import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Box, Button, CardActions, Fab, FormControl, Grid, IconButton, Stack } from '@mui/material';
import { Add, Assignment, AttachMoney, Delete, HighlightOff } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CustomizedSnackbars from '../Notifications/SnackBar';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import PrestadorSelect from '../Prestador/PrestadorSelect';
import { getValidarOrden, getVerificarCostoOrden } from '../../services/Seo/Orden';
import OrdenPreview from './OrdenPreview';
import OrdenValidadaResumidaForm from './OrdenValidadaResumidaForm';
import VerificarCostoOrdenResumidaView from './VerificarCostoOrdenResumidaView';
import PracticaOrden from './components/PracticaOrden';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';
import PrestadorAutocompleteVirtualizado from '../Prestador/PrestadorAutocompleteVirtualizado';

const EmitirOrdenVaria = () => {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  // Dialog Orden
  const [open, setOpen] = useState(false);
  const [datosOrdenReceived, setDatosOrdenReceived] = useState({});
  const [loadingOrden, setLoadingOrden] = useState(false);

  // Dialog Orden Preview
  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  /**Definimos los estados de las variables seleccionadas, las cuales seran usadas por el service */
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  // const [selectedPractica, setSelectedPractica] = useState(null);

  const [practicasList, addPractica] = useState([{ practica: null, cantidad: 1 }]);
  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [selectedPrescriptor, setSelectedPrescriptor] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);
  const [practicasNoSeleccionadas, setPracticasNoSeleccionadas] = useState(true);

  // Dialog Verificar Costo Orden
  const [openVerificarCostoOrden, setOpenVerificarCostoOrden] = useState(false);
  const [datosVerificarCostoOrdenReceived, setDatosVerificarCostoOrdenReceived] = useState({});
  const [loadingVerificarCostoOrden, setLoadingVerificarCostoOrden] = useState(false);

  const [prestadorLogueado, setPrestadorLogueado] = useState(null);

  const MAX_PRACTICAS = 7;

  const practicaModel = {
    practica: null,
    cantidad: '1',
  };

  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  useEffect(() => {}, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador != null) {
        setPrestadorLogueado(prestador);
      }
    };

    if (prestadorLogueado === null) {
      getPrestador();
    }
  }, []);

  const handlerLimpiar = () => {
    reset();
    onSelectAfiliado(null);
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    setSelectedAfiliado(null);
    //setSelectedPractica(null);
    addPractica([{ practica: null, cantidad: 1 }]);
    setSelectedPrestador(null);
    setSelectedPrescriptor(null);
  };

  const getDataOrden = () => {
    let data = {
      numeroTarjeta: selectedAfiliado.numeroTarjeta,
      codigoPrestador: selectedPrestador.matricula,
      tipoPrestador: selectedPrestador.tipo,
      codigoPrescriptor: selectedPrescriptor.matricula,
      idPrestadorPrescriptor: selectedPrescriptor.idPrestador,
      practicas: practicasList.map((item, key) => ({
        codigo: item.practica.codigo,
        cantidad: item.cantidad,
      })),
    };
    return data;
  };

  const onSubmitOrden = async (data, event) => {
    setLoadingOrden(true);
    event.preventDefault();

    try {
      const res = await getValidarOrden(getDataOrden());
      setDatosOrdenReceived(res);
      setOpen(!open);
      setLoadingOrden(false);
    } catch (e) {
      setLoadingOrden(false);
    }
  };

  const validateAfiliado = () => {
    return selectedAfiliado == null ? false : true;
  };

  const handlerOrdenConfirmada = (datosOrden, urlPdf) => {
    setSuccess('Orden generada correctamente');
    setUrlReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenDialogReporte(true);
  };
  const handlerVerificarCosto = async () => {
    setLoadingVerificarCostoOrden(true);
    try {
      const res = await getVerificarCostoOrden(getDataOrden());
      setDatosVerificarCostoOrdenReceived(res);
      setOpenVerificarCostoOrden(!openVerificarCostoOrden);
      setLoadingVerificarCostoOrden(false);
    } catch (e) {
      setLoadingVerificarCostoOrden(false);
    }
  };

  const handlerCerrarPreview = () => {
    handlerLimpiar();
  };

  const handlerSelectPrescriptor = (prestador) => {
    setSelectedPrescriptor(prestador);
  };

  const validatePrescriptor = () => {
    // Si no esta activo seguimos mostrando que debe seleccionar un prescriptador
    return selectedPrescriptor !== null && selectedPrescriptor.codigoEstado === '1' ? true : false;
  };

  /**
   * Al hacer clic en el botón de eliminar una practica, este item
   * pasa el indice que se está eliminando y con esto recorremos
   * el array de practicas y eliminamos dicho objeto de la lista
   * @param {int} indice
   */
  const onDeletePractica = (indice) => {
    let list = practicasList.filter((item, key) => key !== indice);
    if (list.length < 1) {
      list.push(practicaModel);
    }
    addPractica(list);
    reset();
  };

  /**
   * Al hacer clic en el botón ADD se crea un nuevo objeto
   * y se lo agrega en el array de practicas
   */
  const onAddNewPractica = () => {
    addPractica([...practicasList, practicaModel]);
  };

  /**
   * Al realizar un cambio de datos en el objeto practica, se ejecuta
   * el evento de actualización que envía indice y objeto nuevo a actualizar.
   * Se busca el elemento en la lista y se remplaza actualizando la misma.
   * @param {int} indice
   * @param {Object} newValue
   */
  const onChangePractica = (indice, newValue) => {
    if (newValue) {
      let list = practicasList;
      list[indice] = newValue;
      handlerPracticaVacia(list);
    }
  };

  const handlerPracticaVacia = (listaPracticas) => {
    listaPracticas.forEach((element, key) => {
      if (element.practica === null) {
        setPracticasNoSeleccionadas(true);
      } else {
        setPracticasNoSeleccionadas(false);
      }
    });
  };
  /**
   * valida practicas repetidas
   */
  const validatePracticas = () => {
    // si solo es una practica no valido , retorno directamente

    if (practicasList?.length === 1) {
      return true;
    }

    const validarRepetidas = (value, key) => {
      let out = false;
      practicasList.find((value2, key2) => {
        if (key2 !== key && value.practica?.idPractica === value2.practica?.idPractica) {
          out = true;
        }
      });

      return out;
    };
    let newList = practicasList.filter(
      (value, key) => value?.practica != null && validarRepetidas(value, key),
    );
    return newList.length < 1;
  };

  const handleBorrarMensaje = () => {
    setError('');
    setSuccess('');
    setWarning('');
  };

  const DrawerForm = (
    <form onSubmit={handleSubmit(onSubmitOrden)}>
      <CardContent>
        <FormControl fullWidth={true} variant='outlined' margin='normal'>
          <PrestadorSelect
            key={keyComponentAfiliado + '_prestador'}
            value={prestadorLogueado}
            disabled={prestadorLogueado !== null ?? true}
            name='prestadorRegister'
            {...register('prestadorRegister', {
              validate: () => selectedPrestador?.idPrestador || false,
            })}
            onSelectPrestador={(prestador) => {
              setSelectedPrestador(prestador);
            }}
          />
          {errors.prestadorRegister && (
            <p style={{ color: 'red' }}>Debe seleccionar un prestador</p>
          )}
        </FormControl>
        <FormControl fullWidth={true} variant='outlined' margin='normal'>
          <PrestadorAutocompleteVirtualizado
            key={keyComponentAfiliado + '_prescriptor'}
            value={selectedPrescriptor}
            label={'Prescriptor'}
            widthMax={1100}
            modoPrescriptor={true}
            name='prescriptorRegister'
            {...register('prescriptorRegister', {
              validate: () => selectedPrescriptor?.idPrestador || false,
            })}
            onSelectedPrestador={handlerSelectPrescriptor}
          />
          {errors.prescriptorRegister && errors.prescriptorRegister.type === 'validate' && (
            <p style={{ color: 'red' }}>Debe seleccionar el prescriptor</p>
          )}
        </FormControl>
        <FormControl fullWidth={true} variant='outlined' sx={{ marginTop: 4 }}>
          <BuscarAfiliado
            initialValue={selectedAfiliado}
            onSelectAfiliado={(afiliado) => setSelectedAfiliado(afiliado)}
            ref={register('afiliadoRegister', {
              validate: validateAfiliado,
            })}
          />
          {errors.afiliadoRegister != null && (
            <p style={{ color: 'red' }}>Debe seleccionar el afiliado</p>
          )}
        </FormControl>
        <FormControl fullWidth={true} variant='outlined' margin='normal'>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={11}>
                {errors.ordenPracticas != null && (
                  <p style={{ color: 'red' }}>Existen prácticas repetidas</p>
                )}
              </Grid>
              <Grid item xs={12} sm={1}>
                {practicasList?.length < MAX_PRACTICAS && (
                  <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
                    <Fab
                      size='small'
                      color='primary'
                      aria-label='Botón agregar nueva practica.'
                      sx={{ display: { xs: 'none', sm: 'flex' } }}
                      onClick={() => onAddNewPractica()}
                    >
                      <Add />
                    </Fab>
                    <Button
                      variant='contained'
                      fullWidth
                      sx={{ display: { sm: 'none', xs: 'flex' } }}
                      onClick={() => onAddNewPractica()}
                    >
                      <Add /> Agregar
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* COMPONENTE PRACTICA ORDEN */}
            {practicasList.length >= 0 &&
              practicasList.map((item, key) => (
                <Grid
                  key={key}
                  container
                  p={1}
                  gap={1}
                  mt={1}
                  sx={{ border: '1px solid #B5B2B2', borderRadius: '4px' }}
                >
                  <Grid container>
                    <Grid item xs={9} sm={11}>
                      <Typography variant='h6'></Typography>
                    </Grid>
                    <Grid item xs={3} sm={1} textAlign='right'>
                      <IconButton
                        aria-label='delete'
                        color='primary'
                        onClick={() => onDeletePractica(key)}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <PracticaOrden
                    indice={key}
                    practica={item}
                    onPracticaChanged={(value) => onChangePractica(key, value)}
                    register={register}
                    reset={reset}
                    errors={errors}
                    ref={register('ordenPracticas', {
                      validate: validatePracticas,
                    })}
                  ></PracticaOrden>
                </Grid>
              ))}
          </Grid>
        </FormControl>
      </CardContent>

      <CardActions sx={{ justifyContent: 'flex-end', p: 2, fullWidth: true }}>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
          >
            <Button
              variant='contained'
              startIcon={<HighlightOff />}
              color='neutral'
              onClick={handlerLimpiar}
            >
              {' '}
              Limpiar{' '}
            </Button>
            <LoadingButton
              fullWidth={true}
              variant='contained'
              color='neutral'
              startIcon={<AttachMoney />}
              loading={loadingVerificarCostoOrden ? 'show' : 'hide'}
              content={'Verificar Costo'}
              onClick={handleSubmit(handlerVerificarCosto)}
            />
            <LoadingButton
              fullWidth={true}
              variant='contained'
              size='medium'
              color='primary'
              startIcon={<Assignment />}
              type='submit'
              loading={loadingOrden ? 'show' : 'hide'}
              content={'Generar'}
              disabled={
                !selectedAfiliado?.idAfiliado ||
                !selectedPrestador?.idPrestador ||
                !selectedPrescriptor?.idPrestador ||
                practicasNoSeleccionadas
              }
            />
          </Stack>
        </Box>
      </CardActions>
    </form>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5'>
              Nueva Orden de Prácticas
            </Typography>
            <Typography gutterBottom>
              Acá podrás emitir órdenes de prácticas médicas y bioquímicas ambulatorias, para luego
              descargarlas, imprimirlas y/o enviarlas por correo electrónico.
            </Typography>
          </CardContent>

          {DrawerForm}
        </Card>

        <CustomizedSnackbars
          open={success}
          autoHideDuration={8000}
          severity='success'
          message={success}
          onDeleteMessage={handleBorrarMensaje}
        />
        <CustomizedSnackbars
          open={error}
          autoHideDuration={8000}
          severity='error'
          message={error}
          onDeleteMessage={handleBorrarMensaje}
        />
        <CustomizedSnackbars
          open={warning}
          autoHideDuration={8000}
          severity='warning'
          message={warning}
          onDeleteMessage={handleBorrarMensaje}
        />
      </Grid>

      <OrdenValidadaResumidaForm
        open={open}
        setOpen={setOpen}
        datosOrden={datosOrdenReceived}
        handlerOrdenConfirmada={handlerOrdenConfirmada}
      ></OrdenValidadaResumidaForm>

      <VerificarCostoOrdenResumidaView
        open={openVerificarCostoOrden}
        setOpen={setOpenVerificarCostoOrden}
        datosOrden={datosVerificarCostoOrdenReceived}
      ></VerificarCostoOrdenResumidaView>

      <OrdenPreview
        open={openDialogReporte}
        setOpen={setOpenDialogReporte}
        urlReporte={urlReporte}
        datosOrden={datosOrdenGenerada}
        showMail={true}
        nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
        handlerCerrarPreview={handlerCerrarPreview}
      ></OrdenPreview>
    </Grid>
  );
};

export default EmitirOrdenVaria;
