import { axiosPost } from '../ServiceBase.js';

/**
 * Generar token Orden Electrónica api call
 * @returns
 */
export const generarTokenConsulta = async (data) => {
  return axiosPost('/v1/orden-digital/generar-token-consulta', data);
};

export const anularOrden = async (data) => {
  return axiosPost('/v1/orden/anular-orden-electronica', data);
};
