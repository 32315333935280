import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  getContactoConfirmado,
  getContactos,
  generarCodigoConfirmacion,
} from '../../services/Common/Contacto';
import ConfirmarContacto from './ConfirmarContacto';

import AgregarContacto from './AgregarContacto';
import ContactoConfirmado from './ContactoConfirmado';
import { Container } from '@mui/material';
import { useUser } from '../Auth/User';
import SeleccionarContacto from './ConfirmarContacto/SeleccionarContacto';
import SinContacto from './SinContacto';
import ConsultoriosRestringidos from './ConsultoriosRestringidos';

const ValidarContacto = () => {
  const { signOut, token } = useUser();
  const [contactoConfirmado, setContactoConfirmado] = useState(false);
  const [contactos, setContactos] = useState(null);
  // TODO sacar es para testing
  const [codigoEnviado, setcodigoEnviado] = useState(null);
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [openAgregar, setOpenAgregar] = useState(false);
  const [openSeleccionar, setOpenSeleccionar] = useState(false);
  const [openConfirmado, setOpenConfirmado] = useState(false);
  const [openSinContacto, setOpenSinContacto] = useState(false);
  // TODO es momentaneo, porque los consultorios no tienen persona juridica entonces hay que ver como confirmar el contacto
  // lo mismo para recuperar pin no se podra porque los consultorios no tienen cuit

  const [openConsultoriosRestringidos, setOpenConsultoriosRestringidos] = useState(false);
  const [mensajeRestrictivo, setMensajeRestrictivo] = useState('');

  const cerrarSesion = () => {
    signOut();
    localStorage.clear();
    setContactoConfirmado(true);
  };

  const handleCloseSeleccionar = () => {
    setOpenSeleccionar(false);
    cerrarSesion();
  };

  const handleCloseConfirmar = () => {
    setOpenConfirmar(false);
    cerrarSesion();
  };

  const handleCloseConfirmado = () => {
    setOpenConfirmado(false);
    setContactoConfirmado(true);
  };

  const handleCancelarFromAgregarContacto = async () => {
    setOpenAgregar(false);
    if (contactos === null || contactos.length === 0) {
      cerrarSesion();
    } else {
      if (contactos.length === 1) {
        const res = await generarCodigoConfirmacion(
          contactos[0].idHistorialContactoPersonaJuridica,
        );
        setcodigoEnviado(res);
        setOpenConfirmar(true);
      } else {
        if (contactos.length === 1) {
          await generarCodigoConfirmacion(contactos[0].idHistorialContactoPersonaJuridica);
          setOpenConfirmar(true);
        } else {
          setOpenSeleccionar(true);
        }
      }
    }
  };

  const handleAgregarContactoFromConfirmar = () => {
    setOpenConfirmar(false);
    setOpenAgregar(true);
  };

  const handleAgregarCorreoFromSeleccionar = () => {
    setOpenSeleccionar(false);
    setOpenAgregar(true);
  };

  const handleContactoConfirmado = () => {
    setOpenConfirmar(false);
    setOpenConfirmado(true);
  };

  const handleCodigoEnviado = (idHistorialContactoPersonaJuridica) => {
    setOpenSeleccionar(false);
    // selecciono de la lista de contactos el contacto que seleccionó el usuario
    const contactoSeleccionado = contactos.find(
      (item) => item.idHistorialContactoPersonaJuridica === idHistorialContactoPersonaJuridica,
    );

    setContactos([contactoSeleccionado]);
    setOpenConfirmar(true);
  };

  const handleContactoAgregado = async (contacto) => {
    setOpenAgregar(false);
    // Genero el codigo de Confirmacion  y abro el ventana de confirmar
    const res = await generarCodigoConfirmacion(contacto.idHistorialContactoPersonaJuridica);
    setcodigoEnviado(res);
    setContactos([contacto]);
    setOpenConfirmar(true);
  };

  useEffect(() => {
    const get = async () => {
      try {
        const res = await getContactoConfirmado();

        if (res !== null) {
          // Tiene contacto Confirmado => Ingresa
          setContactoConfirmado(true);
        } else {
          // No tiene contacto confirmado => Busco los contactos para mandar codigo de confirmacion
          const resContactos = await getContactos();

          if (resContactos.length > 0) {
            setContactos(resContactos);
            // Abro ventana para que seleccione a cual correo quiere enviar el codigo
            setOpenSeleccionar(true);
          } else {
            // No tienen ningun contacto cargado, abro vantana para que cargue uno
            setOpenSinContacto(true);
          }
        }
      } catch (e) {
        // TODO es momentaneo, porque los consultorios no tienen persona juridica entonces hay que ver como confirmar el contacto
        // lo mismo para recuperar pin no se podra porque los consultorios no tienen cuit
        if (e.data.status == 'error') {
          setOpenConsultoriosRestringidos(true);
          setMensajeRestrictivo(e.data.message);
        }
        setContactos(null);
      }
    };
    if (token) {
      get();
    }
  }, [token]);

  return contactoConfirmado ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/validar-contacto' },
      }}
    />
  ) : (
    <Container>
      <ConfirmarContacto
        onAgregarContacto={handleAgregarContactoFromConfirmar}
        onContactoConfirmado={handleContactoConfirmado}
        open={openConfirmar}
        contactos={contactos}
        codigoEnviado={codigoEnviado}
        onClose={handleCloseConfirmar}
      />

      <SeleccionarContacto
        onAgregarContacto={handleAgregarCorreoFromSeleccionar}
        open={openSeleccionar}
        contactos={contactos}
        onCodigoEnviado={handleCodigoEnviado}
        onClose={handleCloseSeleccionar}
      />

      <AgregarContacto
        open={openAgregar}
        onContactoAgregado={handleContactoAgregado}
        onClose={handleCancelarFromAgregarContacto}
      />

      <ContactoConfirmado open={openConfirmado} onClose={handleCloseConfirmado} />
      <SinContacto open={openSinContacto} onClose={handleCloseConfirmar} />
      <ConsultoriosRestringidos
        open={openConsultoriosRestringidos}
        onClose={handleCloseConfirmar}
        message={mensajeRestrictivo}
      />
    </Container>
  );
};

export default ValidarContacto;
