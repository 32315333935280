import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Box, Button, Grid, Hidden, TextField, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { registrarAtencion, buscarOrden } from '../../services/Seo/Orden';
import PrestadorSelect from '../Prestador/PrestadorSelect';
import { Assignment, HighlightOff, Search } from '@mui/icons-material';
import CustomizedSnackbar from '../Notifications/SnackBar';
import { useTheme } from '@emotion/react';
import NumeroOrdenQRScan from './components/NumeroOrdenQRScan';
import DrawerNumeroOrden from './components/DrawerNumeroOrden';
import { isMobile } from '../../@iosper/utils/DeviceDetector';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';
import PrestadorAutocompleteVirtualizado from '../Prestador/PrestadorAutocompleteVirtualizado';
import { UltimosPrestadoresOrden } from '../Prestador/UltimosPrestadoresOrden';
import { LoadingButton } from '@mui/lab';

const RegistrarAtencion = () => {
  // Para ver si entró desde mobile o web, si es mobile no muestra la opción de Orden Electrónica
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;
  const [showQrScanner, onShowQrScanner] = useState(false);

  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [countPrestadoresSelector, setCountPrestadoresSelector] = useState(null);
  const [keyComponentSelectPrestador, setKeyComponentSelectPrestador] = useState(0);

  const [loadingBuscar, setLoadingBuscar] = useState(false);
  const [numeroOrden, setNumeroOrden] = useState('');
  const [datosOrden, setDatosOrden] = useState(null);

  const [success, setSuccess] = useState('');

  const [loadingRegistrar, setLoadingRegistrar] = useState(false);
  const [ayudaActivada, setAyudaActivada] = useState(false);
  const [scanActivo, setScanActivo] = useState(false);
  const [keyComponentNumeroOrden, setKeyComponentNumeroOrden] = useState(0);
  const [prestadorLogueado, setPrestadorLogueado] = useState(null);

  useEffect(() => {
    if (countPrestadoresSelector === 1 && esMobile) {
      onShowQrScanner(true);
    }
  }, [esMobile, countPrestadoresSelector]);

  useEffect(() => {
    if (countPrestadoresSelector === 1 && esMobile && numeroOrden !== '') {
      onBuscar();
    }
  }, [showQrScanner]);

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador != null) {
        setPrestadorLogueado(prestador);
        setSelectedPrestador(prestador);
      }
    };

    if (prestadorLogueado === null) {
      getPrestador();
    }
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'onBlur' });

  const handlerLimpiar = () => {
    reset();
    setLoadingBuscar(false);
    setLoadingRegistrar(false);
    setSelectedPrestador(null);
    setNumeroOrden('');
    setDatosOrden(null);
    setKeyComponentSelectPrestador(1 + Math.random() * (1000 - 1));
    setKeyComponentNumeroOrden(1 + Math.random() * (1000 - 1));
  };

  const onBuscar = async () => {
    try {
      const res = await buscarOrden(getDataOrden());
      setDatosOrden(res);
    } catch (e) {
      setLoadingBuscar(false);
    }
  };

  const onSubmitBuscar = async (data, event) => {
    event.preventDefault();
    setLoadingBuscar(true);
    onBuscar();
  };

  const onSubmitRegistrar = async (data, event) => {
    event.preventDefault();
    setLoadingRegistrar(true);

    try {
      const res = await registrarAtencion(datosOrden);
      if (res) {
        setSuccess('Atención Registrada.');
        handlerLimpiar();
      }
    } catch (e) {
      setLoadingRegistrar(false);
    }
  };

  const getDataOrden = () => {
    let data = {
      idPrestador: selectedPrestador.idPrestador,
      matriculaPrestador: selectedPrestador.matricula,
      numeroOrden: parseInt(numeroOrden),
    };
    return data;
  };

  const handlerChangeNumeroOrden = (value) => {
    setNumeroOrden(value);
    setValue('numeroorden', value);
  };

  const handlerAyudaActivada = (value) => {
    setAyudaActivada(value);
  };

  const handlerScanActivo = (value) => {
    setScanActivo(value);
  };

  const validatePrestador = () => {
    // Si no esta activo seguimos mostrando que debe seleccionar un prestador
    return selectedPrestador !== null && selectedPrestador.codigoEstado === '1' ? true : false;
  };

  const handlerSelectPrestador = (prestador) => {
    setSelectedPrestador(prestador);
  };

  const handlerUltimoPrestador = (prestador) => {
    handlerSelectPrestador(prestador);
  };

  const DrawerForm = (
    <form onSubmit={handleSubmit(onSubmitBuscar)}>
      <CardContent sx={{ p: 2 }}>
        <Hidden xsUp={scanActivo || ayudaActivada} xsDown={scanActivo || ayudaActivada}>
          <Grid container spacing={2} alignItems='center'>
            <Grid
              item
              xs={prestadorLogueado?.idProfesional || prestadorLogueado?.tipo === '15' ? 12 : 11}
            >
              {prestadorLogueado?.idProfesional || prestadorLogueado?.tipo === '15' ? (
                <PrestadorSelect
                  key={keyComponentSelectPrestador + '_prestador'}
                  value={selectedPrestador}
                  disabled={prestadorLogueado !== null ?? true}
                  name='prestadorRegister'
                  {...register('prestadorRegister', {
                    validate: () => selectedPrestador !== null || false,
                  })}
                  onSelectPrestador={handlerSelectPrestador}
                />
              ) : (
                <PrestadorAutocompleteVirtualizado
                  key={keyComponentSelectPrestador + '_prestador'}
                  value={selectedPrestador}
                  disabled={prestadorLogueado !== null ?? true}
                  name='prestadorRegister'
                  {...register('prestadorRegister', {
                    validate: () => selectedPrestador !== null || false,
                  })}
                  onSelectedPrestador={handlerSelectPrestador}
                />
              )}
            </Grid>
            {!prestadorLogueado?.idProfesional && prestadorLogueado?.tipo !== '15' && (
              <Grid item xs={1}>
                <UltimosPrestadoresOrden onSelectEvent={handlerUltimoPrestador} />
              </Grid>
            )}
          </Grid>
          {errors.prestadorRegister && errors.prestadorRegister.type === 'validate' && (
            <p style={{ color: 'red' }}>Debe seleccionar el prestador</p>
          )}
        </Hidden>
        <NumeroOrdenQRScan
          key={keyComponentNumeroOrden + '__numeroorden'}
          register={register}
          errors={errors}
          change={handlerChangeNumeroOrden}
          onAyudaActivada={handlerAyudaActivada}
          onScanActivo={handlerScanActivo}
          tituloAyuda='¿Querés registrar la atención de una orden?'
        />
      </CardContent>
      <Hidden xsUp={scanActivo || ayudaActivada} xsDown={scanActivo || ayudaActivada}>
        <CardActions sx={{ px: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                }}
              >
                <Button
                  variant='contained'
                  startIcon={<HighlightOff />}
                  color='neutral'
                  onClick={handlerLimpiar}
                >
                  Limpiar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  startIcon={<Search />}
                  type='submit'
                  loading={loadingBuscar}
                >
                  Buscar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Hidden>
    </form>
  );

  const DrawerRegistrar = (
    <form onSubmit={handleSubmit(onSubmitRegistrar)}>
      <CardContent sx={{ justifyContent: 'flex-end', p: 2 }}>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <DrawerNumeroOrden
            codigoTipoOrden={datosOrden?.codigoTipoOrden}
            numeroOrden={datosOrden?.numeroOrden}
            tipoOrden={datosOrden?.tipoOrden}
          ></DrawerNumeroOrden>
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='order-fechageneracion'
            variant='standard'
            label='Estado'
            defaultValue={datosOrden?.estadoOrden}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='order-fechageneracion'
            variant='standard'
            label='Fecha de Emisión'
            defaultValue={datosOrden?.fechaEmision}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='afiliado-nya'
            variant='standard'
            label='Afiliado'
            defaultValue={`(${datosOrden?.nroDocumento}) ${datosOrden?.nombre}, ${datosOrden?.apellido}`}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='prestador-nya'
            label='Prestador'
            variant='standard'
            defaultValue={`(${datosOrden?.matriculaPrestador}) ${datosOrden?.nombrePrestador} `}
            size='small'
            multiline
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl variant='standard' sx={{ m: 1 }} fullWidth>
          <TextField
            id='coseguro-total'
            label='Coseguro Total'
            variant='standard'
            defaultValue={` ${datosOrden?.totalCoseguro} `}
            size='small'
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </CardContent>
      <CardActions sx={{ px: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
              }}
            >
              <Button
                variant='contained'
                startIcon={<HighlightOff />}
                color='neutral'
                onClick={handlerLimpiar}
              >
                Cancelar
              </Button>
              <LoadingButton
                variant='contained'
                size='medium'
                color='primary'
                startIcon={<Assignment />}
                type='submit'
                loading={loadingRegistrar}
              >
                Registrar
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 0 }}>
          <Hidden xsUp={ayudaActivada} xsDown={ayudaActivada}>
            <CardContent>
              <Typography gutterBottom variant='h5'>
                Registrar Atención de Orden Impresa
              </Typography>
              <Typography gutterBottom>
                Acá podrás informar que la orden se usó para atender al paciente y el sistema
                validará que está en condiciones de ser cobrada.
              </Typography>
            </CardContent>
          </Hidden>

          {!datosOrden && DrawerForm}

          {datosOrden && DrawerRegistrar}

          <Hidden xsUp={ayudaActivada} xsDown={ayudaActivada}>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              textAlign={'center'}
              sx={{ p: 2 }}
            >
              El registro de atención de ordenes impresas debe hacerse al momento que el prestador
              la recibe, de esta manera podrá verificar que la orden está en condiciones de ser
              cobrada.
            </Typography>
          </Hidden>
        </Card>

        <CustomizedSnackbar
          open={success}
          autoHideDuration={10000}
          severity='success'
          message={success}
        />
      </Grid>
    </Grid>
  );
};

export default RegistrarAtencion;
