import { Alert, Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';

export default function CustomizedSnackbars(props) {
  const [open, setOpen] = useState(false);
  const vertical = props.vertical || 'bottom';
  const horizontal = props.horizontal || 'center';

  const handleClose = (event, reason) => {
    // controlar si hay mensaje (error, success, warning, information) y la funcion fue definida en el componente padre
    // (cada componente que use CustomizedSnackbars debera manejar/crear funcion para poder cambiar el estado de props.open)
    if (props.open !== '' && typeof props.onDeleteMessage === 'function') {
      props.onDeleteMessage();
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open !== '');
  }, [props.open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
