import { axiosGet } from '../ServiceBase.js';

export const getPracticas = async () => {
  try {
    const practicasAfiliado = JSON.parse(sessionStorage.getItem('practicas'));
    if (practicasAfiliado == null) {
      const practicas = await axiosGet('/v1/practicas/practicas-consultorio');
      sessionStorage.setItem('practicas', JSON.stringify(practicas));
      return practicas;
    } else {
      return practicasAfiliado;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPracticasPorRol = async () => {
  try {
    const practicasPorRol = JSON.parse(sessionStorage.getItem('practicasPorRol'));
    if (practicasPorRol == null) {
      const practicas = await axiosGet('/v1/practicas/practicas-por-rol');
      sessionStorage.setItem('practicasPorRol', JSON.stringify(practicas));
      return practicas;
    } else {
      return practicasPorRol;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
