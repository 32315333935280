import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as SuccessAlertAnimated } from '../../../assets/images/svg/success-alert-animated-tres.svg';
import { ReactComponent as ErrorAlertAnimated } from '../../../assets/images/svg/error-alert-animated-tres.svg';
import { ReactComponent as WarningAlertAnimated } from '../../../assets/images/svg/warning-alert-animated-tres.svg';

export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';

const LoadingAlert = ({
  result = SUCCESS,
  loading = true,
  defaultLoading = false,
  loadingTime = 1000,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [isDefaultLoading, setIsDefaultLoading] = useState(defaultLoading);
  const TagResult = () => {
    let Tag = '';
    switch (result) {
      case SUCCESS:
        Tag = SuccessAlertAnimated;
        break;
      case ERROR:
        Tag = ErrorAlertAnimated;
        break;
      case WARNING:
        Tag = WarningAlertAnimated;
        break;
      default:
        Tag = SuccessAlertAnimated;
        break;
    }

    return (
      <Tag
        className={isLoading ? '' : 'animated'}
        style={{
          height: '80px',
          width: '80px',
        }}
      />
    );
  };

  useEffect(() => {
    if (isDefaultLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, loadingTime);
    }
  }, [result]);

  return (
    <Box sx={{ m: 1, alignItems: 'center' }} position={props.position || 'relative'}>
      {!isLoading && <TagResult />}
      {isLoading && <CircularProgress size={80} />}
    </Box>
  );
};

export default LoadingAlert;
