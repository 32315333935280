import { useState } from 'react';
import { axiosGet, axiosPost } from '../ServiceBase.js';
import axios from 'axios';

export const useToken = () => {
  const getToken = () => localStorage.getItem('token');
  const [token, setToken] = useState(getToken);

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);

    /**
     * Seteamos el token para los headers de AXIOS
     */
    if (userToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  return [token, saveToken];
};

export const singInUser = async ({ username, password }) => {
  try {
    const result = await axiosPost('/v1/auth/signin', { nombre: username, password: password });
    return result?.token || null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getUsuario = async () => {
  try {
    return await axiosGet('/v1/auth/usuario');
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const verifyRecaptcha = async (token) => {
  return axiosPost('/v1/auth/verify-recaptcha', { token: token });
};

export const recuperarPinMiConsultorio = async (
  username,
  cuilcuit,
  idHistorialContactoPersonaJuridica,
) => {
  return axiosPost('/v1/auth/miconsultorio/recuperar', {
    nombreUsuario: username,
    cuilcuit: cuilcuit,
    idHistorialContactoPersonaJuridica: idHistorialContactoPersonaJuridica,
  });
};

export const nuevoPin = async (pin, token, codigoverificacion, idcontacto) => {
  return axiosPost('/v1/auth/miconsultorio/nueva-contrasenia', {
    contrasenia: pin,
    token: token,
    codigoverificacion: codigoverificacion,
    idcontacto: idcontacto,
  });
};
