import { HighlightOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DateTextInput from '../Common/DateTextInput';
import { useForm } from 'react-hook-form';
import { findRecetasElectronicas } from '../../services/Busquedas/RecetasElectronicas';
import CustomizedSnackbars from '../Notifications/SnackBar';
import RecetaElectronicaTableResult from './RecetaElectronicaTableResult';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation, withRouter } from 'react-router-dom/cjs/react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { LoadingButton } from '@mui/lab';

const BuscarRecetas = () => {
  let { id } = useParams();
  const [error, setError] = useState('');
  const [documentoAfiliado, setDocumentoAfiliado] = useState('');
  const [loading, setLoading] = useState(false);
  const [visibleFind, setVisibleFind] = useState(true);
  const [recetasElectronicas, setRecetasElectronicas] = useState([]);
  const [alertResultFind, setAlertResultFind] = useState('');

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  let { state } = useLocation();

  useEffect(() => {
    if (id != null) {
      try {
        buscarRecetasElectronicas({
          idAfiliado: id,
          fechaDesde: null,
          fechaHasta: null,
        });
      } catch (e) {
        setLoading(false);
      }
    }

    if (state?.datosBack?.recetasElectronicas?.length) {
      setVisibleFind(false);
      setRecetasElectronicas(state.datosBack.recetasElectronicas);
    }
  }, [id, state?.datosBack]);

  const buscarRecetasElectronicas = async (data) => {
    try {
      setVisibleFind(false);
      const result = await findRecetasElectronicas(data);
      if (result?.length) {
        setRecetasElectronicas(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const getData = () => {
    let data = {
      documentoAfiliado: documentoAfiliado,
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
    };
    return data;
  };

  const refresh = () => {
    onBuscar(getData());
  };

  const find = (data, e) => {
    e.preventDefault();
    onBuscar(getData());
  };
  const onBuscar = async (data) => {
    setRecetasElectronicas([]);
    setLoading(true);
    setAlertResultFind('');
    setError('');

    if (
      fechaDesde === null &&
      fechaHasta === null &&
      (documentoAfiliado === null || documentoAfiliado === '')
    ) {
      setError();
      setError('Debes ingresar por lo menos un criterio para realizar la búsqueda');
      setLoading(false);
    } else {
      try {
        const result = await findRecetasElectronicas({
          documento: documentoAfiliado,
          fechaDesde: fechaDesde,
          fechaHasta: fechaHasta,
        });
        if (result?.length) {
          setRecetasElectronicas(result);
          setVisibleFind(false);
        } else {
          setAlertResultFind('Busqueda sin resultados, intente con otros parámetros.');
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleVolver = () => {
    setRecetasElectronicas([]);
    setVisibleFind(true);
  };

  const handlerLimpiar = () => {
    reset();
    setDocumentoAfiliado('');
    setFechaDesde(null);
    setFechaHasta(null);
  };

  const handleBorrarMensaje = () => {
    setAlertResultFind('');
    setError('');
  };

  return (
    <Grid container spacing={2}>
      <ButtonBack />
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Buscar Receta Electrónica
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              align='justify'
              gutterBottom
            >
              Acá podrás buscar las prescripciones de recetas electrónicas de plan ambulatorio
              generadas de un afiliado particular y/o en un rango de fecha.
            </Typography>
          </CardContent>

          {visibleFind && (
            <form>
              <CardContent>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <TextField
                    {...register('documentoAfiliado', {
                      /*required: {
                    value: true,
                    message: "El dni es requerido",
                  },*/
                      maxLength: {
                        value: 10,
                        message: 'El dni de afiliado es demasiado largo',
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'El dni debe contener sólo números',
                      },
                    })}
                    variant='standard'
                    fullWidth
                    name='documentoAfiliado'
                    label='Ingresá el dni del afiliado'
                    id='documentoAfiliado'
                    value={documentoAfiliado}
                    onChange={(newValue) => {
                      setDocumentoAfiliado(newValue.target.value);
                    }}
                  />
                  {errors.documentoAfiliado && (
                    <p style={{ color: 'red' }}>{errors.documentoAfiliado.message}</p>
                  )}
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <DateTextInput
                    register={register}
                    errors={setError}
                    required={false}
                    label='Desde'
                    fieldName='fechaDesde'
                    change={(value) => {
                      setFechaDesde(value);
                    }}
                  />
                  {errors['fechaDesde'] && (
                    <p style={{ color: 'red' }}>{errors['fechaDesde'].message}</p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <DateTextInput
                    register={register}
                    errors={setError}
                    required={false}
                    label='Hasta'
                    fieldName='fechaHasta'
                    change={(value) => {
                      setFechaHasta(value);
                    }}
                  />
                  {errors['fechaHasta'] && (
                    <p style={{ color: 'red' }}>{errors['fechaHasta'].message}</p>
                  )}
                </FormControl>
              </CardContent>

              <CardActions sx={{ px: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                      }}
                    >
                      <Button
                        variant='contained'
                        size='medium'
                        startIcon={<HighlightOff />}
                        color='neutral'
                        onClick={handlerLimpiar}
                      >
                        Limpiar
                      </Button>
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        loading={loading}
                        startIcon={<SearchIcon />}
                        onClick={handleSubmit(find)}
                      >
                        Buscar
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          )}
          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item>
                <RecetaElectronicaTableResult
                  recetasElectronicas={recetasElectronicas}
                  onRefresh={refresh}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {' '}
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => handleVolver()}
                    >
                      Buscar Otras
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>

      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default withRouter(BuscarRecetas);
