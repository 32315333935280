import React from 'react';
import { useUser } from '../../components/Auth/User';
import './AccessNav.css';

const AccessNav = () => {
  const { user } = useUser();

  if (!user) {
    return <></>;
  }

  return (
    <>
      <div className={`AccessNav js-accessnav`}>
        <ul className='AccessNav__list'>
          <li>
            <a className='AccessNav__item' href='#main'>
              Saltar al contenido principal
            </a>
          </li>
          <li>
            <a className='AccessNav__item' href='#top-menu'>
              Saltar a menú perfil de usuario
            </a>
          </li>
          <li>
            <a className='AccessNav__item' href='#nav'>
              Saltar a menú de navegación
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AccessNav;
