import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
} from '@mui/material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import React, { useEffect, useMemo, useState } from 'react';
import { Delete, KeyboardArrowDown, KeyboardArrowUp, MoreVert } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';
import AnularOrdenElectronicaPopUp from './AnularOrdenElectronicaPopUp';
import StyledDataGridAnularOrdenElectronica from '../Orden/StyledDataGridAnularOrdenElectronica';

const DataCollapseDrawer = (props) => {
  const { ordenElectronica } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAnular = (ordenElectronica) => {
    props.onAnular(ordenElectronica);
    handleClose();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        selected={open}
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary={ordenElectronica.id}
                primaryTypographyProps={{
                  textTransform: 'none',
                }}
              />
            </ListItem>
          </List>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ justifyContent: 'flex-end', p: 0 }}>
              <ListItemButton
                aria-label='more'
                id='long-button'
                aria-controls={openAction ? 'long-menu' : undefined}
                aria-expanded={openAction ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVert />
              </ListItemButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 50 * 4.5,
                    width: '17ch',
                  },
                }}
              >
                <MenuItem
                  key={'anular'}
                  onClick={() => {
                    handleAnular(ordenElectronica);
                  }}
                  disabled={ordenElectronica.codigoEstado !== '1'}
                >
                  <GridActionsCellItem icon={<Delete />} title='Anular' label='Anular' />
                  Anular
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary='Fecha de emisión'
                secondary={`${ordenElectronica?.fechaEmision}` || ''}
              />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Afiliado' secondary={`${ordenElectronica?.afiliado}` || ''} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary='Prestador'
                secondary={`${ordenElectronica?.prestador}` || ''}
              />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const BuscarAnularOrdenElectronicaTableResult = (props) => {
  const screenWidth = useWidth();

  const [success, setSuccess] = useState('');
  const [ordenElectronica, setOrdenElectronica] = useState(null);
  const [onOpenAnular, setOpenAnular] = useState(null);
  const [loadingResults, setLoadingResults] = useState(null);

  useEffect(() => {
    if (props.ordenesElectronicas.length > 0) {
      setLoadingResults(false);
    }
  }, [props.ordenesElectronicas]);

  useEffect(() => {
    console.log(screenWidth);
  }, [screenWidth]);

  const handleAnular = (ordenElectronica) => {
    setOrdenElectronica(ordenElectronica);
    setOpenAnular(true);
  };

  const handleAnulada = async (anulada) => {
    setSuccess('La orden ha sido anulada correctamente');
    setLoadingResults(true);
    setOrdenElectronica(null);
    setOpenAnular(false);
    if (anulada) {
      props.onRefresh();
    }
  };

  const anularClic = React.useCallback(
    (value) => () => {
      handleAnular(value);
    },
    [],
  );

  const mapOrdenesToRow = (a) => {
    return {
      resume: a,
      id: a.orden.numeroOrden,
      numero: a.orden.numeroOrden,
      fechaEmision: a.orden.fechaEmision,
      afiliado:
        a.afiliadoPersonaFisica.apellido +
        ', ' +
        a.afiliadoPersonaFisica.nombre +
        ' (' +
        a.afiliadoPersonaFisica.numeroDocumentoString +
        ')',
      prestador:
        a.prestadorPersonaFisica != null
          ? a.prestadorPersonaFisica.apellido + ', ' + a.prestadorPersonaFisica.nombre
          : a.prestadorPersonaJuridica.descripcion,
      codigoEstado: a.estadoOrden.codigo,
    };
  };

  const columns = useMemo(
    () => [
      {
        field: 'desplegable',
        headerName: 'Ordenes',
        flex: 1,
        renderCell: (a) => (
          <DataCollapseDrawer
            ordenElectronica={a.row}
            onAnular={(e) => {
              handleAnular(e);
            }}
          />
        ),
      },
      { field: 'fechaEmision', headerName: 'Fecha Emisión', flex: 1 },
      { field: 'id', headerName: 'Nro Orden', flex: 1 },
      { field: 'afiliado', headerName: 'Afiliado', flex: 1 },
      { field: 'prestador', headerName: 'Prestador', flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Delete />}
            label='Anular'
            disabled={params.row.codigoEstado !== '1'}
            onClick={anularClic(params.row)}
          />,
        ],
      },
    ],
    [anularClic],
  );

  const dataGridOrdenes = props.ordenesElectronicas && (
    <div style={{ height: '60vh', width: '100%' }}>
      <StyledDataGridAnularOrdenElectronica
        getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
        sx={{
          border: 0,
          '& .MuiDataGrid-cell': {
            py: '8px',
          },
        }}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        loading={loadingResults}
        rows={props.ordenesElectronicas.map((a) => mapOrdenesToRow(a))}
        columns={columns}
        autoPageSize
        pagination={screenWidth === 'xs' ? null : true}
        disableSelectionOnClick
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs',
          id: screenWidth !== 'xs' && screenWidth !== 'sm',
          afiliado: screenWidth !== 'xs',
          prestador: screenWidth !== 'xs',
          fechaEmision: screenWidth !== 'xs',
          actions: screenWidth !== 'xs',
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              Sin Ordenes Electrónicas.
            </Stack>
          ),
        }}
      />
    </div>
  );

  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  return (
    <Grid container spacing={2} sx={{ width: '100%', p: 1 }}>
      <Grid item xs={12}>
        {dataGridOrdenes}
      </Grid>
      {onOpenAnular ? (
        <AnularOrdenElectronicaPopUp
          open={onOpenAnular}
          ordenElectronica={ordenElectronica}
          setOrdenAnulada={handleAnulada}
          onCancelar={() => setOpenAnular(false)}
        />
      ) : null}

      <CustomizedSnackbars
        open={success}
        autoHideDuration={8000}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarAnularOrdenElectronicaTableResult;
