import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import QrPreview from '../Common/QrPreview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { enviarRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import { useTheme } from '@emotion/react';
import { isMobile as isMobileFn } from '../../@iosper/utils/DeviceDetector';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { Warning as WarningIcon } from '@mui/icons-material';

export const RecetaMedicaGenerada = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = isMobileFn() || !matches;

  const {
    afiliado,
    recetaElectronica,
    estado,
    rpList,
    tipoTratamiento,
    idsRecetas, //todas
    idsRecetasAsociadas, //solo ids de asociadas (no tiene id de la consultada)
    profesional,
    urlReporte,
    esConsulta,
  } = history?.location?.state?.receta || {};

  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');
  //const [urlReporte, setUrlReporte] = useState("");
  const handlerEnviarCorreo = async (correo) => {
    setLoadingEnviar(true);
    setSuccessCorreo('');
    try {
      const res = await enviarRecetaElectronica({
        idsRecetas: idsRecetas,
        correo: correo,
      });

      setSuccessCorreo('Receta electrónica enviada al correo: ' + correo);
      setLoadingEnviar(false);
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  const customStyle = {
    marginBottom: '5px',
    marginTop: '0px',
  };

  const customStyleTextField = {
    fontWeight: 'bold',
    fontSize: '13px', // Ajusta el tamaño de la letra aquí
  };

  return (
    <Grid container spacing={1}>
      <ButtonBack />
      <Grid item xs={12} sx={{ marginTop: '-15px' }}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Grid
              sx={{ marginTop: '-30px' }}
              container
              direction='row'
              justifyContent={esConsulta ? 'center' : 'flex-start'}
              alignItems='center'
              spacing={1}
            >
              <Grid item>
                {' '}
                <Typography variant='h6' style={customStyle}>
                  {' '}
                  {esConsulta
                    ? 'Consulta de Receta Electrónica'
                    : 'Receta Electrónica generada'}{' '}
                </Typography>
              </Grid>
              {!esConsulta && (
                <Grid item>
                  {' '}
                  <CheckCircleIcon fontSize='small' color='primary'></CheckCircleIcon>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {recetaElectronica && !esConsulta && (
                  <QrPreview
                    showDownload={true}
                    showPrint={true}
                    urlReporte={urlReporte}
                    nombreReporte={'Receta_electrónica_iosper_' + recetaElectronica.numero}
                    qrValue={JSON.stringify({ codigo: recetaElectronica.numero })}
                    onEnviarCorreo={handlerEnviarCorreo}
                    showMail={true}
                    correo={afiliado.correo}
                    loadingEnviar={loadingEnviar}
                    successCorreo={successCorreo}
                  />
                )}
  {recetaElectronica.domicilioAfiliado && (
      <div className='container' style={{
        backgroundColor: '#FFF4E5',
        borderRadius: '10px',
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '-20px', 
        paddingBottom: '10px',
        marginBottom: '10px',
      }}>
        <WarningIcon style={{ 
          color: '#FF9800', 
          marginRight: '10px', 
          fontSize: '24px'
        }} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p style={{ color: '#804000', margin: 0 }}>
              La receta generada es de <strong>Venta Controlada/Archivada</strong> y deberá ser impresa (solo una copia), firmada y sellada por el prescriptor, para poder ser presentada en la farmacia, como lo requiere el Ministerio de Salud de la Provincia de Entre Ríos.
            </p>
          </Grid>
        </Grid>
      </div>
                 )}

                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                  <Card sx={{ width: '100%' }}>
                    <CardContent
                      sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0px',
                      }}
                    >
                      <Grid item>
                        {' '}
                        <Typography variant='h6' style={customStyle}>
                          {' '}
                          Receta Electrónica{' '}
                        </Typography>
                      </Grid>
                      <Grid container spacing={1} sx={{ marginTop: '-15px', marginBottom: '-5px' }}>
                        <Grid item xs={6} md={4}>
                          {recetaElectronica && (
                            <FormControl fullWidth={true} variant='standard'>
                              <TextField
                                id='numeroReceta'
                                variant='standard'
                                label='Número'
                                defaultValue={`${recetaElectronica.numero}`}
                                size='small'
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                  style: { fontWeight: 'bold', fontSize: '13px', spacing: '0px' },
                                }}
                              />
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='plan'
                              variant='standard'
                              label='Plan'
                              defaultValue={`${recetaElectronica.planDescripcion}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='estado'
                              variant='standard'
                              label='Estado'
                              defaultValue={`${estado?.descripcion}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={6} md={4}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='prescripcion'
                              variant='standard'
                              label='Prescripción'
                              defaultValue={`${recetaElectronica.fechaPrescripcion}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='fechaInicio'
                              variant='standard'
                              label='Inicio Vigencia'
                              defaultValue={`${recetaElectronica.fechaInicioVigenciaFormateada}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='vencimiento'
                              variant='standard'
                              label='Vencimiento'
                              defaultValue={`${recetaElectronica.vencimientoFormateado}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>

                        {estado?.codigo === '2' && (
                          <>
                            <Grid item xs={6} md={6}>
                              <FormControl fullWidth={true} variant='standard'>
                                <TextField
                                  id='fechaEntregada'
                                  variant='standard'
                                  label='Fecha entrega'
                                  defaultValue={`${recetaElectronica.fechaEstado}`}
                                  size='small'
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                    style: customStyleTextField,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth={true} variant='standard'>
                                <TextField
                                  id='farmacia'
                                  variant='standard'
                                  label='Farmacia'
                                  defaultValue={`${recetaElectronica.farmacia}`}
                                  size='small'
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                    style: customStyleTextField,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container sx={{ marginBottom: '-10px', marginTop: '5px' }} spacing={1}>
                        <Grid item xs={6} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='tipoTratamiento'
                              variant='standard'
                              label='Tipo de Tratamiento'
                              defaultValue={`${tipoTratamiento.descripcion} ${
                                recetaElectronica.periodo != null ? recetaElectronica.periodo : ''
                              }`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='tipoReceta'
                              variant='standard'
                              label='Tipo de Receta'
                              defaultValue={`${recetaElectronica.tipoRecetaElectronicaDescripcion}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: '5px', marginTop: '-5px' }}>
                  <Card sx={{ width: '100%' }}>
                    <CardContent
                      sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '-10px',
                      }}
                    >
                      <Grid item sx={{ marginTop: '-5px' }}>
                        {' '}
                        <Typography variant='h6' style={customStyle}>
                          {' '}
                          Afiliado{' '}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{ marginBottom: '-10px', marginTop: '-15px' }}
                        spacing={1}
                      >
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='documentoAfiliado'
                              variant='standard'
                              label='Documento'
                              defaultValue={`(${afiliado.tipoDocumentoDescripcion}) ${afiliado.numeroDocumentoFormateado}`}
                              size='small'
                              fullWidth
                              multiline
                              maxRows={3}
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          {afiliado && (
                            <FormControl fullWidth={true} variant='standard'>
                              <TextField
                                id='afiliado'
                                variant='standard'
                                label='Apellido y Nombre'
                                defaultValue={`${afiliado.apellido}, ${afiliado.nombre}`}
                                size='small'
                                fullWidth
                                multiline
                                maxRows={3}
                                InputProps={{
                                  readOnly: true,
                                  style: customStyleTextField,
                                }}
                              />
                            </FormControl>
                          )}
                        </Grid>

                        {recetaElectronica?.domicilioAfiliado && (
                          <>
                            <Grid item xs={12} md={6} style={{ marginTop: '-5px' }}>
                              <FormControl fullWidth={true} variant='standard'>
                                <TextField
                                  id='telefono'
                                  variant='standard'
                                  label='Telefono'
                                  defaultValue={`${recetaElectronica?.telefonoAfiliado}`}
                                  size='small'
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                    style: customStyleTextField,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginTop: '-5px' }}>
                              <FormControl fullWidth={true} variant='standard'>
                                <TextField
                                  id='domicilio'
                                  variant='standard'
                                  label='Domicilio'
                                  defaultValue={`${recetaElectronica?.domicilioAfiliado}`}
                                  size='small'
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                    style: customStyleTextField,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: '5px', marginTop: '0px' }}>
                  <Card sx={{ width: '100%' }}>
                    <CardContent
                      sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '-10px',
                      }}
                    >
                      <Grid
                        item
                        sx={{ marginTop: '-5px', paddingBottom: '-20px', marginBottom: '-5px' }}
                      >
                        {' '}
                        <Typography variant='h6' style={customStyle}>
                          {' '}
                          Prescripciones{' '}
                        </Typography>
                      </Grid>

                      {rpList.map((rp, idx) => {
                        return (
                          <FormControl
                            sx={{ marginTop: '0px' }}
                            key={idx}
                            fullWidth={true}
                            variant='standard'
                          >
                            <TextField
                              id='rp1'
                              variant='standard'
                              label={`RP${idx + 1}`}
                              defaultValue={`${rp?.medicamento?.drogas || '-'} ${
                                rp.medicamento.presentacion
                              }, cantidad: ${rp.medicamento.cantidad}(${rp.cie10.descripcion} (${
                                rp.cie10?.codigo
                              }))`}
                              size='small'
                              multiline
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: customStyleTextField,
                              }}
                            />
                          </FormControl>
                        );
                      })}
                      <FormControl
                        fullWidth={true}
                        variant='standard'
                        sx={{ marginBottom: '-10px' }}
                      >
                        <TextField
                          id='indicaciones'
                          variant='standard'
                          label='Indicaciones'
                          defaultValue={`${recetaElectronica.observacion}`}
                          size='small'
                          multiline
                          maxRows={4}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: customStyleTextField,
                          }}
                        />
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
                  <Card sx={{ width: '100%' }}>
                    <CardContent
                      sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0px',
                      }}
                    >
                      <Grid item sx={{ marginTop: '-5px' }}>
                        {' '}
                        <Typography variant='h6' style={customStyle}>
                          {' '}
                          Prescriptor{' '}
                        </Typography>
                      </Grid>

                      <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='profesionPrescriptor'
                              variant='standard'
                              label='Profesion'
                              multiline
                              maxRows={3}
                              defaultValue={`${profesional.profesion} (Matrícula ${profesional.matricula})`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { fontWeight: 'bold' },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='apellidoYNombrePrescriptor'
                              variant='standard'
                              label='Apellido y Nombre'
                              multiline
                              maxRows={3}
                              defaultValue={`${profesional.descripcion}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { fontWeight: 'bold' },
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='telefonoPrescriptor'
                              variant='standard'
                              label='Telefono'
                              defaultValue={`${profesional?.telefono}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { fontWeight: 'bold' },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <TextField
                              id='domicilioPrescriptor'
                              variant='standard'
                              label='Domicilio'
                              defaultValue={`${profesional?.domicilio}`}
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { fontWeight: 'bold' },
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {idsRecetasAsociadas.length > 0 && (
                  <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
                    <Card sx={{ width: '100%' }}>
                      <CardContent
                        sx={{
                          p: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0px',
                        }}
                      >
                        <Grid item sx={{ marginTop: '-5px' }}>
                          {' '}
                          <Typography variant='h6' style={customStyle}>
                            {' '}
                            Otras recetas del tratamiento{' '}
                          </Typography>
                        </Grid>

                        <Stack direction='row' spacing={2}>
                          {idsRecetasAsociadas.map((recetaAsociada, idx) => {
                            return (
                              <Button key={idx} variant='outlined'>
                                {recetaAsociada.numero}
                              </Button>
                            );
                          })}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='h6'
                  color='textSecondary'
                  component='p'
                  mt={3}
                  textAlign={'center'}
                >
                  Receta firmada electrónicamente
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
