import { useEffect, useState } from 'react';
import AccessNav from './AccessibilityNav/AccessNav';
import Content from './Main/Content';
import Mantenimiento from './Messages/Page/Mantenimiento';
import Menu from './Header/Menu';
import SnackbarMessages from './Messages/SnackbarMessages';
import { useVersionControlStore } from '../@iosper/components/VersionControl/store/hooks';

const Layouts = () => {
  const { state, dispatchCheckVersion } = useVersionControlStore();
  const [isUpdated, setIsUpdated] = useState(true);

  useEffect(() => {
    dispatchCheckVersion();
  }, []);

  useEffect(() => {
    setIsUpdated(state.isUptodate);
  }, [state]);

  return (
    <>
      {!isUpdated && <Mantenimiento />}

      {isUpdated && (
        <>
          <header>
            <AccessNav />
          </header>
          <Menu>
            <Content />
          </Menu>
          <SnackbarMessages />
        </>
      )}
    </>
  );
};

export default Layouts;
