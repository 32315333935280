import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Container from '@mui/material/Container';
import logo from '../../assets/images/logo-small.png';
import CustomizedSnackbars from '../Notifications/SnackBar';
import LoadingButton from '../../@iosper/components/LoadingButton';
import Typography from '@mui/material/Typography';
import { useUser } from './User';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useForm } from 'react-hook-form';

const SignIn = () => {
  const { user, signIn } = useUser();
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  React.useEffect(() => {
    if (user) {
      history.push('/validar-contacto', { from: history?.location?.state?.from || '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const signInHandler = async (data, e) => {
    setLoading(true);
    e.preventDefault();
    setResponse('');
    signInEvent(data.username, data.password);
  };

  const signInEvent = async (username, password) => {
    const res = await signIn(username, password);
    if (res) {
      setLoading(false);
      setResponse('Ingresando a Mi Consultorio...');
    } else {
      setLoading(false);
    }
  };

  const handleOnKeyUp = (e) => {
    let { key, keyCode } = e;
    if (key === 'Enter' && keyCode === 13) {
      trigger();
      getValues('username') != '' &&
        getValues('password') != '' &&
        signInEvent(getValues('username'), getValues('password'));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleBorrarMensaje = () => {
    setResponse('');
  };

  return (
    <>
      {!user && (
        <Container
          maxWidth='xs'
          onKeyUpCapture={handleOnKeyUp}
          disableGutters
          sx={{ mt: { xs: 3, sm: 5 } }}
        >
          <Card>
            <CardContent sx={{ my: { xs: 2, md: 4 }, mx: { xs: 2, md: 5 } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                gap={2}
                mb={4}
              >
                <Avatar alt='IOSPER' src={logo} sx={{ width: 70, height: 70 }} />
                <Typography variant='h4' fontWeight={'bold'}>
                  Iniciar sesión
                </Typography>
              </Grid>

              <form noValidate onSubmit={handleSubmit(signInHandler)}>
                <Box px={1}>
                  <TextField
                    variant='standard'
                    margin='normal'
                    required
                    fullWidth
                    id='username'
                    label='Usuario'
                    name='username'
                    autoComplete='username'
                    autoFocus
                    {...register('username', {
                      required: {
                        value: true,
                        message: 'Debe ingresar usuario',
                      },
                    })}
                  />
                  {errors.username && <p style={{ color: 'red' }}>{errors.username.message}</p>}

                  <FormControl variant='standard' fullWidth required>
                    <InputLabel htmlFor='standard-adornment-password'>Contraseña</InputLabel>
                    <Input
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...register('password', {
                        required: {
                          value: true,
                          message: 'Debe ingresar contraseña',
                        },
                      })}
                    />{' '}
                    {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>}
                  </FormControl>
                </Box>

                <Box sx={{ pt: 4 }}>
                  <LoadingButton
                    fullWidth={true}
                    variant='contained'
                    color='primary'
                    startIcon={<LockOpenIcon />}
                    loading={loading ? 'show' : 'hide'}
                    content={'Ingresar'}
                    type='submit'
                  />
                </Box>
                <LoadingButton
                  component={Link}
                  to={'/olvide-pin'}
                  fullWidth={true}
                  color='primary'
                  content={'Olvide mi Contraseña'}
                />
              </form>
            </CardContent>
          </Card>

          <CustomizedSnackbars
            open={response}
            severity='success'
            message={response}
            onDeleteMessage={handleBorrarMensaje}
          />
        </Container>
      )}
    </>
  );
};

export default SignIn;
